import React, { useEffect, useState } from "react";

import "devextreme/data/odata/store";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Col, Row } from "react-bootstrap";
import * as yup from "yup";

import API from "../../api/api";
import { parsedUser } from "../../utils/GetCurrentUser";
import { useParams } from "react-router-dom";
import FieldSelector from "../../components/customComponents/FieldSelector";
import { useFormik } from "formik";
import Sha256 from "../../utils/sha256";
import { useToast } from "../../contexts/toast";
import yupTypeValidator from "../../utils/YupTypeValidator";


const CRUDPageFORM = (props) => {

  // PROPERTIES
  const user = parsedUser();

  const params = useParams();
  const toast = useToast();

  const [generalError, setGeneralError] = useState(null);
  const [actionMode, setActionMode] = useState(props.actionMode);//I=Insert, U= Update = C= Consultar, D= Delete
  const [loading, setLoading] = useState(false);
  const [isPartOfParent, setIsPartOfParent] = useState(false);//This state is used to know if we have to sent the 
  const initialModel = {
    section: {},
    data: {
      Columns: [],
      Rows: [],
    },
  }
  const [model, setModel] = useState(props.page);
  // END PROPERTIES

  //METHODS

  // THIS METHOD IS USED TO RETURN TRUE IF THIS PAGE FIELDS ARE PART OF THE PARENT COMPONENT
  const bindDataPartOfParent = async () => {
    setLoading(true);
    try {
      let query = `id=${params.id}&token=${user.Token}&PageIndex=${props.page.PageIndex}`;

      let request = await API.getAction("api/CRUDM/IsPartOfParent", query);

      if (request.data) {
        setIsPartOfParent(true);
        props.setRequiresPageObject(true);


      }
    } catch (error) {
      console.error(error)
    }
    finally {
      setLoading(false);
    }
  }

  const bindDataDetailsRemote = async (recordId) => {
    //First load records
    setLoading(true);

    try {
      let query = `id=${params.id}&token=${user.Token}&RecordId=${props.selectedItem.Id}`;
      let request = await API.getAction("api/CRUD/Details", query);
      let results = request.data[0];

      let parsedResults = JSON.parse(results.JSONData)[0];
      if (parsedResults.Error) {
        alert(parsedResults.Msg);
        return;
      }
      let finalResult = parsedResults.JSONData[0];
      fillFormikObject(finalResult);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }

    //setRecordId(finalResult?.GUID)
    //Fill object with new values
  };

  //Update the field value on formik according to what has been updated
  const updateField = (fieldName, fieldValue) => {

    formik.setFieldValue(fieldName, fieldValue);
    if (isPartOfParent) {
      props.updateField(fieldName, fieldValue);


    } else {
      // we are going to keep updating the field value in this formik just to keep the UI refreshing
    }

    //formik.validateField(fieldName);
    console.log("formik values", formik.values)
  };



  const [validationsSchema, setValidationSchema] = useState(null);

  const fillFormikObject = (record) => {
    console.log("fillFormikObject", "record", record, "model", model);
    if (
      model &&
      model.Fields &&
      model.Fields.length > 0
    ) {
      model.Fields.forEach((item) => {
        console.log(
          "field name",
          item.FieldName,
          "RecordValue ",
          record[item.FieldName]
        );
        formik.setFieldValue(item.FieldName, record[item.FieldName]);
      });
    }

    //Now
  };

  const prepareFormikObject = () => {
    console.log("Preparing formik object", model)
    let initialObject = {};
    let initialObjectValidation = {};


    if (model.Fields) {
      model.Fields.forEach((item) => {

        if (item.FieldName === "Id") return;

        initialObject[item.FieldName] = item.value;

        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };



  //This method is to save the record we are working on
  const handleSubmit = async (obj, event) => {

    if (Object.values(formik.errors).length > 0) {
      console.log("There are errors in the page", formik.errors);
      return;
    }


    let actions = {
      "I": "I",
      "U": "U",
      "D": "I"
    };

    if (actionMode === "D") {
      const { GUID, Id, ...rest } = obj;
      obj = rest;
    }
    confirmAlert({
      closeOnClickOutside: false,
      message: "Are you sure you want to save this record?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              let objSPVersion = "";
              model.Fields.forEach((item) => {

                if (item.ReadOnly || item.HtmlType === "separator" || item.FieldName === 'IdCustomer') return;

                if (item.HtmlType !== "separator") {
                  objSPVersion +=
                    (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
                }
                if (
                  item.FieldType === "int" ||
                  item.FieldType === "bool" ||
                  item.FieldType === "bit" ||
                  item.FieldType === "bigint"
                ) {
                  objSPVersion +=
                    obj[item.FieldName] === undefined || obj[item.FieldName] === null
                      ? item.DefaultValue && item.DefaultValue.length > 0 ? item.DefaultValue : "null"
                      : "'" + obj[item.FieldName] + "'";
                  //objSPVersion +=
                  //obj[item.FieldName] === undefined ? "null" : obj[item.FieldName];
                } else if (item.HtmlType === "password") {
                  let passwordEncrypted = null;
                  if (obj[item.FieldName] && obj[item.FieldName].length > 0) {
                    passwordEncrypted =
                      obj["UserName"].toUpperCase() + obj[item.FieldName];
                    passwordEncrypted = Sha256.hash(passwordEncrypted);
                    ;
                  }
                  objSPVersion +=
                    obj[item.FieldName] === undefined
                      ? "null"
                      : "'" + passwordEncrypted + "'"
                } else
                  if (item.HtmlType === 'radio') {

                    objSPVersion +=
                      obj[item.FieldName] === undefined || obj[item.FieldName] === null
                        ? item.DefaultValue && item.DefaultValue.length > 0 ? item.DefaultValue : "null"
                        : "'" + obj[item.FieldName] + "'";


                    // } else if(item.HtmlType==="separator"){
                    //   //DO NOTHING, CONTINUE NEXT RECORD

                  } else {
                    objSPVersion +=
                      obj[item.FieldName] === undefined || obj[item.FieldName] === null
                        ? "null"
                        : "'" + obj[item.FieldName] + "'";
                  }
              });
              let queryString = `Id=${params.id}&Token=${user.Token}&ActionMode=${actions[actionMode]}&PageIndex=${props.page.PageIndex}`;
              objSPVersion += `, @ParentGUID='${props.ParentGUID}'`;
              let queryData = {
                Data: objSPVersion,
              };

              let request = await API.postAction(
                "api/CRUDM/AddEdit?" + queryString,
                queryData
              );

              if (request.status === 200) {
                let response = JSON.parse(request.data[0].JSONData)[0];
                if (response.Error) {
                  setGeneralError(response.Msg);
                  return;
                }
              }
              toast({
                type: "success",
                "message": "Record saved successfully",
              })

            } catch (error) {
              console.error(error);
            }
          },
        },
        {
          label: "No",
        },
      ],
    });


  };


  const validateOnChange = () => !["U", "C", "D"].includes(props.actionMode);
  const validateOnBlur = () => !validateOnChange();

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,

    validateOnChange: validateOnChange(),
    validateOnBlur: validateOnBlur(),

    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });


  // END METHODS

  // UI METHODS

  const loadFormUI = () => {
    console.log("Enter on load form ui")
    if (!model || !model.Fields) {
      return;
    }
    return (
      <div className="card bg-transparent">

        {/* <Form onSubmit={formik.handleSubmit}> */}
        <div id="tab-body" className="card-body p-4 formBackground">

          <Row>
            {/* CONTENT */}
            {model &&
              model.Fields.map((item, index) => {
                return (
                  <Col
                    className={
                      item.cssClasss + (!item.Visible ? " d-none" : "")
                    }
                    key={index}
                  >
                    <label className={item.HtmlType === 'separator' ? `me-2 ${(index !== 0 ? "mt-4 pt-4" : "mt-2")}  separatorTitle` : "me-2 mt-2"}>{item.FieldTitle}
                      {item.Required ? <i className="required-asterisk ms-1 text-danger">*</i> : null}
                    </label>
                    <br />

                    <FieldSelector
                      actionMode={actionMode}
                      model={item}
                      key={index}
                      updateField={updateField}
                      value={formik.values[item.FieldName]}
                    ></FieldSelector>
                    {formik.errors[item.FieldName] ? (
                      <div className="invalid text-sm">
                        {formik.errors[item.FieldName]}
                      </div>
                    ) : null}
                  </Col>
                );
              })}
          </Row>
        </div>
        {(!loading && !isPartOfParent) && (
          <div
            className="card-footer"
            data-html2canvas-ignore="true"
          >
            {generalError ? (
              <Row>
                <Col xs={12}>
                  <div className="alert alert-danger" role="alert">
                    {generalError}
                  </div>
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col xs={12} className="text-end ">
                {Object.values(formik.errors).length > 0 && !isPartOfParent ? (

                  <span className="invalid me-2">
                    Please check the forms for errors
                  </span>
                ) : null}


                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() => [formik.validateForm(formik.values), handleSubmit(formik.values)]}
                >
                  Save
                </button>
              </Col>
            </Row>
          </div>
        )}
        {/* </Form> */}

      </div>
    );
  };

  //EVENTS
  useEffect(() => {


    console.log("❗ ~ file: CRUDPageFORM.js:381 ~ useEffect ~ props.requestObject === true && isPartOfParent:", props.requestObject === true && isPartOfParent)
    if (props.requestObject === true && isPartOfParent) {
      //We are going over this because we don't have a form, so we must go over every field and try to do the job
      let objToSend = {};
      model.Fields.forEach(field => {
        try {

          //Here happens some error due to missing fields, don't know the best way to go over formik object
          if (field.Required) {
            formik.validateField(field.FieldName);
          }
          objToSend[field.FieldName] = formik.values[field.FieldName];//field.value;
        } catch (ex) {
          console.error(ex)
        }

      })

      props.setRequestObject(false);
      if (Object.values(formik.errors).length === 0) {
        props.doSubmit({ values: objToSend }, model.Fields)
      }

      //handleSubmit(formik.values, null);
    }
  }, [props.requestObject])

  useEffect(() => {
    console.log("formik error update", formik.errors, Object.values(formik.errors).length, Object.values(formik.errors))
    const hasErrors = Object.values(formik.errors).length > 0;
    if (isPartOfParent) {
      props.doSubmit({ errors: hasErrors ? formik.errors : null })
    }

  }, [formik.errors])


  useEffect(() => {
    if (!props.active) return;
    console.log("section updated", model)
    prepareFormikObject();
    bindDataPartOfParent();

    if (props.actionMode !== "I") {
      bindDataDetailsRemote(props.ParentGUID);
    }
  }, [props.active]);

  // END EVENTS


  useEffect(() => {
    console.log("formik changed on crudpage", formik.values)
  }, [formik.values])

  if (props.active === false) return <></>;

  return (
    <>

      {/* DISPLAY THE TYPE OF FORM */}

      {loading ? (
        <React.Fragment>
          <div className="">
            <div className="w-25 mx-auto my-1 p-5 text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <>
          {loadFormUI()}
        </>
      )}
    </>)

}

export default CRUDPageFORM;
import {
  AddBox,
  CleaningServices,
  Delete,
  Edit,
  Search,
} from "@mui/icons-material";
import { TextBox } from "devextreme-react";
import React, { useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import RoomDetails from "./RoomDetails";
import AvailableReservations from "./AvailableReservations";

const RestaurantReservationsPage = (props) => {
  const [open, setOpen] = useState(false);
  const [actionMode, setActionMode] = useState("start");

  const [search, setSearch] = useState("");

  const [selectedItem, setSelectedItem] = useState(null);

  return (
    <React.Fragment>
      <div className="row mx-0">
        <div className="col-12">
          <div className="dx-card my-4 px-3 py-2">
            <div className="row">
              <div className="col">
                <h5 className={"mt-2"}>Restaurant Reservations</h5>
              </div>
              <div className="col text-end">
                <button
                  className="btn"
                  //onClick={() => toggleOpen("I")}
                >
                  <AddBox />
                </button>
                {selectedItem ? (
                  <>
                    <button
                      className="btn"
                      // onClick={() => toggleOpen("U")}
                    >
                      <Edit />
                    </button>
                    <button
                      className="btn"
                      type="button"
                      //   onClick={() => deleteConfirm()}
                    >
                      <Delete />
                    </button>
                  </>
                ) : null}
              </div>
            </div>
          </div>

          <Card className="mb-4">
            <Card.Header>
              <Card.Title>Guest Information</Card.Title>
            </Card.Header>
            <Form
              onSubmit={async (e) => {
                e.preventDefault();

                setActionMode("userInfo");
                //   await bindDataRemote();
              }}
            >
              <Card.Body>
                <Row>
                  <Col className={"col-12"}>
                    <TextBox
                      // className="form-control "
                      id={"search"}
                      mode={"text"}
                      value={search}
                      placeholder="Room Number"
                      onValueChanged={(e) => {
                        setSearch(e.value);
                      }}
                    />
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-end">
                <button
                  className="btn btn-sm btn-secondary me-2"
                  type="button"
                  onClick={() => {
                    setSearch("");
                    setTimeout(async () => {
                      //     await bindDataRemote();
                    }, 1000);
                  }}
                >
                  <CleaningServices />
                </button>
                <button className="btn btn-sm btn-primary" type="submit">
                  <Search />
                </button>
              </Card.Footer>
            </Form>
          </Card>
          {actionMode === "userInfo" ? (
            <>
              <RoomDetails />

              <AvailableReservations />
            </>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};

export default RestaurantReservationsPage;

import { DropDownBox, TextArea } from "devextreme-react";
import React from "react";
import { Col, Modal, Row } from "react-bootstrap";

const MakeReservation = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.toggle}
      size="md"
      className="modalRight"
    >
      <Modal.Header closeButton>
        <Modal.Title>Reservation</Modal.Title>
      </Modal.Header>

      {/* <Form onSubmit={handleSubmit}> */}
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <label className="me-2 mt-2">Reason for the reservation</label>
            <br />
            <DropDownBox />
          </Col>
          <Col xs={12}>
            <label className="me-2 mt-2">Alergies?</label>
            <br />
            <TextArea />
          </Col>
          <Col xs={12}>
            <label className="me-2 mt-2">
              Observations and other reasons for the reservation?
            </label>
            <br />
            <TextArea />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="p-1">
        <button className="btn btn-secondary" onClick={() => props.toggle()}>
          Cancel
        </button>
        <button className="btn btn-primary me-2">Reserve</button>
      </Modal.Footer>
      {/* </Form> */}
    </Modal>
  );
};
export default MakeReservation;

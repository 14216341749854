const RATEPERIODPOLICIESFIELDS = [
	{
		"IdAppForm": 77,
		"Id": 7700001,
		"FieldName": "IdContract",
		"FieldType": "bigint",
		"FieldTitle": "Contract",
		"Position": 1,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "select",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": "{\"HashID\":\"1a1a1446cb29d5afcaa119e8c68f86df56f645a22b7a13adce601a33721da780\"}",
		"Required": false,
		"Visible": false,
		"ReadOnly": false
	},
	{
		"IdAppForm": 77,
		"Id": 7700002,
		"FieldName": "IdBusinessUnit",
		"FieldType": "bigint",
		"FieldTitle": "Business Unit",
		"Position": 2,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "select",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": "{\"HashID\":\"be60cb6ae9e3b5bfc7eb84cb4127a5f0b071e40ad1a9dd2dc20035c778f507ef\"}",
		"Required": false,
		"Visible": false,
		"ReadOnly": false
	},
	{
		"IdAppForm": 77,
		"Id": 7700003,
		"FieldName": "IdContractRatePeriod",
		"FieldType": "bigint",
		"FieldTitle": "Contract Rate Period",
		"Position": 3,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "select",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": "{\"HashID\":\"c72b6b91ad943bda15484201660283a8eb56c01952d44ceee40ef3bb83b7aa92\"}",
		"Required": false,
		"Visible": false,
		"ReadOnly": false
	},
	{
		"IdAppForm": 77,
		"Id": 7700004,
		"FieldName": "Id",
		"FieldType": "bigint",
		"FieldTitle": "Id",
		"Position": 4,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "text",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": null,
		"Required": false,
		"Visible": false,
		"ReadOnly": false
	},
	{
		"IdAppForm": 77,
		"Id": 7700005,
		"FieldName": "Description",
		"FieldType": "varchar",
		"FieldTitle": "Description",
		"Position": 5,
		"cssClasss": "col-12 col-md-12",
		"HtmlType": "text",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": null,
		"Required": true,
		"Visible": true,
		"ReadOnly": false
	},
	{
		"IdAppForm": 77,
		"Id": 7700006,
		"FieldName": "PolicyType",
		"FieldType": "char",
		"FieldTitle": "Policy Type",
		"Position": 6,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "radio",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": "[{\"id\": 0,\"text\": \"Cancellation\"},{\"id\": 1,\"text\": \"No show\"},{\"id\": 2,\"text\": \"Deposit\"}]",
		"DataSource": null,
		"Required": true,
		"Visible": true,
		"ReadOnly": false,
		"CompleteObj": true
	},
	{
		"IdAppForm": 77,
		"Id": 7700007,
		"FieldName": "ChargeForm",
		"FieldType": "char",
		"FieldTitle": "ChargeForm",
		"Position": 7,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "radio",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": null,
		"Required": false,
		"Visible": false,
		"ReadOnly": false
	},
	{
		"IdAppForm": 77,
		"Id": 7700008,
		"FieldName": "ChargeType",
		"FieldType": "char",
		"FieldTitle": "ChargeType",
		"Position": 8,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "radio",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": null,
		"Required": false,
		"Visible": false,
		"ReadOnly": false
	},
	{
		"IdAppForm": 77,
		"Id": 7700009,
		"FieldName": "ChargeValue",
		"FieldType": "money",
		"FieldTitle": "Charge Value",
		"Position": 9,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "number",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": null,
		"Required": true,
		"Visible": true,
		"ReadOnly": false
	},
	{
		"IdAppForm": 77,
		"Id": 7700010,
		"FieldName": "NightsToCharge",
		"FieldType": "int",
		"FieldTitle": "Nights To Charge",
		"Position": 10,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "number",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": null,
		"Required": false,
		"Visible": true,
		"ReadOnly": false
	},
	{
		"IdAppForm": 77,
		"Id": 7700011,
		"FieldName": "PriorArrivalDays",
		"FieldType": "int",
		"FieldTitle": "Prior Arrival Days",
		"Position": 11,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "number",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": null,
		"Required": false,
		"Visible": true,
		"ReadOnly": false
	},
	{
		"IdAppForm": 77,
		"Id": 7700012,
		"FieldName": "Comments",
		"FieldType": "varchar",
		"FieldTitle": "Comments",
		"Position": 12,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "textarea",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": null,
		"Required": false,
		"Visible": true,
		"ReadOnly": false
	},
	{
		"IdAppForm": 77,
		"Id": 7700013,
		"FieldName": "Active",
		"FieldType": "bit",
		"FieldTitle": "Active",
		"Position": 13,
		"cssClasss": "col-12 col-md-6",
		"HtmlType": "radio",
		"PlaceHolder": "",
		"DefaultValue": "{\"1\"}",
		"OptionValues": "[{\"id\": 1,\"text\": \"Yes\"},{\"id\": 0,\"text\": \"No\"}]",
		"DataSource": null,
		"Required": false,
		"Visible": false,
		"ReadOnly": false
	},
	{
		"IdAppForm": 77,
		"Id": 7700021,
		"FieldName": "GUID",
		"FieldType": "uniqueidentifier",
		"FieldTitle": "GUID",
		"Position": 21,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "text",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": null,
		"Required": false,
		"Visible": false,
		"ReadOnly": false
	}
]

export default RATEPERIODPOLICIESFIELDS;
import React, { useEffect, useState } from 'react'
import { Col, Placeholder, Row } from 'react-bootstrap'


import { v4 as uuidv4 } from "uuid";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import RatePeriodDetails from './RatePeriodDetails'
import API from '../../../api/api';
import { parsedUser } from '../../../utils/GetCurrentUser';


const RatePeriodContractTab = (props) => {
  const [actionMode, setActionMode] = useState(null);
  const [record, setRecord] = useState(null); //Business Unit
  const [records, setRecords] = useState([]) //Business Units
  const [showAddEditRate, setShowAddEditRate] = useState(false);
  const [recordRatePeriod, setRecordRatePeriod] = useState(null);
  const toggleRate = (obj, mode) => {
    setShowAddEditRate(!showAddEditRate);
    setActionMode(mode)

    if (obj) {
      let exist = record.RatePeriods.filter(x => x.GUID === obj.GUID)[0];
      if (exist) {

        let objs = record.RatePeriods;
        objs.filter(x => x.GUID === obj.GUID)[0] = obj;

        setRecord({ ...record, RatePeriods: objs })
      } else {
        let objs = record.RatePeriods;
        objs.push(obj);
        setRecord({ ...record, RatePeriods: objs })


      }
    }
  }

  const [businessUniteSelection, setBusinessUniteSelection] = useState(null);


  //Delete the record
  const deleteConfirm = async (obj) => {
    confirmAlert({
      message: "Are you sure you want to delete this record?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {

            let items = records;
            items.filter(x => x === obj)[0].Delete = true;
            console.log(items);
            setRecords(items);
            setRecord(null);
            // setRatePeriodSelect(null);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  const [businessUnitRecords, setBusinessUnitRecords] = useState([]);
  const [businessUnitRecordsSelected, setBusinessUnitRecordsSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const user = parsedUser();
  const bindDataRemoteBusinessUnit = async () => {
    try {
      const id = 'be60cb6ae9e3b5bfc7eb84cb4127a5f0b071e40ad1a9dd2dc20035c778f507ef';
      const query = `token=${user.Token}&id=${id}`;
      const request = await API.getAction("api/selector", query);
      const results = request.data[0];
      console.log(results);
      const parsedResults = JSON.parse(results.JSONData)[0];

      if (parsedResults.Error) {
        throw Error(parsedResults.Msg);
      }

      const recordResult = JSON.parse(parsedResults.JSONData);

      if (recordResult) {
        setBusinessUnitRecords(recordResult);
      }

    } catch (error) {
      console.error(error);
    } finally {

    }
  };
  const addToList = () => {
    let r = records;
    let item = businessUnitRecords.filter(x => x.Id === businessUnitRecordsSelected * 1)[0];
    console.log(item);
    if (item !== undefined) {
      let newRecord = {
        GUID: uuidv4(),
        BusinessUnitDesc: item.Description,
        IdBusinessUnit: item.Id,
        Delete: false,
        RatePeriods: [],
      }

      setRecords([...records, newRecord]);
    }
  }

  const removeFromList = (e, item) => {
    e.stopPropagation();
    setRecords(records.filter((i) => i !== item))
    setRecord(null);
  }

  useEffect(() => {
    bindDataRemoteBusinessUnit();
  }, [])

  useEffect(() => {
    console.log("rate period update", record)
  }, [record])
  useEffect(() => {
    console.log("rate periods update", records)
    props.update(records);
  }, [records])
  return (
    <>
      {showAddEditRate ? <RatePeriodDetails businessUnitSelected={record}
        record={recordRatePeriod}
        toggle={toggleRate} show={showAddEditRate} actionMode={actionMode} /> : null}

      <Row className='mx-0' style={{ minHeight: '200px' }}>
        <Col xs={4} className='bg-light border-end py-4'>
          <div className='d-flex flex-column'>
            <div className="d-flex gx-3 align-items-center justify-content-end px-2 mb-2">
              {isLoading ?
                <Placeholder className="w-100 rounded" style={{ height: '2rem' }} animation='wave' bg='secondary' />
                : (<select className='form-select'
                  onChange={(e) => setBusinessUnitRecordsSelected(e.target.value)}
                  name='businessUnit'
                  id='businessUnit'
                  defaultValue={''}
                >
                  <option value="">-Assign Business Unit-</option>
                  {businessUnitRecords.map((item, index) => (
                    <option key={index} value={item.Id}>{item.Description}</option>
                  ))}
                </select>)}
              <button className="btn btn-primary btn-sm mx-2" type={'button'} onClick={addToList} disabled={!businessUnitRecordsSelected} >
                <i className="fa fa-plus"></i>
              </button>
            </div>
            <ul className='list-group px-2'>
              {records && records.map((item, index) => {

                return (
                  <li key={index} className={`list-group-item list-group-item-action d-flex justify-content-between align-items-center ${(record && item.GUID === record.GUID ? "active" : "")}`} onClick={() => {
                    console.log(item)
                    //     props.selection(item);
                    setRecord(item)
                  }}>
                    {item.BusinessUnitDesc}
                    <button
                      className="btn btn-sm text-danger"
                      type={'button'}
                      onClick={(e) => removeFromList(e, item)}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </li>
                )
              })

              }
            </ul>
          </div>

        </Col>
        <Col xs={8} className='gap-2 py-4 bg-white'>
          {record ? <Row>
            <Col>
              <table className=' table table-bordered table-striped'>
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Description</th>
                    <th>Date Start</th>
                    <th>Date End</th>
                  </tr>
                </thead>
                <tbody>
                  {!record ?
                    <tr>
                      <td colSpan={4} className='text-center py-4'>
                        Select a Business Unit
                      </td>
                    </tr> : null}
                  {record.RatePeriods && record.RatePeriods.length === 0 ?
                    <tr>
                      <td colSpan={4} className='text-center py-4'>
                        No data
                      </td>
                    </tr>
                    : null}
                  {record.RatePeriods && record.RatePeriods.filter(x => x.Delete === false).map((item, index) => {
                    console.log(item, businessUniteSelection);
                    return (<tr key={index} onClick={() => {
                      setRecordRatePeriod(item)
                    }}
                      className={recordRatePeriod && recordRatePeriod.GUID === item.GUID ? "active" : ""}
                    >
                      <td>{item.Code}</td>
                      <td>{item.Description}</td>
                      <td>{item.DateStart}</td>
                      <td>{item.DateEnd}</td>
                    </tr>)
                  })}
                </tbody>
              </table>

            </Col>
            <Col xs={"auto"} className=' gap-2'>
              <button className={`btn btn-primary btn-sm ${!record && 'disabled'}`} onClick={() => {
                toggleRate(null, 'I');
              }} type="button">
                <i className="fas fa-plus"></i>
              </button>
              {record &&
                (<>
                  <br />
                  <button className={`btn btn-sm`} onClick={() => toggleRate(record, 'U')} type="button">
                    <i className="fas fa-pencil"></i>
                  </button>
                  <br />
                  <button className={`btn btn-sm text-danger `} onClick={() => deleteConfirm(record)} type="button">
                    <i className="fas fa-trash"></i>
                  </button>
                </>)}
            </Col>
          </Row>
            : <div className='d-flex justify-content-center align-items-center w-100 h-100'>
              <p className="h4 text-center text-muted">Please add and select a new Business Unit</p>
            </div>
          }
        </Col>
      </Row >
    </>
  )
}

export default RatePeriodContractTab


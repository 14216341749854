import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row, Table } from 'react-bootstrap'
import { confirmAlert } from 'react-confirm-alert'
import { useToast } from '../../../contexts/toast'
import { actionsModel } from '../../../enum/actionsModel.enum'
import CommentHistoryModal from './CommentHistoryModal'

const CommentHistoryTab = (props) => {

  const toast = useToast();
  const [show, setShow] = useState(false);
  const [actionMode, setActionMode] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [data, setData] = useState([])
  const [action, setAction] = useState(null)

  const toggleOpen = async (action) => {
    setActionMode(action);
  };

  const clearSelection = () => {
    if (selectedItem) setSelectedItem(null);
    setActionMode(null);
  }

  const removeItem = (item) => {
    confirmAlert({
      message: `Are you sure you want to delete this record?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            console.log('Actual selection? ', selectedItem)
            let newData = data.filter((x) => x.id !== item.id);
            setData(newData);
            clearSelection();
            toast({
              type: "error",
              message: "Comment removed successfully",
            })
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  const submit = (submitAction, item) => {
    if (submitAction === actionsModel.CREATE) {
      addNewItem(item);
    } else if (submitAction === actionsModel.EDIT) {
      editItem(item);
    }
  }

  const addNewItem = (item) => {
    let newData = [...data, {
      ...item,
      id: data.length + 1,
    }];
    setData(newData);
    toast({
      type: "success",
      message: "Comment added successfully",
    })
  }

  const editItem = (item) => {
    console.log('Edit item', item)
    let newData = data.map((x) => {
      if (x.id === item.id) {
        return item;
      }
      return x;
    });
    setData(newData);
    toast({
      type: "info",
      message: "Comment edited successfully",
    })
  }

  const actionSelected = (action) => {

    setAction(action)
    console.log('Action', { actionSelected: action })

    if (action === actionsModel.CREATE) {
      setSelectedItem(null)
      setShow(true)
    };
    setShow(action === actionsModel.EDIT || action === actionsModel.CREATE || action === actionsModel.VIEW);
    if (action === actionsModel.VIEW) toggleOpen("C");
    if (action === actionsModel.EDIT) toggleOpen("U");
  }

  useEffect(() => {
    if (props.ParentState) {
      setData(props.ParentState.Comments)
    }
  }, [props.ParentState])

  useEffect(() => {
    props.update(data)
  }, [data])

  return (
    <Container id="tab-grid-body-comment-history" fluid className=" ">
      <Card>
        <Card.Body className="p-0">
          <Row className="mx-0">
            <Col className="py-2">
              <Row>
                <Col>
                  <Table bordered striped hover responsive>
                    <thead>
                      <tr>
                        <th scope="col">Comment Type</th>
                        <th scope="col">Comment</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data.filter(x => !x.Delete).map((item, index) => {
                        return (<tr key={index} className={`${selectedItem?.id === item?.id ? "active" : ""}`}
                          onClick={() => {
                            setSelectedItem(item)
                          }}>
                          <td>{item.IdCommentType}</td>
                          <td>{item.Comments}</td>
                        </tr>)
                      })}
                      {data && data.filter(x => !x.Delete).length === 0 ?
                        <tr>
                          <td colSpan={4} className="text-center p-4">
                            No Data
                          </td>
                        </tr>
                        : null}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Col>
            <Col className="col-auto ml-auto text-center bg-light border">
              <div className="d-flex flex-column gy-3 align-items-center justify-content-center">
                <button
                  type="button"
                  className="btn btn-primary my-2 btn-sm"
                  onClick={() => actionSelected(actionsModel.CREATE, {})}
                  title="Add"
                >
                  <i className="fa fa-plus"></i>
                </button>
                {
                  selectedItem && (
                    <>
                      <button
                        type="button"
                        className="btn my-2 btn-sm"
                        onClick={() => actionSelected(actionsModel.EDIT, data)}
                        title="Edit"
                      >
                        <i className="fa fa-pen"></i>
                      </button>
                      <button
                        type="button"
                        className="btn my-2 btn-sm text-danger"
                        onClick={() => removeItem(selectedItem)}
                        title="Delete"
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </>
                  )
                }
              </div>
            </Col>
          </Row>
          {show && <CommentHistoryModal action={action} data={selectedItem} setShow={setShow} clearSelection={clearSelection} isOpen={show} submitComment={submit} />}
        </Card.Body>
      </Card>
    </Container>
  )
}

export default CommentHistoryTab

import React, { useEffect, useState } from 'react';
import { Container, Dropdown } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { useLocation } from 'react-router-dom';
import API from '../../api/api';
import { CustomBreadcrumbs } from '../../components';

import { ContractForm, ContractDataGrid } from '../../components/contracts';
import { actionsModel } from '../../enum/actionsModel.enum';
import useFavorite from '../../hooks/useFavorite';
import { parsedUser } from '../../utils/GetCurrentUser';
import { exportToExcel } from '../../utils/export-to-excel';
import { exportToPDF as GeneratePDF } from '../../utils/export-to-pdf';
import { generateColumnsFromJson } from '../../utils/generate-columns';

import { DATAMODEL } from './dataModel';

const ContractsV2 = () => {

  const user = parsedUser();
  const { pathname } = useLocation();
  const [actionMode, setActionMode] = useState(null); //I=Insert, U= Update = C= Consultar, D= Delete
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  // const [columns, setColumns] = useState([]);
  const [data, setData] = useState([])
  const [showForm, setShowForm] = useState(false);

  const { UpdateFavorites, checkIfPinned, isPinned } = useFavorite()



  useEffect(() => {
    //setColumns(generateColumnsFromJson(data[0]));
  }, [data]);

  useEffect(() => {
    checkIfPinned();
    //setData(FAKE_DATA);
    bindDataRemote()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Bring all the records 
  const bindDataRemote = async () => {
    setIsLoading(true);

    try {
      let query = `token=${user.Token}`;
      let request = await API.getAction("api/ContractsManager/ContractsV2List", query);

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData).JSONData;
      let recordResult = parsedResults;
      if (recordResult === null) {
        throw new Error("No data found");
      }
      console.log("Results from record", recordResult);
      setData(recordResult)
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };


  //Bring all the details for the selected record
  const bindDataDetailsRemote = async () => {
    setIsLoading(true);

    try {
      let query = `token=${user.Token}&id=${selectedItem.GUID}`;
      let request = await API.getAction("api/ContractsManager/ContractsV2Details", query);

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData).JSONData;
      let recordResult = parsedResults;
      if (recordResult === null) {
        throw new Error("No data found");
      }
      console.log("Results from record", recordResult);
      let record = DATAMODEL;
      //Now we need to go over the entire result and assign values to the record
      Object.keys(recordResult).forEach(item => {
        record[item] = recordResult[item];
      })
      setSelectedItem(record);
      console.log("finish assigning record", record);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const actionSelected = (action) => {


    // TODO For edit and create, we need to pass the data to the modal.
    // setData([])

    if (action === actionsModel.CREATE) {
      setSelectedItem(null)
      toggleOpen("I")
    };
    setShowForm(action === actionsModel.EDIT || action === actionsModel.CREATE || action === actionsModel.VIEW);
    if (action === actionsModel.VIEW) toggleOpen("C");
    if (action === actionsModel.EDIT) {
      toggleOpen("U")
      bindDataDetailsRemote();
    };
    if (action === actionsModel.DELETE) {
      confirmAlert({
        message: 'Are you sure to delete this item?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => console.log('Click Yes')
          },
          {
            label: 'No',
            onClick: () => console.log('Click No')
          }
        ]
      });
    }
  }

  const toggleOpen = async (action) => {
    setActionMode(action);
  };

  const manageFav = () => {
    UpdateFavorites({
      icon: 'fa-solid fa-file-lines',
      path: pathname,
      formPath: 'Rates Management / Contract Manager / Contracts v2',
      title: 'Contracts V2',
      user
    })
  }

  //Delete the record
  const deleteConfirm = async (obj) => {
    confirmAlert({
      message: "Are you sure you want to delete this record?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            console.log('Delete confirmed')
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const exportToPDF = async () => {
    GeneratePDF(document.getElementById("contentContainer"));
  }

  const clearSelection = () => {
    setSelectedItem(null);
    setActionMode(null);
  }

  return (
    <React.Fragment>
      <Container>
        <CustomBreadcrumbs pathTitle={'Rates Management / Contract Manager / Contracts v2'} />
        <div className="row mx-0" id="contentContainer">
          <div className="col-12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2">
              {isLoading ? (
                <React.Fragment>
                  <div className="">
                    <div className="w-25 mx-auto my-1">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="d-flex align-items-center my-1">
                    <i className="fa-solid fa-file-lines me-3 fa-2x"></i>
                    <div className="d-flex flex-row">
                      <h5 className="my-1">Contracts</h5>
                    </div>
                  </div>
                </React.Fragment>
              )}
              <div className="d-flex">
                {actionMode === null ? (
                  <>
                    <button className="btn btn-primary btn-sm my-1 me-2" title="Create" disabled={isLoading} onClick={() => actionSelected(actionsModel.CREATE, {})}>
                      <i className="fa fa-plus"></i>
                    </button>
                    {
                      <button className={`btn btn-sm my-1 me-2`} title="Search" onClick={() => setIsSearching(prev => !prev)}>
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </button>
                    }
                    {selectedItem &&
                      <>
                        <button className="btn btn-sm my-1 me-2" title="Update" disabled={isLoading} onClick={() => actionSelected(actionsModel.EDIT, data)}>
                          <i className="fa fa-pencil"></i>
                        </button>
                        <button className="btn btn-sm my-1 me-2" title="View" disabled={isLoading} onClick={() => actionSelected(actionsModel.VIEW, data)}>
                          <i className="fa fa-eye"></i>
                        </button>
                      </>
                    }
                  </>
                ) : null}
                <Dropdown>
                  <Dropdown.Toggle variant="button" id="dropdown-basic">
                    <i className="fa fa-ellipsis-v" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => manageFav()}>{isPinned ? 'Remove from' : 'Add to'} favorites</Dropdown.Item>
                    {(actionMode === null && selectedItem) && <Dropdown.Item onClick={() => { toggleOpen('D') }}>Duplicate</Dropdown.Item>}
                    {actionMode === null ?
                      <><Dropdown.Divider /><Dropdown.Item onClick={() => exportToExcel(data, 'Contracts V2')}>Export to Excel</Dropdown.Item></> : null}
                    {actionMode === "C" ? <Dropdown.Item onClick={() => exportToPDF()}>Export to PDF</Dropdown.Item> : null}
                    {(actionMode === null && selectedItem) && <><Dropdown.Divider /><Dropdown.Item onClick={() => deleteConfirm()}>Delete</Dropdown.Item></>}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            {showForm ? <ContractForm actionMode={actionMode} data={selectedItem} setShow={setShowForm} clearSelection={clearSelection} /> :
              <ContractDataGrid
                // columns={columns}
                setSelectedItem={setSelectedItem}
                data={data}
                showSearchPanel={isSearching}
              />}

          </div>
        </div>
      </Container>
    </React.Fragment>
  )
}

export default ContractsV2
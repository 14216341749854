import React from "react";
import Toolbar, { Item } from "devextreme-react/toolbar";
import Button from "devextreme-react/button";
import UserPanel from "../user-panel/UserPanel";
import "./Header.scss";
import { Template } from "devextreme-react/core/template";
import logo from "../../assets/auth-logo-dark.png";
import { DropDownButton } from "devextreme-react";
import FavoritesBar from "../favorites-bar/FavoritesBar";

export default function Header({ menuToggleEnabled, title, toggleMenu }) {
  return (
    <header className={"header-component"}>
      <Toolbar className={"header-toolbar"}>
        <Item
          visible={menuToggleEnabled}
          location={"before"}
          widget={"dxButton"}
          cssClass={"menu-button"}
        >
          <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
        </Item>
        {/* <Item
          location={'before'}
          cssClass={'header-title'}
          text={title}
          visible={!!title}
        /> */}


        <Item location={"before"}>
          <FavoritesBar />
        </Item>
        <Item location={"after"} locateInMenu={"auto"}>
          <DropDownButton
            className="language-select"
            icon="fa fa-language"
            text="Language"
            items={[
              { id: 2, text: "English" },
              { id: 1, text: "Spanish" },
            ]}
            // Configuration goes here
          />
        </Item>
        <Item
          location={"after"}
          locateInMenu={"auto"}
          menuItemTemplate={"userPanelTemplate"}
        >
          <Button
            className={"user-button authorization"}
            // width={210}
            height={"100%"}
            stylingMode={"text"}
          >
            <UserPanel menuMode={"context"} />
          </Button>
        </Item>
        <Template name={"userPanelTemplate"}>
          <UserPanel menuMode={"list"} />
        </Template>
      </Toolbar>
    </header>
  );
}

const SPECIALRATEFIELDS = [
	{
		"IdAppForm": 80,
		"Id": 8000001,
		"FieldName": "IdContractRatePeriod",
		"FieldType": "bigint",
		"FieldTitle": "Contract Rate Period",
		"Position": 1,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "select",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": "{\"HashID\":\"c72b6b91ad943bda15484201660283a8eb56c01952d44ceee40ef3bb83b7aa92\"}",
		"Required": false,
		"Visible": false,
		"ReadOnly": false
	},
	{
		"IdAppForm": 80,
		"Id": 8000002,
		"FieldName": "IdRoomType",
		"FieldType": "bigint",
		"FieldTitle": "Room Type",
		"Position": 2,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "select",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": "{\"HashID\":\"c8cd1c30a5cd65d35b2dc5b4c0287d131a741f68a85fd9828e4216931f6ff384\"}",
		"Required": true,
		"Visible": true,
		"ReadOnly": false,
		"CompleteObj": true
	},
	{
		"IdAppForm": 80,
		"Id": 8000003,
		"FieldName": "Id",
		"FieldType": "int",
		"FieldTitle": "Id",
		"Position": 3,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": null,
		"Required": false,
		"Visible": false,
		"ReadOnly": false
	},
	{
		"IdAppForm": 80,
		"Id": 8000004,
		"FieldName": "DateStart",
		"FieldType": "datetime",
		"FieldTitle": "Date Start",
		"Position": 4,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "date",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": null,
		"Required": false,
		"Visible": true,
		"ReadOnly": false
	},
	{
		"IdAppForm": 80,
		"Id": 8000005,
		"FieldName": "DateEnd",
		"FieldType": "datetime",
		"FieldTitle": "Date End",
		"Position": 5,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "date",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": null,
		"Required": false,
		"Visible": true,
		"ReadOnly": false
	},
	{
		"IdAppForm": 80,
		"Id": 8000006,
		"FieldName": "DateStartArrival",
		"FieldType": "datetime",
		"FieldTitle": "Date Start Arrival",
		"Position": 6,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "date",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": null,
		"Required": false,
		"Visible": true,
		"ReadOnly": false
	},
	{
		"IdAppForm": 80,
		"Id": 8000007,
		"FieldName": "DateEndArrival",
		"FieldType": "datetime",
		"FieldTitle": "Date End Arrival",
		"Position": 7,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "date",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": null,
		"Required": false,
		"Visible": true,
		"ReadOnly": false
	},
	{
		"IdAppForm": 80,
		"Id": 8000008,
		"FieldName": "DateStartBooking",
		"FieldType": "datetime",
		"FieldTitle": "Date Start Booking",
		"Position": 8,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "date",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": null,
		"Required": false,
		"Visible": true,
		"ReadOnly": false
	},
	{
		"IdAppForm": 80,
		"Id": 8000009,
		"FieldName": "DateEndBooking",
		"FieldType": "datetime",
		"FieldTitle": "Date End Booking",
		"Position": 9,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "date",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": null,
		"Required": false,
		"Visible": true,
		"ReadOnly": false
	},
	{
		"IdAppForm": 80,
		"Id": 8000010,
		"FieldName": "MinimumStayDays",
		"FieldType": "int",
		"FieldTitle": "Minimum Stay Days",
		"Position": 10,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "number",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": null,
		"Required": false,
		"Visible": false,
		"ReadOnly": false
	},
	{
		"IdAppForm": 80,
		"Id": 8000011,
		"FieldName": "Comments",
		"FieldType": "varchar",
		"FieldTitle": "Comments",
		"Position": 11,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "textarea",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": null,
		"Required": false,
		"Visible": true,
		"ReadOnly": false
	},
	{
		"IdAppForm": 80,
		"Id": 8000012,
		"FieldName": "Active",
		"FieldType": "bit",
		"FieldTitle": "Active",
		"Position": 12,
		"cssClasss": "col-12 col-md-6",
		"HtmlType": "radio",
		"PlaceHolder": "",
		"DefaultValue": "{\"1\"}",
		"OptionValues": "[{\"id\": 0,\"text\": \"No\"},{\"id\": 1,\"text\": \"Si\"}]",
		"DataSource": null,
		"Required": false,
		"Visible": false,
		"ReadOnly": false
	},
	{
		"IdAppForm": 80,
		"Id": 8000020,
		"FieldName": "GUID",
		"FieldType": "uniqueidentifier",
		"FieldTitle": "GUID",
		"Position": 20,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "text",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": null,
		"Required": false,
		"Visible": false,
		"ReadOnly": false
	}
]
export default SPECIALRATEFIELDS;
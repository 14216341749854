export const generateColumnsFromJson = (jsonData, excludedProperties = []) => {
  const dataTypeMapping = {
    string: "string",
    number: "number",
    boolean: "boolean",
    object: value => (value instanceof Date ? "date" : undefined),
  };

  const columns = Object.keys(jsonData)
    .filter(key => !excludedProperties.includes(key))
    .map(key => {
      const dataTypeResolver = dataTypeMapping[typeof jsonData[key]];
      const dataType = typeof dataTypeResolver === "function" ? dataTypeResolver(jsonData[key]) : dataTypeResolver;

      if (dataType !== undefined) {
        const column = {
          dataField: key,
          caption: key.charAt(0).toUpperCase() + key.slice(1),
          dataType,
        };

        if (dataType === "date") {
          column.format = "dd/MM/yyyy";
        }

        return column;
      }

      return null;
    })
    .filter(column => column !== null);

  return columns;
}

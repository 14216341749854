import React from 'react';
import './Footer.scss';
import { useAuth } from '../../contexts/auth';
import { useFormik } from 'formik';
import { db } from '../../utils/db';
import { parsedUser } from '../../utils/GetCurrentUser';


export default function Footer() {
  const { user } = useAuth();
  const userLogged = parsedUser();
  const checkAudit = localStorage.getItem('AuditInformation')

  const footerItems = [
    {
      icon: <i className="fa-solid fa-user"></i>,
      key: "Username"
    },
    {
      icon: <i className="fa-solid fa-lock"></i>,
      key: "Token",
      format: (token) => token.replace(/(...)(?=.)/gm, "$1-")
    }
  ]


  const handleChange = async (e) => { localStorage.setItem('AuditInformation', e.target.checked) }

  return (
    <footer className={'footer '}>

      <form >
        <div className="form-check form-switch footer-item">
          <input className="form-check-input"
            onChange={handleChange}
            defaultChecked={checkAudit === 'true' ? true : false}
            type="checkbox" id="CheckDefault3" name='AuditInformation' />
          <label className="form-check-label" htmlFor="CheckDefault3">Audit Information</label>
        </div>
      </form>

      {user && footerItems.map(({ icon, key, format }) => (
        <div key={key} className="footer-item">
          <div className="footer-item-icon">{icon}</div>
          <div className="footer-item-text">{format ? format(user[key]) : user[key]}</div>
        </div>
      ))}
    </footer>
  );
}

import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Search,
  CleaningServices,
} from "@mui/icons-material";
import "devextreme/data/odata/store";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Card, Col, Container, Dropdown, Form, Modal, Row } from "react-bootstrap";
import * as yup from "yup";
import { DataGrid, Pager, Paging, Selection } from "devextreme-react/data-grid";

import API from "../../api/api";
import { parsedUser } from "../../utils/GetCurrentUser";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TextBox } from "devextreme-react";
import FieldSelector from "../../components/customComponents/FieldSelector";
import { useFormik } from "formik";
import Sha256 from "../../utils/sha256";
import ROUTES from "../../utils/routes";
import ClearUser from "../../utils/ClearUser";
import { db } from "../../utils/db";
import { useToast } from "../../contexts/toast";
import yupTypeValidator from "../../utils/YupTypeValidator";
import { exportToPDF as GeneratePDF } from '../../utils/export-to-pdf';
import { exportToExcel as GenerateXLS } from '../../utils/export-to-excel';
import { CustomBreadcrumbs as Breadcrumbs } from "../../components";
import useFavorite from "../../hooks/useFavorite";

export default function CRUDSimple() {
  // PROPERTIES
  const user = parsedUser();
  const { pathname } = useLocation();
  const params = useParams();
  const { idModule } = useParams();
  const toast = useToast();
  const navigate = useNavigate();

  const [generalError, setGeneralError] = useState(null);
  const [open, setOpen] = useState(false);
  const [actionMode, setActionMode] = useState(null);//I=Insert, U= Update = C= Consultar, D= Delete
  const [loading, setLoading] = useState(false);
  // const [isPinned, setIsPinned] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const checkAudit = localStorage.getItem('AuditInformation')

  const initialModel = {
    section: {},
    data: {
      Columns: [],
      Rows: [],
    },
  }
  const [model, setModel] = useState(initialModel);
  const [search, setSearch] = useState("");
  const allowedPageSizes = [10, 20, 50, 100, "all"];
  const [selectedItem, setSelectedItem] = useState(null);
  const { isPinned, UpdateFavorites, checkIfPinned } = useFavorite()
  const selectedItemRef = useRef(null);
  // END PROPERTIES



  //METHODS
  ///BRING THE DATA FROM THE SERVER FOR MODEL AND RECORDS
  const bindDataRemote = async (cleanFilter) => {

    // First start loading the model
    setLoading(true);

    //First load records

    try {
      let query = `id=${params.id}&token=${user.Token}${idModule ? "&IdModule=" + idModule : ""}`;
      if (search.length > 0 && cleanFilter == null) {
        query += "&Search=" + search;

      }
      let prefix = idModule ? "CRUDDynamic" : "CRUD";
      let request = await API.getAction(`api/${prefix}/Records`, query);
      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];

      let recordResult = parsedResults.JSONData || [];
      // let configInfo = recordResult[0].JSONConfig ? recordResult[0].JSONConfig[0] : null;
      let dataFromAPI = recordResult[0] ? (recordResult[0].JSONConfig ? recordResult[0].JSONData : recordResult) : [];
      console.log("Results from record", recordResult);


      //Now let's load sections
      let querySections = `id=${params.id}&token=${user.Token}${idModule ? "&IdModule=" + idModule : ""}`;
      let requestSections = await API.getAction(`api/${prefix}/Model`, querySections);
      let resultsSections = requestSections.data[0];
      let parsedResultsSections = JSON.parse(resultsSections.JSONData)[0];
      if (parsedResultsSections.Error) {
        alert(parsedResultsSections.Msg);
        return;
      }

      let recordResultSection = parsedResultsSections.JSONData;

      //TODO: | Use JSONConfig to show specific fields
      let gridData = null;
      let gridColumns = dataFromAPI && dataFromAPI.length > 0 ? Object.keys(dataFromAPI[0]).filter(x => !x.startsWith("Id")) : null;

      //* If there is a config, then we need to sort the fields to show them in the grid
      // if (configInfo && configInfo.Fields) {

      //   const sortedFields = configInfo.Fields.sort((a, b) => a.Position - b.Position);

      //   //? Here I reduce the fields to only the ones that are visible
      //   gridColumns = sortedFields.reduce((acc, item) => {
      //     (item.Visible) && (acc.push(item.FieldName))
      //     return acc;
      //   }, [])

      //   gridData = dataFromAPI.map((item) => {
      //     return gridColumns.reduce((acc, key) => {
      //       acc[key] = item[key];
      //       acc["Id"] = item["Id"];
      //       return acc;
      //     }, {})
      //   })
      // }

      //! If grid data stays null, we use the data from the API

      let titles = ['Audit Information', 'Added by', 'Date Added', 'Modified by', 'Last Modified']

      setModel({
        ...model,
        section: checkAudit === 'true' ? recordResultSection[0] : { ...recordResultSection[0], Fields: recordResultSection[0].Fields.filter((item) => !titles.includes(item.FieldTitle)) },
        data: {
          ...model.data,
          Columns: gridColumns,
          Rows: gridData || dataFromAPI,
        },
      });

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => { bindDataRemote() }, [checkAudit])

  const bindDataDetailsRemote = async (recordId) => {
    //First load records
    try {
      let prefix = idModule ? "CRUDDynamic" : "CRUD";
      let query = `id=${params.id}&token=${user.Token}&RecordId=${recordId}${idModule ? "&IdModule=" + idModule : ""}`;
      let request = await API.getAction(`api/${prefix}/Details`, query);
      let results = request.data[0];

      let parsedResults = JSON.parse(results.JSONData)[0];
      if (parsedResults.Error) {
        alert(parsedResults.Msg);
        return;
      }
      let finalResult = parsedResults.JSONData[0];
      fillFormikObject(finalResult);
      setSelectedItem(null)
    } catch (error) {
      console.error(error)
    }
    //Fill object with new values
  };

  //Open edit/add mode
  const toggleOpen = async (action, reload) => {
    setActionMode(action);

    if (action === null) {
      setSelectedItem(null);
      formik.resetForm();
    }

    if (action === "I") {
      setSelectedItem(null)
    }
    if (action === "U" || action === "C" || action === "D") {
      setOpen(!open);
      await bindDataDetailsRemote(selectedItem.Id);
    }

    if (selectedItemRef.current) {
      selectedItemRef.current.clearSelection();
    }

    setGeneralError(null);
    setOpen(!open);
    if (reload) {
      bindDataRemote();
    }
  };

  //Delete the record
  const deleteConfirm = async (obj) => {
    confirmAlert({
      closeOnClickOutside: false,
      message: "Are you sure you want to delete this record?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {

            let prefix = idModule ? "CRUDDynamic" : "CRUD";
            let query = `id=${params.id}&token=${user.Token}&RecordId=${selectedItem.Id}${idModule ? "&IdModule=" + idModule : ""}`;
            await API.getAction(`api/${prefix}/Delete`, query);
            await bindDataRemote();
            setSelectedItem(null);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  //Update the field value on formik according to what has been updated
  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };



  const [validationsSchema, setValidationSchema] = useState(null);

  const fillFormikObject = (record) => {
    console.log("record", record, "model", model.section);
    if (
      model.section &&
      model.section.Fields &&
      model.section.Fields.length > 0
    ) {
      model.section.Fields.forEach((item) => {
        console.log(
          "field name",
          item.FieldName,
          "RecordValue ",
          record[item.FieldName]
        );
        formik.setFieldValue(item.FieldName, record[item.FieldName]);
      });
    }

    //Now
  };

  const prepareFormikObject = () => {
    console.log("Preparing formik object", model.section)
    let initialObject = {};
    let initialObjectValidation = {};

    if (model.section.Fields) {
      model.section.Fields.forEach((item) => {
        initialObject[item.FieldName] = item.value;

        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }
    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };

  //This method is to save the record we are working on
  const handleSubmit = async (obj, event) => {
    console.log("❗ ~ file: CRUDSimple.js:259 ~ handleSubmit ~ obj:", obj)
    console.log({ fields: model.section.Fields })
    let actions = {
      "I": "I",
      "U": "U",
      "D": "I"
    };

    if (actionMode === "D") {
      const { GUID, Id, ...rest } = obj;
      obj = rest;
    }


    confirmAlert({
      closeOnClickOutside: false,
      message: "Are you sure you want to save this record?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            let objSPVersion = "";
            model.section.Fields.forEach((item) => {

              if (item.ReadOnly || item.HtmlType === "separator") return;

              if (item.HtmlType !== "separator") {
                objSPVersion +=
                  (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
              }
              if (
                item.FieldType === "int" ||
                item.FieldType === "bool" ||
                item.FieldType === "bit" ||
                item.FieldType === "bigint"
              ) {
                objSPVersion +=
                  obj[item.FieldName] === undefined || obj[item.FieldName] === null
                    ? item.DefaultValue && item.DefaultValue.length > 0 ? item.DefaultValue : "null"
                    : "'" + obj[item.FieldName] + "'";
                //objSPVersion +=
                //obj[item.FieldName] === undefined ? "null" : obj[item.FieldName];
              } else if (item.HtmlType === "password") {
                let passwordEncrypted = null;
                if (obj[item.FieldName] && obj[item.FieldName].length > 0) {
                  passwordEncrypted =
                    obj["UserName"].toUpperCase() + obj[item.FieldName];
                  passwordEncrypted = Sha256.hash(passwordEncrypted);
                  ;
                }
                objSPVersion +=
                  obj[item.FieldName] === undefined
                    ? "null"
                    : "'" + passwordEncrypted + "'"
              } else
                if (item.HtmlType === 'radio') {

                  objSPVersion +=
                    obj[item.FieldName] === undefined || obj[item.FieldName] === null
                      ? item.DefaultValue && item.DefaultValue.length > 0 ? item.DefaultValue : "null"
                      : "'" + obj[item.FieldName] + "'";
                } else {
                  objSPVersion +=
                    obj[item.FieldName] === undefined || obj[item.FieldName] === null
                      ? "null"
                      : "'" + obj[item.FieldName] + "'";
                }
            });
            let queryString = `Id=${params.id}&Token=${user.Token}&ActionMode=${actions[actionMode]}${idModule ? "&IdModule=" + idModule : ""}`;
            let queryData = {
              Data: objSPVersion,
            };

            let prefix = idModule ? "CRUDDynamic" : "CRUD";
            let request = await API.postAction(
              `api/${prefix}/AddEdit?${queryString}`,
              queryData
            );

            if (request.status === 200) {
              let response = JSON.parse(request.data[0].JSONData)[0];
              if (response.Error) {
                setGeneralError(response.Msg);
                return;
              }
            }
            setGeneralError(null);
            setSelectedItem(null);

            formik.resetForm();

            toast({
              type: "success",
              "message": "Record saved successfully",
            })
            toggleOpen(null, true);
          },
        },
        {
          label: "No",
        },
      ],
    });


  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,

    validateOnChange: false,
    validateOnBlur: false,

    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  // const checkIfPinned = async () => {
  //   try {
  //     await db.favorites.where("username").equals(user.Username).toArray((item) => {
  //       if (item.length > 0) {
  //         const favExists = item[0].values.some((fav) => fav.path === pathname);
  //         setIsPinned(favExists)
  //       }
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  const addFavorite = async () => {

    const newFavorite = {
      icon: `${model.section.FormIcon}`,
      path: pathname,
      formPath: model.section.FormPath,
      title: model.section.FormTitle,
    }
    UpdateFavorites(newFavorite)

    // try {
    //   await db.favorites.where("username").equals(user.Username).modify((item) => {

    //     const favExists = item.values.some((fav) => fav.path === newFavorite.path);

    //     if (favExists) {
    //       setIsPinned(false)
    //       item.values = item.values.filter((fav) => fav.path !== newFavorite.path);
    //       toast({
    //         type: "warning",
    //         message: "Removed from favorites",
    //       });
    //       return;
    //     }

    //     setIsPinned(true)
    //     item.values.push(newFavorite);
    //     toast({
    //       type: "success",
    //       message: "Added to favorites",
    //     });
    //   });
    // } catch (error) {
    //   console.error(error)
    // }
  }

  const exportToExcel = useCallback(async () => {
    GenerateXLS(model.data.Rows, `${model.section.FormTitle}.xlsx`);
  }, [model.data.Rows, model.section.FormTitle])

  const exportToPDF = useCallback(async () => {
    const container = document.getElementById("contentContainer");
    GeneratePDF(container);
  }, [])

  // END METHODS

  // UI METHODS



  const loadGRIDUI = () => {
    return (
      <>
        {isSearching && (
          <Card className="mb-4">
            <Card.Header className="w-full d-flex justify-content-between align-items-center">
              <Card.Title>Filters</Card.Title>

              <button
                className="btn btn-secondary"
                title="Close"
                type="button"
                onClick={() => { setIsSearching(false) }}
                style={{
                  "padding": "0px 8px",
                  "lineHeight": "23px"
                }}
              >
                <i style={{ fontSize: "10px" }} className="fa-solid fa-x"></i>
              </button>
            </Card.Header>
            <Form
              onSubmit={async (e) => {
                e.preventDefault();
                await bindDataRemote();
              }}
            >
              <Card.Body className="formBackground p-0">
                <Row>
                  <Col className={"col-12"}>
                    <TextBox
                      // className="form-control "
                      id={"search"}
                      mode={"text"}
                      value={search}
                      placeholder="Search by description"
                      onValueChanged={(e) => {
                        setSearch(e.value);
                      }}
                    />
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-end">
                <button
                  className="btn btn-sm btn-secondary me-2"
                  type="button"
                  title="Clear"
                  onClick={() => {
                    setSearch("");
                    setTimeout(async () => {
                      await bindDataRemote(true);
                    }, 1000);
                  }}
                >
                  <CleaningServices />
                </button>
                <button className="btn btn-sm btn-primary" title="Search" type="submit">
                  <Search />
                </button>
              </Card.Footer>
            </Form>
          </Card>
        )
        }
        {/* <Card className="shadow-sm">

          <Card.Body className="p-0">
          
          </Card.Body>
        </Card> */}
        <DataGrid
          dataSource={model.data.Rows}
          columns={model.data.Columns}
          columnAutoWidth={true}
          showBorders={true}
          className="grid"
          onSelectionChanged={selectItem}
          loadPanel={{
            enabled: true,
            showIndicator: true,
            text: "Loading...",
          }}
        >
          <Selection mode="single" />
          <Paging
            defaultPageSize={10}
          />
          <Pager
            showInfo={true}
            infoText={"Page:"}
            visible={true}
            showPageSizeSelector={true}
            allowedPageSizes={allowedPageSizes}
          />
        </DataGrid>
      </>
    );
  };

  //EVENTS
  const selectItem = useCallback((e) => {
    selectedItemRef.current = e.component;
    e.component.byKey(e.currentSelectedRowKeys[0]).done((item) => {
      setSelectedItem(item);
    });
  }, []);

  useEffect(() => {
    setOpen(false)
    setSelectedItem(null)
    setActionMode(null)
    setGeneralError(null)
    setModel(initialModel);
    checkIfPinned();
    setIsSearching(false);
    setSearch("");
    bindDataRemote();

    return () => formik.resetForm();
  }, [params.id]);

  useEffect(() => {

    console.log("section updated", model)
    prepareFormikObject();
  }, [model.section, model.section.Fields]);
  // END EVENTS

  return (
    <React.Fragment>
      {open ?
        <Modal
          show={open}
          onHide={() => toggleOpen(null)}
          backdrop="static"
          keyboard={false}
          size={"md"}
          className="modalRight"
        >
          <Modal.Header closeButton>
            <Modal.Title>{model.section.FormTitle}</Modal.Title>
          </Modal.Header>
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Body>
              <Row>
                {/* CONTENT */}
                {model.section &&
                  model.section.Fields.map((item, index) => {

                    // preferences.then((x) => {
                    //   let titles = ['Audit Information', 'Added by', 'Date Added', 'Modified by', 'Last Modified']
                    //   if (!x[0].value) {
                    //     if (titles.includes(item.FieldTitle)) return
                    //    }

                    // })

                    return (
                      <Col
                        className={
                          item.cssClasss + (!item.Visible ? " d-none" : "")
                        }
                        key={index}
                      >
                        <label className={item.HtmlType === 'separator' ? `me-2 ${(index !== 0 ? "mt-4 pt-4" : "mt-2")}  separatorTitle` : "me-2 mt-3 "}>{item.FieldTitle}
                          {item.Required ? <i className="required-asterisk ms-1 text-danger">*</i> : null}
                        </label>
                        <br />
                        {
                          // console.log("item", item, "formik", formik.values[item.FieldName])
                        }
                        <FieldSelector
                          actionMode={actionMode}
                          model={item}
                          key={index}
                          // limit longitudes
                          updateField={updateField}
                          value={formik.values[item.FieldName]}
                        ></FieldSelector>
                        {formik.errors[item.FieldName] ? (
                          <div className="invalid text-sm">
                            {formik.errors[item.FieldName]}
                          </div>
                        ) : null}
                      </Col>
                    );
                  })}
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex gx-3 align-items-center justify-content-end">
                {Object.values(formik.errors).length > 0 && (
                  <span className="invalid me-2">
                    Please check the forms for errors
                  </span>
                )}
                <button className="btn  me-2" type="button" onClick={() => toggleOpen(null)}>Close</button>
                <button className="btn btn-primary " type="button" onClick={() => [formik.submitForm()]}>Save</button>
              </div>
            </Modal.Footer>
          </Form>
        </Modal> : null}
      {model?.section.FormPath && <Breadcrumbs pathTitle={model.section.FormPath} />}
      <Container>
        <div className="row mx-0" >
          <div className="col-12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 mt-2">
              {loading ? (
                <React.Fragment>
                  <div className="">
                    <div className="w-25 mx-auto my-1" style={{ paddingTop: "" }}>
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {/* <div className="col-auto"></div> */}
                  <div className="d-flex align-items-center my-1">
                    <i className={model.section.FormIcon + " me-3 fa-2x"}></i>
                    <div className="d-flex flex-column">
                      <h5 className={"my-1"}>{model.section.FormTitle}

                      </h5>
                      {/* <small>{model.section.FormPath}</small> */}
                    </div>
                  </div>
                </React.Fragment>
              )}
              <div className="w-auto d-flex align-items-center">

                {actionMode === null ? (
                  <>
                    <button className="btn btn-sm btn-primary my-1 me-2" title="Create" disabled={loading} onClick={() => toggleOpen("I")}>
                      <i className="fa fa-plus"></i>
                    </button>
                    {!isSearching && (
                      <button className={`btn btn-sm  my-1 me-2`} title="Search" onClick={() => setIsSearching(true)}>
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </button>
                    )}
                  </>
                ) : null}
                {selectedItem ? (
                  <>
                    <button className="btn btn-sm  my-1 me-2" title="Update" disabled={loading} onClick={() => toggleOpen("U")} aria>
                      <i className="fa fa-pencil"></i>
                    </button>
                    <button className="btn btn-sm  my-1 me-2" title="View" disabled={loading} onClick={() => toggleOpen("C")}>
                      <i className="fa fa-eye"></i>
                    </button>


                  </>
                ) : null}



                <Dropdown>
                  <Dropdown.Toggle variant="button" id="dropdown-basic">
                    <i className="fa fa-ellipsis-v" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {isPinned ? <Dropdown.Item onClick={() => { addFavorite() }}>Remover de favoritos</Dropdown.Item> : <Dropdown.Item onClick={() => { addFavorite() }}>Agregar a favoritos</Dropdown.Item>}
                    <Dropdown.Divider />
                    {selectedItem ?
                      <Dropdown.Item onClick={() => { toggleOpen('D') }}>Duplicar</Dropdown.Item> : null}


                    {actionMode === null ?
                      <Dropdown.Item onClick={() => { exportToExcel() }}>Export to Excel</Dropdown.Item> : null}
                    {actionMode === "C" ?
                      <Dropdown.Item
                        onClick={() => exportToPDF()}>Export to PDF</Dropdown.Item> : null}

                    <Dropdown.Divider />
                    {selectedItem ?
                      <Dropdown.Item onClick={() => deleteConfirm()}>Delete</Dropdown.Item> : null}
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="ms-3">
                  <button
                    className="btn btn-outline-danger  close-button my-1 me-2"
                    type="button"
                    title="Close Window"
                    onClick={() => { navigate("/dashboard") }}
                  >
                    <i className="fa-solid fa-x"></i>
                  </button>
                </div> */}
              </div>
            </div>

            {/* <div className="dx-card mb-4  px-3 py-2 titleContainer">
            
          </div> */}
            {loadGRIDUI()}
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}

import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Search,
  CleaningServices,
} from "@mui/icons-material";
import "devextreme/data/odata/store";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Card, Col, Form, Row } from "react-bootstrap";
import * as yup from "yup";
import { DataGrid, Pager, Paging, Selection } from "devextreme-react/data-grid";

import API from "../../api/api";
import { parsedUser } from "../../utils/GetCurrentUser";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TextBox } from "devextreme-react";
import FieldSelector from "../../components/customComponents/FieldSelector";
import { useFormik } from "formik";
import Sha256 from "../../utils/sha256";
import { db } from "../../utils/db";
import { useToast } from "../../contexts/toast";



import { v4 as uuidv4 } from "uuid"
import { utils as SheetUtils, writeFile as writeExcel } from "xlsx";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import yupTypeValidator from "../../utils/YupTypeValidator";
import { TabsGroup } from "../../components/tabs-group";
import JsonFormat from "../../components/json-format";

export default function CRUDTest() {
  // PROPERTIES
  const user = parsedUser();
  const params = useParams();
  const toast = useToast();
  const navigate = useNavigate();

  const [generalError, setGeneralError] = useState(null);
  const [open, setOpen] = useState(false);
  const [actionMode, setActionMode] = useState(null);//I=Insert, U= Update = C= Consultar, D= Delete
  const [loading, setLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const initialModel = {
    section: {},
    data: {
      Columns: [],
      Rows: [],
    },
  }
  const [model, setModel] = useState(initialModel);
  const [search, setSearch] = useState("");
  const allowedPageSizes = [10, 20, 50, 100, "all"];
  const [selectedItem, setSelectedItem] = useState(null);
  const selectedItemRef = useRef(null);
  // END PROPERTIES
  const jsonData = {
    "nombre": "Juan",
    "edad": 30,
    "ciudad": "Ejemplo"
  };
  const jsonString = JSON.stringify(jsonData, null, 2);
  //METHODS
  ///BRING THE DATA FROM THE SERVER FOR MODEL AND RECORDS
  const [storeProcedureResponse, setStoreProcedureResponse] = useState(null);
  const storeProcedureRemote = async () => {
    let query = `token=${user.Token}&sp=${storeProcedure}`;

    let request = await API.getAction("api/testsp/Records", query);
    setStoreProcedureResponse(request);
    setTabList((prev) => ([...prev, { eventKey: 'storeProcedures', content: <JsonFormat json={request} />, title: 'Store Procedures' }]))
  }
  const bindDataRemote = async (cleanFilter) => {

    // First start loading the model
    setLoading(true);

    //First load records

    try {
      //Store procedure object 


      let query = `token=${user.Token}&sp=${storeProcedure}`;
      if (search.length > 0 && cleanFilter == null) {
        query += "&Search=" + search;
      }
      if (idParent.length > 0) {
        query += "&IdParent=" + idParent;
      }
      let request = await API.getAction("api/testsp/Records", query);
      setRecordResponse(request);
      setTabList((prev) => ([...prev, { eventKey: 'recordResponse', content: <JsonFormat json={request} />, title: 'Record Response' }]))
      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];

      let recordResult = parsedResults.JSONData || [];
      // let configInfo = recordResult[0].JSONConfig ? recordResult[0].JSONConfig[0] : null;
      let dataFromAPI = recordResult[0] ? (recordResult[0].JSONConfig ? recordResult[0].JSONData : recordResult) : [];
      console.log("Results from record", recordResult);


      //Now let's load sections
      let querySections = `token=${user.Token}&sp=${storeProcedure}`;
      if (idParent.length > 0) {
        querySections += "&IdParent=" + idParent;
      }
      let requestSections = await API.getAction("api/testsp/Model", querySections);
      setModelResponse(requestSections);
      setTabList((prev) => ([...prev, { eventKey: 'modelResponse', content: <JsonFormat json={requestSections} />, title: 'Model' }]))
      let resultsSections = requestSections.data[0];
      let parsedResultsSections = JSON.parse(resultsSections.JSONData)[0];
      if (parsedResultsSections.Error) {
        alert(parsedResultsSections.Msg);
        return;
      }

      let recordResultSection = parsedResultsSections.JSONData;

      //TODO: | Use JSONConfig to show specific fields
      let gridData = null;
      let gridColumns = dataFromAPI && dataFromAPI.length > 0 ? Object.keys(dataFromAPI[0]).filter(x => !x.startsWith("Id")) : null;
      console.log("section data", recordResultSection)
      //! If grid data stays null, we use the data from the API
      setModel({
        ...model,
        section: recordResultSection[0],
        data: {
          ...model.data,
          Columns: gridColumns,
          Rows: gridData || dataFromAPI,
        },
      });

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const bindDataDetailsRemote = async (recordId) => {
    //First load records
    try {
      let query = `token=${user.Token}&RecordId=${recordId}&sp=${storeProcedure}`;
      if (idParent.length > 0) {
        query += "&IdParent=" + idParent;
      }
      let request = await API.getAction("api/testsp/Details", query);
      let results = request.data[0];

      let parsedResults = JSON.parse(results.JSONData)[0];
      if (parsedResults.Error) {
        alert(parsedResults.Msg);
        return;
      }
      let finalResult = parsedResults.JSONData[0];
      fillFormikObject(finalResult);
      setSelectedItem(null)
    } catch (error) {
      console.error(error)
    }
    //Fill object with new values
  };

  //Open edit/add mode
  const toggleOpen = async (action, reload) => {
    setActionMode(action);

    if (action === null) {
      setSelectedItem(null);
      formik.resetForm();
    }

    if (action === "I") {
      setSelectedItem(null)
    }
    if (action === "U" || action === "C" || action === "D") {
      await bindDataDetailsRemote(selectedItem.Id);
    }

    if (selectedItemRef.current) {
      selectedItemRef.current.clearSelection();
    }

    setGeneralError(null);
    setOpen(!open);
    if (reload) {
      bindDataRemote();
    }
  };

  //Delete the record
  const deleteConfirm = async (obj) => {
    confirmAlert({
      closeOnClickOutside: false,
      message: "Are you sure you want to delete this record?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {

            let query = `token=${user.Token}&RecordId=${selectedItem.Id}&sp=${storeProcedure}`;
            if (idParent.length > 0) {
              query += "&IdParent=" + idParent;
            }
            await API.getAction("api/testsp/Delete", query);
            await bindDataRemote();
            setSelectedItem(null);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  //Update the field value on formik according to what has been updated
  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };



  const [validationsSchema, setValidationSchema] = useState(null);

  const fillFormikObject = (record) => {
    console.log("record", record, "model", model.section);
    if (
      model.section &&
      model.section.Fields &&
      model.section.Fields.length > 0
    ) {
      model.section.Fields.forEach((item) => {
        console.log(
          "field name",
          item.FieldName,
          "RecordValue ",
          record[item.FieldName]
        );
        formik.setFieldValue(item.FieldName, record[item.FieldName]);
      });
    }

    //Now
  };

  const prepareFormikObject = () => {
    console.log("Preparing formik object", model.section)
    let initialObject = {};
    let initialObjectValidation = {};

    if (model.section.Fields) {
      model.section.Fields.forEach((item) => {
        initialObject[item.FieldName] = item.value;

        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }
    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };

  //This method is to save the record we are working on
  const handleSubmit = async (obj, event) => {
    console.log("❗ ~ file: CRUDSimple.js:259 ~ handleSubmit ~ obj:", obj)
    console.log({ fields: model.section.Fields })
    let actions = {
      "I": "I",
      "U": "U",
      "D": "I"
    };

    if (actionMode === "D") {
      const { GUID, Id, ...rest } = obj;
      obj = rest;
    }


    confirmAlert({
      closeOnClickOutside: false,
      message: "Are you sure you want to save this record?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            let objSPVersion = "";
            model.section.Fields.forEach((item) => {

              if (item.ReadOnly || item.HtmlType === "separator") return;

              if (item.HtmlType !== "separator") {
                objSPVersion +=
                  (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
              }
              if (
                item.FieldType === "int" ||
                item.FieldType === "bool" ||
                item.FieldType === "bit" ||
                item.FieldType === "bigint"
              ) {
                objSPVersion +=
                  obj[item.FieldName] === undefined || obj[item.FieldName] === null
                    ? item.DefaultValue && item.DefaultValue.length > 0 ? item.DefaultValue : "null"
                    : "'" + obj[item.FieldName] + "'";
                //objSPVersion +=
                //obj[item.FieldName] === undefined ? "null" : obj[item.FieldName];
              } else if (item.HtmlType === "password") {
                let passwordEncrypted = null;
                if (obj[item.FieldName] && obj[item.FieldName].length > 0) {
                  passwordEncrypted =
                    obj["UserName"].toUpperCase() + obj[item.FieldName];
                  passwordEncrypted = Sha256.hash(passwordEncrypted);
                  ;
                }
                objSPVersion +=
                  obj[item.FieldName] === undefined
                    ? "null"
                    : "'" + passwordEncrypted + "'"
              } else
                if (item.HtmlType === 'radio') {

                  objSPVersion +=
                    obj[item.FieldName] === undefined || obj[item.FieldName] === null
                      ? item.DefaultValue && item.DefaultValue.length > 0 ? item.DefaultValue : "null"
                      : "'" + obj[item.FieldName] + "'";
                } else {
                  objSPVersion +=
                    obj[item.FieldName] === undefined || obj[item.FieldName] === null
                      ? "null"
                      : "'" + obj[item.FieldName] + "'";
                }
            });
            let queryString = `Token=${user.Token}&ActionMode=${actions[actionMode]}&sp=${storeProcedure}`;
            let queryData = {
              Data: objSPVersion,
            };

            let request = await API.postAction(
              "api/testsp/AddEdit?" + queryString,
              queryData
            );

            if (request.status === 200) {
              let response = JSON.parse(request.data[0].JSONData)[0];
              if (response.Error) {
                setGeneralError(response.Msg);
                return;
              }
            }
            setGeneralError(null);
            setSelectedItem(null);

            formik.resetForm();

            toast({
              type: "success",
              "message": "Record saved successfully",
            })
            toggleOpen(null, true);
          },
        },
        {
          label: "No",
        },
      ],
    });


  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,

    validateOnChange: false,
    validateOnBlur: false,

    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });


  const exportToExcel = useCallback(async () => {

    const worksheet = SheetUtils.json_to_sheet(model.data.Rows);

    const workbook = SheetUtils.book_new();

    SheetUtils.book_append_sheet(workbook, worksheet, "Sheet1");

    writeExcel(workbook, `${model.section.FormTitle}.xlsx`);
  }, [model.data.Rows])

  const exportToPDF = useCallback(async () => {

    const container = document.getElementById("contentContainer");

    const containerHeight = container.clientHeight
    const containerWidth = container.clientWidth
    const ratio = containerHeight / containerWidth;

    html2canvas(container).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg");
      const pdf = new jsPDF("l", "mm", "a0");

      const width = pdf.internal.pageSize.getWidth();
      let height = pdf.internal.pageSize.getHeight();
      height = ratio * width;

      pdf.addImage(imgData, "JPEG", 0, 0, width, height);
      pdf.save(`${model.section.FormTitle}.pdf`);
      pdf.close()
    })
  }, [])

  // END METHODS

  // UI METHODS
  const loadFormUI = () => {
    return (
      <div className="card">
        <Form autoComplete="off" onSubmit={formik.handleSubmit}>
          <div id="form-body" className="card-body p-4 formBackground">
            <Row>
              {/* CONTENT */}
              {model.section &&
                model.section.Fields &&
                model.section.Fields.map((item, index) => {
                  return (
                    <Col
                      className={
                        item.cssClasss + (!item.Visible ? " d-none" : "")
                      }
                      key={index}
                    >
                      <label className={item.HtmlType === 'separator' ? `me-2 ${(index !== 0 ? "mt-4 pt-4" : "mt-2")}  separatorTitle` : "me-2 mt-2"}>{item.FieldTitle}
                        {item.Required ? <i className="required-asterisk ms-1 text-danger">*</i> : null}
                      </label>
                      <br />

                      <FieldSelector
                        actionMode={actionMode}
                        model={item}
                        key={index}
                        updateField={updateField}
                        value={formik.values[item.FieldName]}
                      ></FieldSelector>
                      {formik.errors[item.FieldName] ? (
                        <div className="invalid text-sm">
                          {formik.errors[item.FieldName]}
                        </div>
                      ) : null}
                    </Col>
                  );
                })}
            </Row>
          </div>
          <div
            id="submit-container"
            className="card-footer"
            data-html2canvas-ignore="true"
          >
            {generalError ? (
              <Row>
                <Col xs={12}>
                  <div className="alert alert-danger" role="alert">
                    {generalError}
                  </div>
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col xs={12} className="text-end ">
                {Object.values(formik.errors).length > 0 ? (
                  <span className="invalid me-2">
                    Please check the forms for errors
                  </span>
                ) : null}

                {actionMode !== "C" && (
                  <button id="submit-button" className="btn btn-primary me-2" type="submit" disabled={actionMode === "C"}>
                    Save
                  </button>
                )}

                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={() => {

                    confirmAlert({
                      closeOnClickOutside: false,
                      message: "Are you sure you want to close this screen?",
                      buttons: [
                        {
                          label: "Yes",
                          onClick: () => {
                            toggleOpen(null)
                          },
                        },
                        {
                          label: "No",
                        },
                      ],
                    });
                  }
                  }
                >
                  Close
                </button>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    );
  };

  const loadGRIDUI = () => {
    return (
      <>
        {isSearching && (
          <Card className="mb-4">
            <Card.Header className="w-full d-flex justify-content-between align-items-center">
              <Card.Title>Filters</Card.Title>

              <button
                className="btn btn-secondary"
                type="button"
                onClick={() => { setIsSearching(false) }}
                style={{
                  "padding": "0px 8px",
                  "lineHeight": "23px"
                }}
              >
                <i style={{ fontSize: "10px" }} className="fa-solid fa-x"></i>
              </button>
            </Card.Header>
            <Form
              onSubmit={async (e) => {
                e.preventDefault();
                await bindDataRemote();
              }}
            >
              <Card.Body className="formBackground">
                <Row>
                  <Col className={"col-12"}>
                    <TextBox
                      // className="form-control "
                      id={"search"}
                      mode={"text"}
                      value={search}
                      placeholder="search"
                      onValueChanged={(e) => {
                        setSearch(e.value);
                      }}
                    />
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-end">
                <button
                  className="btn btn-sm btn-secondary me-2"
                  type="button"
                  onClick={() => {
                    setSearch("");
                    setTimeout(async () => {
                      await bindDataRemote(true);
                    }, 1000);
                  }}
                >
                  <CleaningServices />
                </button>
                <button className="btn btn-sm btn-primary" type="submit">
                  <Search />
                </button>
              </Card.Footer>
            </Form>
          </Card>
        )
        }
        <Card>

          <Card.Body>
            <DataGrid
              dataSource={model.data.Rows}
              columns={model.data.Columns}
              columnAutoWidth={true}
              showBorders={true}
              onSelectionChanged={selectItem}
              loadPanel={{
                enabled: true,
                showIndicator: true,
                text: "Loading...",
              }}
            >
              <Selection mode="single" />
              <Paging
                defaultPageSize={10}
              />
              <Pager
                showInfo={true}
                infoText={"Page:"}
                visible={true}
                showPageSizeSelector={true}
                allowedPageSizes={allowedPageSizes}
              />
            </DataGrid>
          </Card.Body>
        </Card>
      </>
    );
  };

  //EVENTS
  const selectItem = useCallback((e) => {
    selectedItemRef.current = e.component;
    e.component.byKey(e.currentSelectedRowKeys[0]).done((item) => {
      setSelectedItem(item);
    });
  }, []);

  useEffect(() => {
    setOpen(false)
    setSelectedItem(null)
    setActionMode(null)
    setGeneralError(null)
    setSearch("");
    setModel(initialModel);

    return () => formik.resetForm();
  }, [params.id]);

  useEffect(() => {
    console.log("section updated", model)
    prepareFormikObject();
  }, [model.section, model.section.Fields]);
  // END EVENTS

  const [storeProcedure, setStoreProcedure] = useState('');
  const [idParent, setIdParent] = useState('');
  const [modelResponse, setModelResponse] = useState(null)
  const [recordResponse, setRecordResponse] = useState(null)
  const [tabList, setTabList] = useState([])

  const resetResponse = () => {
    setTabList([])
    setModelResponse(null)
    setRecordResponse(null)
    setStoreProcedureResponse(null)
  }

  const tryOut = () => {
    bindDataRemote();
    storeProcedureRemote();
  }

  return (
    <React.Fragment>
      <div className="row mx-0" id="contentContainer">
        <Col xs={12} className="mt-4">
          <Card>
            <Card.Header>
              <h2>Store Procedure</h2>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xl={8}>
                  <label>Store Procedure name</label>
                  <input type="text" name="storeProcedure" className="form-control" value={storeProcedure} onChange={e => setStoreProcedure(e.target.value)} />
                </Col>
                <Col xl={4}>
                  <label>Id Parent (If needed)</label>
                  <div className="d-flex gap-4">
                    <input type="text" name="idParent" className="form-control w-100" value={idParent} onChange={e => setIdParent(e.target.value)} />
                    <button className="btn btn-secondary" onClick={() => { setIdParent(uuidv4()) }}>Generate</button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="d-flex gap-2">
              <button className="btn btn-primary" type="button" onClick={tryOut} title='Call store procedure'>Try out</button>
              <button className="btn btn-outline-secondary" type="button" onClick={resetResponse} title='Clear response'>Clear</button>
            </Card.Footer>
          </Card>
        </Col>
        <Col className="mt-4">
          {tabList.length > 0 && <TabsGroup tabList={tabList} />}
        </Col>
        <div className="col-12">
          <div className="dx-card my-4 px-3 py-2 titleContainer">
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              {loading ? (
                <React.Fragment>
                  <div className="">
                    <div className="w-25 mx-auto my-1" style={{ paddingTop: "" }}>
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {/* <div className="col-auto"></div> */}
                  <div className="d-flex align-items-center my-1">
                    <i className={model.section.FormIcon + " me-3 fa-2x"}></i>
                    <div className="d-flex flex-column">
                      <h5 className={"my-1"}>{model.section.FormTitle}</h5>
                      <small>{model.section.FormPath}</small>
                    </div>
                  </div>
                </React.Fragment>
              )}
              <div className="w-auto d-flex align-items-center">

                {actionMode === null ? (
                  <>
                    <button className="btn btn-primary my-1 me-2" title="Create" disabled={loading} onClick={() => toggleOpen("I")}>
                      <i className="fa fa-plus"></i>
                    </button>
                    {!isSearching && (
                      <button className={`btn btn-primary my-1 me-2`} title="Search" onClick={() => setIsSearching(true)}>
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </button>
                    )}
                  </>
                ) : null}
                {selectedItem ? (
                  <>
                    <button className="btn btn-primary my-1 me-2" title="View" disabled={loading} onClick={() => toggleOpen("C")}>
                      <i className="fa fa-eye"></i>
                    </button>
                    <button id="edit-button" className="btn btn-primary my-1 me-2" title="Edit" disabled={loading} onClick={() => toggleOpen("U")}>
                      <i className="fa fa-pencil"></i>
                    </button>
                    <button className="btn btn-primary my-1 me-2" title="Duplicate" disabled={loading} onClick={() => toggleOpen("D")}>
                      <i className="fa-solid fa-copy"></i>
                    </button>
                    <button
                      id="delete-button"
                      className="btn btn-secondary my-1 me-2"
                      type="button"
                      disabled={loading}
                      title="Delete"
                      onClick={() => deleteConfirm()}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </>
                ) : null}
                {actionMode === "C" && (
                  <button
                    className="btn btn-blue-overline my-1 me-2"
                    title="Export to PDF"
                    disabled={loading}
                    onClick={() => exportToPDF()}
                    data-html2canvas-ignore="true"
                  >
                    <i className="fa-solid fa-file-pdf"></i>
                  </button>
                )}
                {actionMode === null && (
                  <button
                    className="btn btn-blue-overline my-1 me-2"
                    type="button"
                    disabled={loading}
                    title="Export to Excel"
                    onClick={() => exportToExcel()}
                  >
                    <i className="fa-solid fa-file-excel"></i>
                  </button>
                )}
                <div className="ms-3">
                  <button
                    className="btn close-button my-1 me-2"
                    type="button"
                    title="Close Window"
                    onClick={() => { navigate("/dashboard") }}
                  >
                    <i className="fa-solid fa-x"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {open ? loadFormUI() : loadGRIDUI()}
        </div>
      </div>
    </React.Fragment >
  );
}

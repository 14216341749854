import { useLiveQuery } from "dexie-react-hooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { parsedUser } from "../../utils/GetCurrentUser";
import { db } from "../../utils/db";
import "./FavoritesBar.scss";

export default function FavoritesBar() {

    const navigate = useNavigate();

    const user = parsedUser();
    const favoritesDB = useLiveQuery(() => db.favorites.where("username").equals(user.Username).first());

    const [favoritesList, setFavoritesList] = useState([]);

    useEffect(() => {
        if (!favoritesDB) return;
        setFavoritesList(favoritesDB.values);
    }, [favoritesDB])

    return (
        <div className="favorites-container">
            {favoritesList && favoritesList.map((item) => (
                <button
                    key={`${item.title}-${item.formPath}`}
                    className="favorites-item btn mt-2"
                    title={item.formPath}
                    onClick={() => navigate(item.path)}
                >
                    <i className={`${item.icon}`}></i>
                </button>
            ))}
        </div>
    )
}


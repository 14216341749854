import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';

const ContractPolicySel = ({ optionsList, optionSelected, children }) => {

  const [selected, setSelected] = useState({});
  const [options, setOptions] = useState([]);
  const [contractType, setContractType] = useState(1);

  useEffect(() => {
    const selection = options.find((item) => item.id === Number(selected));
    optionSelected(selection);
  }, [selected]);

  useEffect(() => {
    if (optionsList) {
      setOptions(optionsList);
      // setOptions(optionsList.filter((item) => item.id === contractType));
    }
  }, [optionsList, contractType])

  return (
    <div className='d-flex flex-column w-100'>
      <div className='mb-2'>
        <label htmlFor='' className='me-4 fw-bold '>Contract Policy Type:</label>
        <Form.Check
          inline
          label="Policy Type 1"
          name="group1"
          type='radio'
          id={`inline-radio-1`}
          checked={contractType === 1}
          onChange={() => setContractType(1)}
        />
        <Form.Check
          inline
          label="Policy Type 2"
          name="group1"
          type='radio'
          id={`inline-radio-2`}
          checked={contractType === 2}
          onChange={() => setContractType(2)}
        />
        <Form.Check
          inline
          label="Policy Type 3"
          name="group1"
          type='radio'
          id={`inline-radio-3`}
          checked={contractType === 3}
          onChange={() => setContractType(3)}
        />
      </div>
      <div className='d-flex gap-2 align-items-start '>
        <select
          className='form-control'
          value={selected}
          onChange={(e) => setSelected(e.target.value)}
        >
          <option value={''}>-Assign Contract Policy-</option>
          {options.map((item) => (
            <option key={item.id} value={item.id}>{item.text}</option>
          ))}
        </select>
        {children}
      </div>
    </div>

  )
}

export default ContractPolicySel

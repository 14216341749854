import React from 'react';
import { Card, Col, Container, Modal, Row, Spinner } from 'react-bootstrap';
import API from '../../api/api';
import ModelTable from '../../components/modelManagement/ModelTable';
import ListGroup from '../../components/modelManagement/list';
import { parsedUser } from '../../utils/GetCurrentUser';
import ModalModel from '../../components/modelManagement/ModelModal';


const ModelManagement = () => {
    const user = parsedUser();

    const [originalList, setOriginalList] = React.useState([]);
    const [filterList, setFilterList] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [ManagementModel, setManagementModel] = React.useState([{}])
    const [select, setSelect] = React.useState({ isActive: false, name: '', id: '' })


    const getManagementList = () => {
        try {
            let request = API.getAction(`api/CRUDModelManagement/List?Token=${user.Token}`, null, null);

            request.then(result => {
                const data = result.data ?? [];
                setLoading(false);
                setFilterList(data);
                setOriginalList(data);

            }).catch(e => { console.log(e) })
        }
        catch (e) { console.log(e) }
    }

    const getManagementModel = () => {
        try {
            let request = API.getAction(`api/CRUDModelManagement/Model?Token=${user.Token}&id=${select.id}`, null, null);
            request.then(result => {
                const data = result.data ?? [];
                setManagementModel(data);
            }).catch(e => { console.log(e) })
        }
        catch (e) { console.log(e) }
    }

    const filterName = (e) => {
        const value = e.target.value.toLowerCase();
        const filter = value ? originalList.filter(item => item.Description.toLowerCase().includes(value)) : originalList;
        setFilterList(filter);
    };

    React.useEffect(() => { getManagementList() }, [])

    React.useEffect(() => {
        if (!select.id) return
        getManagementModel()
    }, [select.id])

    return (
        <div className='m-5'>
            <Row>
                <Col md={select.isActive ? 3 : 12} xs={12}>
                    <Card>
                        <Card.Body >
                            <Card.Title>Model Management List</Card.Title>
                            <input type="text" className="form-control my-2 p-2" onChange={filterName} placeholder="Search" />
                            <Container style={{ height: '60vh', overflow: 'scroll', overflowX: 'hidden' }}>
                                {loading ?
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <Spinner animation="border" variant="primary" />
                                    </div>
                                    : <ListGroup data={filterList} select={select} setSelect={setSelect} />
                                }
                            </Container>
                        </Card.Body>
                    </Card>
                </Col>
                {select.isActive &&
                    <Col md={9} xs={12}>
                        <ModelTable ManagementModel={ManagementModel} name={select.name} requestData={getManagementModel} selected={select} />
                    </Col>
                }

            </Row>
        </div >
    )
}
export default ModelManagement 
import React, { useEffect } from "react";


const ErrorPageComponent = ({ error, resetErrorBoundary }) => {
 
  useEffect(() => {
    console.log("THE ERROR ====", error);


  }, []);

  return (
    <div role="alert" className="col-6 mx-auto ">
      <div className="card shadow-sm my-4">
        <div className="card-body">
          <p>Something went wrong:</p>
          <pre className="text-danger">{error.message}</pre>


          <div className="row">
            <div className="col-12 text-center mb-4">
            
           
              <button
                onClick={() => {
                  window.location.reload();
                }}
                className="btn btn-primary my-2"
              >
                Reload
              </button>
            </div>
            <div className="col-12 text-center">
              If the problem persist, try to   <button
                onClick={() => {
                  window.location = "/cleanup";
                }}
                className="btn btn-secondary btn-sm"
              >
                Logout
              </button> 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPageComponent;

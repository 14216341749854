import React, { useEffect, useState } from 'react'
import { Form, Row, Card } from 'react-bootstrap'
import * as yup from 'yup'
import { actionsModel } from '../../../enum/actionsModel.enum'
import { TabsGroup } from '../../tabs-group'
import RatePeriodContractTab from '../RatePeriodTab/RatePeriodTab'
import FieldSelector from '../../customComponents/FieldSelector'
import ContractFields from '../ContractFieldsV2'
import { useFormik } from 'formik'
import { parsedUser } from '../../../utils/GetCurrentUser'
import API from '../../../api/api'
import ContractPoliciesTab from '../ContractPoliciesTab/ContractPoliciesTab'
import CommentHistoryTab from '../CommentHistoryTab'
import { useToast } from '../../../contexts/toast'
import { v4 as uuidv4 } from "uuid";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css


const ContractForm = (props) => {
  const user = parsedUser()
  const toast = useToast();
  const [validationsSchema, setValidationSchema] = useState(null);
  const [fields, setFields] = useState(ContractFields);
  const [recordId, setRecordId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [headers, setHeaders] = useState([]);
  const [policies, setPolicies] = useState([]); // Contract Policy Tab

  const [record, setRecord] = useState({})
  // useEffect(() => {
  //   if (data) {
  //     //  bindDataModelRemote()
  //     console.log("🚀🚀🚀", data.GUID);
  //     setRecordId(data.GUID);
  //   }
  // }, [data]);


  useEffect(() => {
    prepareFormikObject();
    if (props.actionMode === actionsModel.EDIT) {
      fillFormikObject(props.data);
    }
  }, [props.data]);

  // Prepare Formik Object
  const prepareFormikObject = () => {
    console.log("Preparing formik object", fields)
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        initialObject[item.FieldName] = item.value;

        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }
    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
  };

  const fillFormikObject = (record) => {
    // console.log("record", record, "model", fields);
    if (
      fields &&
      fields.length > 0
    ) {
      fields.forEach((item) => {
        console.log(
          "field name",
          item.FieldName,
          "RecordValue ",
          record[item.FieldName]
        );
        formik.setFieldValue(item.FieldName, record[item.FieldName]);
      });
    }
  };

  // Yup Type Validator
  const yupTypeValidator = (item) => {
    const { FieldType, FieldTitle } = item;
    if (FieldType === "string" || FieldType === "varchar") {
      return yup.string().label(FieldTitle).required();
    } else if (FieldType === "bool" || FieldType === "bit") {
      return yup.boolean().label(FieldTitle).required();
    } else if (FieldType === "int" || FieldType === "bigint") {
      return yup.number().label(FieldTitle).required();
    } else if (FieldType === "date") {
      return yup.date().label(FieldTitle).required();
    }
  };

  // SUBMIT FORM
  const handleSubmit = (values) => {
    console.log(values, record, props)
    let recordTouch = record;
    if (props.actionMode === 'I') {
      recordTouch.GUID = uuidv4();

    }

    recordTouch = {
      ...recordTouch, IdCustomer: values.IdCustomer, StartDate: values.StartDate,
      EndDate: values.EndDate
    }

    console.log("object to be sent", recordTouch);
    confirmAlert({
      closeOnClickOutside: false,
      message: "Are you sure you want to save this record?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {

            let queryString = `Id=${recordTouch.GUID}&Token=${user.Token}`;
            let queryData = recordTouch


            let request = await API.postAction(
              `api/ContractsManager/ContractsV2AddEdit?${queryString}`,
              queryData
            );

            if (request.status === 200) {

              let response = JSON.parse(request.data[0].JSONData);
              if (response.Error) {
                alert(response.Msg);
                return;
              }
            }


            formik.resetForm();

            toast({
              type: "success",
              "message": "Record saved successfully",
            })
            handleClose();
          },
        },
        {
          label: "No",
        },
      ],
    });


  }

  // CLOSE FORM
  const handleClose = () => {
    props.clearSelection(null);
    props.setShow(false);
  }

  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,

    validateOnChange: false,
    validateOnBlur: false,

    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  const UpdateRatePeriod = obj => {
    setRecord({ ...record, BusinessUnits: obj })
  }

  const UpdateHistory = obj => {
    setRecord({ ...record, Comment: obj })
  }

  const UpdatePolicies = obj => {
    setRecord({ ...record, Policies: obj })
  }

  return (
    <div className=''>
      {/* <div>
        <h2>{action === actionsModel.EDIT ? 'Update' : 'Create'} Contract </h2>
      </div> */}

      <Form onSubmit={formik.handleSubmit}>
        <Card className='mb-4'
        >
          <Card.Body className='p-2 px-4'>
            <Row>
              {ContractFields.map((item, index) => (
                <div key={index} className={`${item.cssClasss} mb-4`}>
                  <label className={item.HtmlType === 'separator' ? `me-2 ${(index !== 0 ? "mt-4 pt-4" : "mt-2")}  separatorTitle` : "me-2 mt-3 "}>{item.FieldTitle}
                    {item.Required ? <i className="required-asterisk ms-1 text-danger">*</i> : null}
                  </label>
                  <br />
                  <FieldSelector
                    model={item}
                    updateField={updateField}
                    data={props.data}
                    action={props.action}
                    value={formik.values[item.FieldName] !== undefined ? formik.values[item.FieldName] : item.value}
                  />
                  {formik.errors[item.FieldName] && <div className="invalid text-sm">{formik.errors[item.FieldName]}</div>}
                </div>
              ))}
            </Row>
          </Card.Body>
        </Card>
        <div className='mb-4'>
          <TabsGroup
            tabList={[
              {
                title: 'Rate Period',
                eventKey: 'ratePeriod',
                content: <RatePeriodContractTab update={UpdateRatePeriod} />
              },
              {
                title: 'Contract Policies',
                eventKey: 'contractPolicies',
                content: <ContractPoliciesTab update={UpdatePolicies} />
              },
              {
                title: 'Comment History',
                eventKey: 'contractNotes',
                content: <CommentHistoryTab update={UpdateHistory} />
              },
            ]} />
        </div>
        <Card>
          <Card.Body className='p-2'>
            <Row className=''>
              <div className="d-flex gx-3 align-items-center justify-content-end">
                <button className="btn me-2" type={'button'} onClick={handleClose}>Cancel</button>
                <button className="btn btn-primary me-2" type={'submit'}>Save</button>
              </div>
            </Row>
          </Card.Body>
        </Card>
      </Form>
    </div>)
}

export default ContractForm
import React from "react";
import { Card, Col, Row } from "react-bootstrap";

const RoomDetails = (props) => {
  return (
    <Card className="mb-4">
      <Card.Header>
        <Card.Title>Room Details</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <label className="me-2 mt-2">Guest (s)</label>
            <br />
            <ol>
              <li>
                <b>Smith, John</b>
              </li>
              <li>
                <b>Smith, Lily</b>
              </li>
            </ol>
          </Col>
          <Col>
            <label className="me-2 mt-2">Resort</label>
            <br />
            <span className="">
              <b>Cofresi Palm & Spa Beach Resort</b>
            </span>
            <br />
            <label className="me-2 mt-2">Membership</label>
            <br />
            <span className="">
              <b>Standard</b>
            </span>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default RoomDetails;

import React, { useEffect, useState } from 'react'
import ColumnsModelsSettings from '../settings/model.settings'
import Table from '../table'
import ModalModel from './ModelModal';
import { confirmAlert } from 'react-confirm-alert';
import { actionsModel } from '../../enum/actionsModel.enum';
import ModalSample from './ModalSample';

const ModelTable = ({ ManagementModel, name, requestData, selected }) => {

    const [showModal, setShowModal] = React.useState(false);
    const [data, setData] = React.useState({})
    const [action, setAction] = React.useState('')
    const [showSample, setshowSample] = useState(false);
    const toggleSample = () => {
        setshowSample(!showSample);
    }

    const actionSelected = (action, data) => {

        setAction(action)
        setData(data)

        setShowModal(action === actionsModel.EDIT || action === actionsModel.CREATE);

        if (action === actionsModel.DELETE) {
            confirmAlert({
                message: 'Are you sure to delete this item?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => console.log('Click Yes')
                    },
                    {
                        label: 'No',
                        onClick: () => console.log('Click No')
                    }
                ]
            });
        }
    }
    useEffect(() => {
        console.log(showModal)
        if (!showModal) {

            requestData();
        }
    }, [showModal])

    useEffect(() => {
        console.log("update table", ManagementModel)
    }, [ManagementModel])
    return (
        <>
            <ColumnsModelsSettings actionSelected={actionSelected} >
                {({ columns }) => (
                    <Table
                        headerTop={
                            <div className="d-flex justify-content-between ">
                                <h4 className="font-bold">Model - {name}</h4>
                                <div className="flex items-center">
                                    {/* <button className="btn btn-secondary me-2" onClick={() => {
                                        console.log(selected);
                                        debugger;
                                    }
                                    }>
                                        <i className="fas fa-link"></i>
                                    </button> */}
                                    <button className="btn btn-secondary me-2" onClick={() => toggleSample()}>
                                        <i className="fas fa-eye"></i>
                                    </button>
                                    <button className="btn btn-primary " onClick={() => actionSelected(actionsModel.CREATE, {})}>
                                        <i className="fas fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                        }
                        Empty={() => <p style={{ fontSize: '1.5rem' }}>no data</p>}
                        keyName="model-table"
                        title="model table"
                        header={{ columns }}
                        data={ManagementModel}
                        stylesCustom={{ containerOverflow: "max-h-96 overflow-y-auto" }}
                    />
                )}
            </ColumnsModelsSettings >
            <ModalModel show={showModal} setShow={setShowModal} data={data} action={action} />
            {
                showSample ?
                    <ModalSample show={showSample} toggle={toggleSample} data={ManagementModel}></ModalSample>
                    : null
            }

        </>
    )
}

export default ModelTable
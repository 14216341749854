const maintenanceAdminClientsData = {
  title: "CLIENT",
  type: "form",
  editable: true,
  records: {
    fieldsTitles: [],

    fieldsRows: [],
  },
  fields: [
    {
      title: "ID",
      value: "",
      htmlType: "text",
      required: true,
      cssClass: "col-12 col-md-4",
      placeholder: "john doe",
      name: "UserName",
    },
    {
      title: "Description",
      value: "",
      htmlType: "text",
      required: true,
      cssClass: "col-12 col-md-4",
      placeholder: "jdoe",
      name: "lastName",
    },

    {
      title: "Client Type",
      value: "",
      htmlType: "radio",
      required: false,
      cssClass: "col-12 col-md-4 ",
      placeholder: "",
      name: "blocked",
      defaultValues: [
        { id: false, text: "Desktop" },
        { id: true, text: "Mobile" },
      ], //"false;NO|true;YES",
    },
    {
      title: "Platform",
      value: "",
      htmlType: "text",
      required: false,
      cssClass: "col-12 col-md-4 ",
      placeholder: "",
      name: "blocked",
      defaultValues: [
        { id: false, text: "NO" },
        { id: true, text: "YES" },
      ], //"false;NO|true;YES",
    },
    {
      title: "Sys User",
      value: "",
      htmlType: "select",
      required: false,
      cssClass: "col-12 col-md-4",
      placeholder: "",
      name: "gender",
    },
    {
      title: "Browser",
      value: "",
      htmlType: "text",
      required: false,
      cssClass: "col-12 col-md-4",
      placeholder: "",
      name: "gender",
    },
    {
      title: "Browser Version",
      value: "",
      htmlType: "text",
      required: false,
      cssClass: "col-12 col-md-4",
      placeholder: "",
      name: "gender",
    },
    {
      title: "Locked Access",
      value: "",
      htmlType: "radio",
      required: false,
      cssClass: "col-12 col-md-4 ",
      placeholder: "",
      name: "blocked",
      defaultValues: [
        { id: false, text: "NO" },
        { id: true, text: "YES" },
      ], //"false;NO|true;YES",
    },
    {
      title: "User",
      value: "",
      htmlType: "select",
      required: false,
      cssClass: "col-12 col-md-4",
      placeholder: "",
      name: "gender",
    },
    {
      title: "Organization Unit",
      value: "",
      htmlType: "select",
      required: false,
      cssClass: "col-12 col-md-4",
      placeholder: "",
      name: "gender",
    },
    {
      title: "Department",
      value: "",
      htmlType: "select",
      required: false,
      cssClass: "col-12 col-md-4",
      placeholder: "",
      name: "gender",
    },

    {
      title: "Active",
      value: "",
      htmlType: "radio",
      required: false,
      cssClass: "col-12 col-md-4 ",
      placeholder: "",
      name: "blocked",
      defaultValues: [
        { id: false, text: "NO" },
        { id: true, text: "YES" },
      ], //"false;NO|true;YES",
    },
    {
      title: "Comments",
      value: "",
      htmlType: "textarea",
      required: false,
      cssClass: "col-12 ",
      placeholder: "",
      name: "comments",
    },
  ],
};

export default maintenanceAdminClientsData;

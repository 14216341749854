import React, { useEffect, useState } from "react";

import SPECIALRATEFIELDS from "./RatePeriodSpecialRateFields";
import { Col, Form, Row } from "react-bootstrap";
import FieldSelector from "../../customComponents/FieldSelector";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import yupTypeValidator from "../../../utils/YupTypeValidator";
import * as yup from "yup";

const RatePeriodSpecialRate = props => {
	const model = SPECIALRATEFIELDS;
	const [records, setRecords] = useState(props.records ?? []);
	const [record, setRecord] = useState(null);
	const [showForm, setShowForm] = useState(false);
	const [actionMode, setActionMode] = useState(null);

	const [validationsSchema, setValidationSchema] = useState(null);


	const toggle = mode => {
		setActionMode(mode);
		if (mode === 'I') {
			setRecord(null);
			formik.resetForm();
		}
		if (mode === 'U') {
			fillFormikObject(record);
		}
		setShowForm(!showForm)
	}

	const selectItem = (obj) => {
		setRecord(obj);
	}

	const clearSelection = () => {
		setRecord(null);
	}

	const updateField = (fieldName, fieldValue) => {
		formik.setFieldValue(fieldName, fieldValue);
	};


	//Delete the record
	const deleteConfirm = async (obj) => {
		confirmAlert({
			message: "Are you sure you want to delete this record?",
			buttons: [
				{
					label: "Yes",
					onClick: async () => {

						let items = records;
						items.filter(x => x === obj)[0].Delete = true;
						console.log(items);
						setRecords(items);
						setRecord(null);
					},
				},
				{
					label: "No",
				},
			],
		});
	};

	const handleSubmit = (obj) => {
		formik.validateForm();
		if (!formik.isValid) {

			return;
		}
		console.log(obj)
		obj.Delete = false;//This is just to include 
		obj.ActionMode = actionMode;
		if (actionMode === 'I') {
			obj.GUID = uuidv4();
			setRecords([...records, obj])
		} else if (actionMode === 'U') {
			const copy = records.map(x => {
				if(x.GUID === obj.GUID) {
					return obj
				}
				return x
			})
			setRecords(copy);
			clearSelection();
		}
		//Assemble the object 

		toggle(null);
		formik.resetForm();
	}
	const formik = useFormik({
		initialValues: {},
		enableReinitialize: true,

		validateOnChange: false,
		validateOnBlur: false,

		validationSchema: validationsSchema,
		onSubmit: handleSubmit,
	});


	const fillFormikObject = (record) => {
		console.log("record", record, "model", model.section);

		model.forEach((item) => {
			console.log(
				"field name",
				item.FieldName,
				"RecordValue ",
				record[item.FieldName]
			);
			formik.setFieldValue(item.FieldName, record[item.FieldName]);
		});
		//Now
	};
	const prepareFormikObject = () => {
		console.log("Preparing formik object", model)
		let initialObject = {};
		let initialObjectValidation = {};


		if (model) {
			model.forEach((item) => {

				if (item.FieldName === "Id" || item.FieldName === "File") return;

				initialObject[item.FieldName] = item.value;

				//TODO: REMOVE THIS CONDITION!!!!!!
				if (item.Required) {
					initialObjectValidation[item.FieldName] = yupTypeValidator(item);
				}
			});
		}

		formik.initialValues = initialObject;

		setValidationSchema(yup.object(initialObjectValidation));
		//Now
	};

	useEffect(() => {
		props.assignSpecialRate(records);
	}, [records])

	useEffect(() => {
		prepareFormikObject();
		console.log(props.ratePeriod);
		if (props.ratePeriod && props.ratePeriod.SpecialRate) {
			console.log(props.ratePeriod.SpecialRate);
			setRecords(props.ratePeriod.SpecialRate);
		}
	}, []);

	useEffect(() => {
		//	setRecords(props.ratePeriod.SpecialRate)
		console.log(records)
	}, [props.records])

	const formUI = () => {
		return (

			<Row>


				{/* CONTENT */}
				{model.map((item, index) => {
					return (
						<Col
							className={
								item.cssClasss + (!item.Visible ? " d-none" : "")
							}
							key={index}
						>
							<label className={item.HtmlType === 'separator' ? `me-2 ${(index !== 0 ? "mt-4 pt-4" : "mt-2")}  separatorTitle` : "me-2 mt-3 "}>{item.FieldTitle}
								{item.Required ? <i className="required-asterisk ms-1 text-danger">*</i> : null}
							</label>
							<br />
							{
								// console.log("item", item, "formik", formik.values[item.FieldName])
							}
							<FieldSelector
								//   actionMode={actionMode}
								model={item}
								key={index}
								// limit longitudes
								updateField={updateField}
								value={formik.values[item.FieldName]}
							></FieldSelector>
							{formik.errors[item.FieldName] ? (
								<div className="invalid text-sm">
									{formik.errors[item.FieldName]}
								</div>
							) : null}
						</Col>
					);
				})}
				<Col xs={12} className="my-2 text-end">
					<hr />
					<button className="btn ms-2" type="button" onClick={() => toggle(null)}>
						Cancel
					</button>
					<button className="btn btn-secondary ms-2" type="button" onClick={formik.handleSubmit}>
						Save
					</button>
				</Col>

			</Row>
		)
	}
	const gridUI = () => {
		return (<Row>
			<Col>
				<table className="table table-striped table-bordered">
					<thead>
						<tr>
							<th>Room Type</th>
							<th>Date Start</th>
							<th>Date End</th>
						</tr>
					</thead>
					<tbody>
						{records && records.filter(x => !x.Delete).map((item, index) => {
							console.log(item)
							return (<tr key={index} className={`${record && item === record ? "active" : ""}`}
								onClick={() => {
									selectItem(item)
								}}>
								<td>{item.IdRoomType}</td>
								<td>{item.DateStart}</td>
								<td>{item.DateEnd}</td>
							</tr>)
						})}
						{records && records.filter(x => !x.Delete).length === 0 ?
							<tr>
								<td colSpan={4} className="text-center p-4">
									No Data
								</td>
							</tr>
							: null}
					</tbody>
				</table>
			</Col>
			<Col xs="auto">
				<button className="btn btn-sm btn-primary mb-2" type="button" onClick={() => {
					toggle('I')
				}}>
					<i className="fa fa-plus"></i>
				</button>
				{(record) && (
					<>
						<br />
						<button
							id="grid-edit-button"
							type="button"
							className="btn mb-2 btn-sm"
							title="Update"
							onClick={() => toggle("U")}>
							<i className="fa fa-pencil"></i>
						</button>
						<br />
						<button
							id="grid-delete-button"
							type="button"
							className="btn text-danger mb-2 btn-sm"
							title="Delete"
							onClick={() => deleteConfirm(record)}
						>
							<i className="fa fa-trash"></i>
						</button>

					</>
				)
				}
			</Col>
		</Row>)
	}
	return (<div className="bg-white border  py-4 px-2">{showForm ? formUI() : gridUI()}</div>)
}

export default RatePeriodSpecialRate;
import React from 'react'

const JsonFormat = (json) => {
    return (
        <pre style={{ backgroundColor: '#000', padding: '1rem', borderRadius: '7px', fontSize: '13px' }}>
            <code style={{ color: '#6EE11F' }}>
                {JSON.stringify(json, null, 3)}
            </code>
        </pre>
    )
}

export default JsonFormat
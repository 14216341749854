import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Search,
  CleaningServices,
} from "@mui/icons-material";
import "devextreme/data/odata/store";

import { v4 as uuidv4 } from "uuid"
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Card, Col, Form, Row, Tabs, Tab } from "react-bootstrap";
import * as yup from "yup";
import { DataGrid, Pager, Paging, Selection } from "devextreme-react/data-grid";

import API from "../../api/api";
import { parsedUser } from "../../utils/GetCurrentUser";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useFormik } from "formik";
import Sha256 from "../../utils/sha256";
import { db } from "../../utils/db";
import { useToast } from "../../contexts/toast";
import RatePeriod from "../../components/rate-period/RatePeriod";

import { utils as SheetUtils, writeFile as writeExcel } from "xlsx";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import CRUDPageGRIDCustom from "../crud/CRUDPageGridCustom";

import FieldSelector from "../../components/customComponents/FieldSelector";
import { TextBox, TreeView } from "devextreme-react";
import ContractFields from "../../components/contracts/ContractFields";


export default function CRUDContractManager() {
  // PROPERTIES

  const [headers, setHeaders] = useState([]);//This is used for GRIDs
  const [fields, setFields] = useState(ContractFields);
  const user = parsedUser();
  const { pathname } = useLocation();
  console.log("❗ ~ file: CRUDSimple.js:34 ~ CRUDSimple ~ pathname:", pathname)
  const params = useParams();
  const toast = useToast();
  const [generalError, setGeneralError] = useState(null);
  const [open, setOpen] = useState(false);
  const [actionMode, setActionMode] = useState(null);//I=Insert, U= Update = C= Consultar, D= Delete
  const [loading, setLoading] = useState(false);
  const [isPinned, setIsPinned] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const initialModel = {
    section: {},
    data: {
      Rows: [],
    },
  }
  const [model, setModel] = useState(initialModel);
  const [search, setSearch] = useState("");
  const allowedPageSizes = [10, 20, 50, 100, "all"];
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedTab, setSelectedTab] = useState("0");
  const [recordId, setRecordId] = useState('');


  const navigate = useNavigate();
  const dropDownBoxRef = useRef(null);



  const changeDropDownBoxValue = useCallback((arg) => {
    let val = arg.addedItems[0];
    if (val) {
      updateField("ContractStatus", val.id);
    }
    dropDownBoxRef.current.instance.close();
  }, []);
  // END PROPERTIES

  //METHODS
  ///BRING THE DATA FROM THE SERVER FOR MODEL AND RECORDS
  const bindDataRemote = async (cleanFilter) => {

    // First start loading the model
    setLoading(true);

    //First load records

    try {
      let query = `token=${user.Token}`;
      if (search.length > 0 && cleanFilter == null) {
        query += "&Search=" + search;
      }
      let request = await API.getAction("api/ContractsManager/ContractRecords", query);
      let results = request.data[0];

      let parsedResults = JSON.parse(results.JSONData)[0];

      let recordResult = parsedResults.JSONData;
      if (recordResult === null) {
        //Came null 
        console.log("came null", request);
        setModel({
          ...model,
          data: {
            ...model.data,
            Rows: []
          }
        })
        return;
      }
      let configInfo = recordResult[0].JSONConfig ? recordResult[0].JSONConfig[0] : null;
      let dataFromAPI = recordResult[0].JSONConfig ? recordResult[0].JSONData : recordResult;
      console.log("Results from record", recordResult);

      //TODO: | Use JSONConfig to show specific fields
      let gridData = null;
      let gridColumns = dataFromAPI && dataFromAPI.length > 0 ? Object.keys(dataFromAPI[0]).filter(x => !x.startsWith("Id")) : null;


      //! If grid data stays null, we use the data from the API
      setModel({
        ...model,
        //  section: recordResultSection[0],
        data: {
          ...model.data,
          Columns: gridColumns,
          Rows: gridData || dataFromAPI,
        },
      });

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  const bindDataModelRemote = async (cleanFilter) => {



    // First start loading the model
    setLoading(true);

    //First load records

    try {
      let query = `token=${user.Token}`;

      let request = await API.getAction("api/ContractsManager/ContractsModel", query);

      //Check for header
      if (request.data.header) {

        let headersResponse = request.data.header;
        let firstParseHeader = JSON.parse(headersResponse);
        let parsedHeaders = JSON.parse(firstParseHeader[0].JSONData);

        let finalParseHeader = parsedHeaders[0].JSONData[0].Fields;
        setHeaders(finalParseHeader);

        console.log("the header ", headersResponse, parsedHeaders, finalParseHeader)
      }
     
      

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const bindDataDetailsRemote = async (recordId, isOld = true) => {
    //First load records
    let query = `token=${user.Token}&RecordId=${recordId}`;
    let request = await API.getAction("api/ContractsManager/ContractDetails", query);
    let results = request.data[0];

    let parsedResults = JSON.parse(results.JSONData)[0];

    if (parsedResults.Error) {
      alert(parsedResults.Msg);
      return;
    }
    let finalResult = parsedResults.JSONData[0];
    fillFormikObject(finalResult);
    setSelectedItem(finalResult)
    setRecordId(finalResult?.GUID)
    //Fill object with new values
  };

  //Open edit/add mode
  const toggleOpen = async (action, reload) => {
    setActionMode(action);

    if (action === null) {
      setSelectedItem(null);
      formik.resetForm();
    }

    if (action === "I") {
      setSelectedItem(null);
      setRecordId(uuidv4());
    }
    if (action === "U" || action === "C" || action === "D") {
      if (!selectedItem) {
        return;
      }
      await bindDataDetailsRemote(selectedItem.Id);
    }

    setGeneralError(null);
    setOpen(!open);
    if (reload) {
      bindDataRemote();
    }
  };

  //Delete the record
  const deleteConfirm = async (obj) => {
    confirmAlert({
      message: "Are you sure you want to delete this record?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {

            let query = `token=${user.Token}&RecordId=${selectedItem.Id}`;
            await API.getAction("api/ContractsManager/ContractDelete", query);
            await bindDataRemote();
            setSelectedItem(null);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  //Update the field value on formik according to what has been updated
  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  const yupTypeValidator = (item) => {
    if (item.FieldType === "string" || item.FieldType === "varchar") {
      return yup.string().label(item.FieldTitle).required();
    } else if (item.FieldType === "bool" || item.FieldType === "bit") {
      return yup.boolean().label(item.FieldTitle).required();
    } else if (item.FieldType === "int" || item.FieldType === "bigint") {
      return yup.number().label(item.FieldTitle).required();
    } else if (item.FieldType === "date") {
      return yup.date().label(item.FieldTitle).required();
    }
  };

  const [validationsSchema, setValidationSchema] = useState(null);

  const fillFormikObject = (record) => {
    console.log("record", record, "model", model.section);
    if (
      fields &&
      fields.length > 0
    ) {
      fields.forEach((item) => {
        console.log(
          "field name",
          item.FieldName,
          "RecordValue ",
          record[item.FieldName]
        );
        formik.setFieldValue(item.FieldName, record[item.FieldName]);
      });
    }

    //Now
  };

  const prepareFormikObject = () => {
    console.log("Preparing formik object", model.section)
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        initialObject[item.FieldName] = item.value;

        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }
    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };

  //This method is to save the record we are working on
  const handleSubmit = async (obj, event) => {

    let actions = {
      "I": "I",
      "U": "U",
      "D": "I"
    };

    if (actionMode === "D") {
      const { GUID, Id, ...rest } = obj;
      obj = rest;
    }


    confirmAlert({
      message: "Are you sure you want to save this record?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            console.log("Fields to evaluate", fields)

            let objSPVersion = "";
            fields.forEach((item) => {

              if (item.HtmlType !== "separator") {
                objSPVersion +=
                  (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
              }
              if (
                item.FieldType === "int" ||
                item.FieldType === "bool" ||
                item.FieldType === "bit" ||
                item.FieldType === "bigint"
              ) {
                objSPVersion +=
                  obj[item.FieldName] === undefined || obj[item.FieldName] === null
                    ? item.DefaultValue && item.DefaultValue.length > 0 ? item.DefaultValue : "null"
                    : "'" + obj[item.FieldName] + "'";
                //objSPVersion +=
                //obj[item.FieldName] === undefined ? "null" : obj[item.FieldName];
              } else if (item.HtmlType === "password") {
                let passwordEncrypted = null;
                if (obj[item.FieldName] && obj[item.FieldName].length > 0) {
                  passwordEncrypted =
                    obj["UserName"].toUpperCase() + obj[item.FieldName];
                  passwordEncrypted = Sha256.hash(passwordEncrypted);
                  ;
                }
                objSPVersion +=
                  obj[item.FieldName] === undefined
                    ? "null"
                    : "'" + passwordEncrypted + "'"
              } else
                if (item.HtmlType === 'radio') {

                  objSPVersion +=
                    obj[item.FieldName] === undefined || obj[item.FieldName] === null
                      ? item.DefaultValue && item.DefaultValue.length > 0 ? item.DefaultValue : "null"
                      : "'" + obj[item.FieldName] + "'";


                } else if (item.HtmlType !== 'separator') {
                  objSPVersion +=
                    obj[item.FieldName] === undefined || obj[item.FieldName] === null
                      ? "null"
                      : "'" + obj[item.FieldName] + "'";
                }
            });

            console.log("❗ ~ file: CRUDContractManager.js:322 ~ onClick: ~ action:", actions[actionMode])
            let queryString = `Token=${user.Token}&ActionMode=${actions[actionMode]}`;
            let queryData = {
              Data: objSPVersion,
            };

            let request = await API.postAction(
              "api/ContractsManager/ContractAddEdit?" + queryString,
              queryData
            );

            if (request.status === 200) {
              let response = JSON.parse(request.data[0].JSONData)[0];
              if (response.Error) {
                setGeneralError(response.Msg);
                return;
              }
            }
            setGeneralError(null);
            setSelectedItem(null);

            formik.resetForm();

            toast({
              type: "success",
              "message": "Record saved successfully",
            })

            // if (actionMode === 'I') {
            //   return;
            // }
            toggleOpen(null, true);
          },
        },
        {
          label: "No",
        },
      ],
    });


  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,

    validateOnChange: false,
    validateOnBlur: false,

    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  const checkIfPinned = async () => {
    try {
      await db.favorites.where("username").equals(user.Username).toArray((item) => {
        if (item.length > 0) {
          const favExists = item[0].values.some((fav) => fav.path === pathname);
          setIsPinned(favExists)
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  const addFavorite = async () => {

    const newFavorite = {
      icon: `${model.section.FormIcon}`,
      path: pathname,
      formPath: model.section.FormPath,
      title: model.section.FormTitle,
    }

    try {
      await db.favorites.where("username").equals(user.Username).modify((item) => {

        const favExists = item.values.some((fav) => fav.path === newFavorite.path);

        if (favExists) {
          setIsPinned(false)
          item.values = item.values.filter((fav) => fav.path !== newFavorite.path);
          toast({
            type: "warning",
            message: "Removed from favorites",
          });
          return;
        }

        setIsPinned(true)
        item.values.push(newFavorite);
        toast({
          type: "success",
          message: "Added to favorites",
        });
      });
    } catch (error) {
      console.error(error)
    }
  }

  const exportToExcel = useCallback(async () => {

    const worksheet = SheetUtils.json_to_sheet(model.data.Rows);
    const workbook = SheetUtils.book_new();
    SheetUtils.book_append_sheet(workbook, worksheet, "Sheet1");

    writeExcel(workbook, `${model.section.FormTitle}.xlsx`);
  }, [])

  const exportToPDF = useCallback(async () => {

    const container = document.getElementById("contentContainer");

    const containerHeight = container.clientHeight
    const containerWidth = container.clientWidth
    const ratio = containerHeight / containerWidth;

    html2canvas(container).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg");
      const pdf = new jsPDF("l", "mm", "a0");

      const width = pdf.internal.pageSize.getWidth();
      let height = pdf.internal.pageSize.getHeight();
      height = ratio * width;

      pdf.addImage(imgData, "JPEG", 0, 0, width, height);
      pdf.save(`data.pdf`);
    })
  }, [])

  // END METHODS

  // UI METHODS
  const loadGRIDUI = () => {
    return (
      <>
        {isSearching && (
          <Card className="mb-4">
            <Card.Header className="w-full d-flex justify-content-between align-items-center">
              <Card.Title>Filters</Card.Title>

              <button
                className="btn btn-secondary"
                title="Close"
                type="button"
                onClick={() => { setIsSearching(false) }}
                style={{
                  "padding": "0px 8px",
                  "lineHeight": "23px"
                }}
              >
                <i style={{ fontSize: "10px" }} className="fa-solid fa-x"></i>
              </button>
            </Card.Header>
            <Form
              onSubmit={async (e) => {
                e.preventDefault();
                await bindDataRemote();
              }}
            >
              <Card.Body className="formBackground">
                <Row>
                  <Col className={"col-12"}>
                    <TextBox
                      // className="form-control "
                      id={"search"}
                      mode={"text"}
                      value={search}
                      placeholder="Search by description"
                      onValueChanged={(e) => {
                        setSearch(e.value);
                      }}
                    />
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-end">
                <button
                  className="btn btn-sm btn-secondary me-2"
                  type="button"
                  title="Clear"
                  onClick={() => {
                    setSearch("");
                    setTimeout(async () => {
                      await bindDataRemote(true);
                    }, 1000);
                  }}
                >
                  <CleaningServices />
                </button>
                <button className="btn btn-sm btn-primary" title="Search" type="submit">
                  <Search />
                </button>
              </Card.Footer>
            </Form>
          </Card>
        )
        }
        <Card>

          <Card.Body>
            <DataGrid
              id="table-grid"
              dataSource={model.data.Rows}
              columns={headers.length > 0 ? headers.map(item => item.FieldName) : null}

              columnAutoWidth={true}
              showBorders={true}
              onSelectionChanged={selectItem}
              loadPanel={{
                enabled: true,
                showIndicator: true,
                text: "Loading...",
              }}
            >
              <Selection mode="single" />
              <Paging
                defaultPageSize={10}
              />
              <Pager
                showInfo={true}
                infoText={"Page:"}
                visible={true}
                showPageSizeSelector={true}
                allowedPageSizes={allowedPageSizes}
              />
            </DataGrid>
          </Card.Body>
        </Card>
      </>
    );
  };





  const commentItem = {
    "PageIndex": 5,
    "PageTitle": "Comment History",
    "PageIcon": "fa-solid fa-comments",
    "MultiRecord": true,
    "Active": true,

  }

  const loadFormFields = fields => {
    let uiFields = fields &&
      fields
        .filter(x => x.FieldTitle !== "Audit Information"
          && x.FieldName !== 'IdUserAdd'
          && x.FieldName !== 'IdUserMod'
          && x.FieldName !== 'DateAdd'
          && x.FieldName !== 'DateMod')
        .map((item, index) => {
          return (
            <Col
              className={
                item.cssClasss + (!item.Visible ? " d-none" : "")
              }
              key={index}
            >
              <label className="me-2 mt-2">{item.FieldTitle}</label>
              {item.Required ? <i className="required-asterisk ms-1 text-danger">*</i> : null}
              <br />

              <FieldSelector
                actionMode={actionMode}
                model={item}
                key={index}
                updateField={updateField}
                value={formik.values[item.FieldName] !== undefined? formik.values[item.FieldName] : item.value}
              ></FieldSelector>
              {formik.errors[item.FieldName] ? (
                <div className="invalid text-sm">
                  {formik.errors[item.FieldName]}
                </div>
              ) : null}
            </Col>
          );
        })

    return (uiFields)
  }


  const loadFormUI = () => {

    return (
      <div className="card">
        <Form autoComplete="off" onSubmit={formik.handleSubmit}>
          <div id="form-body" className="card-body p-4 formBackground">
            <Row>
              {/* CONTENT */}
              {loadFormFields(fields)}
            </Row>
            <Row className="px-2 mt-5">
              <Col xs={12}>
                <Tabs className="mt-4 ms-2 border-bottom-0"
                  onSelect={(key) => {
                    setSelectedTab(key)
                  }}
                >
                  <Tab
                    key={1}
                    eventKey={`${1}`}
                    className="tab-form p-0 border mb-4 "
                    style={{ borderTop: "none" }}
                    title={
                      <span>
                        <i className={"fa-solid fa-calendar me-2"}></i>
                        {'Rate Period'}
                      </span>
                    }>
                    <RatePeriod
                      actionMode={actionMode}
                      ParentGUID={recordId}
                    />
                  </Tab>
                  <Tab
                    key={2}
                    eventKey={`${2}`}
                    className="tab-form p-0  mb-4  border"
                    style={{ borderTop: "none" }}
                    title={
                      <span>
                        <i className={"fa-solid fa-comment me-2"}></i>
                        {'Contract Policies'}
                      </span>
                    }>

                    <CRUDPageGRIDCustom
                      getEndpoint="api/ContractsManager/ContractToContractPoliciesRecords"
                      modelEndpoint="api/ContractsManager/ContractToContractPoliciesModel"
                      addEditEndpoint="api/ContractsManager/ContractToContractPoliciesAddEdit"
                      deleteEndpoint="api/ContractsManager/ContractToContractPoliciesDelete"
                      detailsEndpoint="api/ContractsManager/ContractToContractPoliciesDetails"
                      ParentGUID={recordId}
                      page={{
                        "PageIndex": 2,
                        "PageTitle": "Contract Policies",
                        "PageIcon": "fa-solid fa-file-lines",
                        "MultiRecord": true,
                        "Active": true,
                      }}
                      selectedTab={selectedTab}
                      id={2}
                    />
                  </Tab>

                  <Tab
                    key={3}
                    eventKey={`${3}`}
                    className="tab-form  border p-0 mb-4 "
                    style={{ borderTop: "none" }}
                    title={
                      <span>
                        <i className={"fa-solid fa-comment me-2"}></i>
                        {'Comment History'}
                      </span>
                    }>
                    <CRUDPageGRIDCustom
                      getEndpoint="api/ContractsManager/CommentHistoryRecords"
                      modelEndpoint="api/ContractsManager/CommentHistoryModel"
                      addEditEndpoint="api/ContractsManager/CommentHistoryAddEdit"
                      deleteEndpoint="api/ContractsManager/CommentHistoryDelete"
                      detailsEndpoint="api/ContractsManager/CommentHistoryDetails"
                      ParentGUID={recordId}
                      page={commentItem}
                      selectedTab={selectedTab}
                      id={3}
                    />
                  </Tab>

                </Tabs>
              </Col>

            </Row>
          </div>
          <div
            className="card-footer"
            data-html2canvas-ignore="true"
          >
            {generalError ? (
              <Row>
                <Col xs={12}>
                  <div className="alert alert-danger" role="alert">
                    {generalError}
                  </div>
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col xs={12} className="text-end">
                {Object.values(formik.errors).length > 0 ? (
                  <span className="invalid me-2">
                    Please check the forms for errors
                  </span>
                ) : null}
                <button className="btn btn-primary me-2" type="submit" disabled={actionMode === "C"}>
                  Save
                </button>
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={() => {

                    confirmAlert({
                      message: "Are you sure you want to close this screen?",
                      buttons: [
                        {
                          label: "Yes",
                          onClick: () => {
                            toggleOpen(null)
                          },
                        },
                        {
                          label: "No",
                        },
                      ],
                    });
                  }
                  }
                >
                  Close
                </button>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    );
  };


  //EVENTS
  const selectItem = useCallback((e) => {
    e.component.byKey(e.currentSelectedRowKeys[0]).done((item) => {

      setSelectedItem(item);
    });
  }, []);

  useEffect(() => {
    setOpen(false)
    setSelectedItem(null)
    setActionMode(null)
    setGeneralError(null)
    setModel(initialModel);
    checkIfPinned();
    bindDataRemote();
    setIsSearching(false);
    setSearch("");
    bindDataModelRemote();

    return () => formik.resetForm();
  }, [params.id]);

  useEffect(() => {
    console.log("section updated", fields)
    prepareFormikObject();
  }, [fields]);
  // END EVENTS

  return (
    <React.Fragment>
      <div className="row mx-0" id="contentContainer">
        <div className="col-12">
          <div className="dx-card my-4 px-3 py-2 titleContainer">
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              {loading ? (
                <React.Fragment>
                  <div className="">
                    <div className="w-25 mx-auto my-1" style={{ paddingTop: "" }}>
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {/* <div className="col-auto"></div> */}
                  <div className="d-flex align-items-center my-1">
                    <i className={"fa-solid fa-file-lines" + " me-3 fa-2x"}></i>
                    <div className="d-flex flex-column">
                      <h5 className={"my-1"}>Contracts</h5>
                      <small>Rates Management / Contract Manager / Contracts</small>
                    </div>
                  </div>
                </React.Fragment>
              )}
              <div className="">
                <button
                  className={`btn btn-blue-overline${isPinned ? "-selected" : ""} me-2`}
                  title="Pin to Favorites"
                  onClick={() => addFavorite()}
                  data-html2canvas-ignore="true"
                >
                  <i className="fa fa-thumbtack"></i>
                </button>
                {actionMode === null ? (
                  <>
                    <button className="btn btn-primary my-1 me-2" title="Create" disabled={loading} onClick={() => toggleOpen("I")}>
                      <i className="fa fa-plus"></i>
                    </button>
                    {!isSearching && (
                      <button className={`btn btn-primary my-1 me-2`} title="Search" onClick={() => setIsSearching(true)}>
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </button>
                    )}
                  </>
                ) : null}
                {selectedItem ? (
                  <>
                    <button className="btn btn-primary my-1 me-2" title="Update" disabled={loading} onClick={() => toggleOpen("U")}>
                      <i className="fa fa-pencil"></i>
                    </button>
                    <button className="btn btn-primary my-1 me-2" title="View" disabled={loading} onClick={() => toggleOpen("C")}>
                      <i className="fa fa-eye"></i>
                    </button>
                    <button className="btn btn-primary my-1 me-2" title="Duplicate" disabled={loading} onClick={() => toggleOpen("D")}>
                      <i className="fa-solid fa-copy"></i>
                    </button>
                    <button
                      className="btn btn-secondary my-1 me-2"
                      type="button"
                      disabled={loading}
                      title="Delete"
                      onClick={() => deleteConfirm()}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </>
                ) : null}
                {actionMode === "C" && (
                  <button
                    className="btn btn-blue-overline my-1 me-2"
                    title="Export to PDF"
                    disabled={loading}
                    onClick={exportToPDF}
                    data-html2canvas-ignore="true"
                  >
                    <i className="fa-solid fa-file-pdf"></i>
                  </button>
                )}
                {actionMode === null && (
                  <button
                    className="btn btn-blue-overline my-1 me-2"
                    type="button"
                    disabled={loading}
                    title="Export to Excel"
                    onClick={exportToExcel}
                  >
                    <i className="fa-solid fa-file-excel"></i>
                  </button>
                )}
                {/* {
                  open && (

                    <button
                      className="btn border border-dark my-1 me-2"
                      type="button"
                      title="Close Window"
                      onClick={() => { navigate("/dashboard") }}
                      data-html2canvas-ignore="true"
                    >
                      <i className="fa-solid fa-x text-dark"></i>
                    </button>

                  )
                } */}
              </div>

            </div>
          </div>
          {open ? loadFormUI() : loadGRIDUI()}
        </div>
      </div>
    </React.Fragment>
  );
}

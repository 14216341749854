import { DataGrid } from 'devextreme-react'
import { Column, Pager, Paging, SearchPanel, Selection } from 'devextreme-react/data-grid'
import React from 'react'

const allowedPageSizes = [10, 20, 50, 100, "all"];

const ContractDataGrid = ({ columns, setSelectedItem, data, showSearchPanel = false, keyExpr = 'GUID' }) => {

  return (
    <DataGrid
      id="gridContainer"
      dataSource={data}
      columnAutoWidth={true}
      showBorders={true}
      //   columns={columns}
      keyExpr={keyExpr}
      onRowClick={(e) => setSelectedItem(e.data)}
      onCellClick={(e) => setSelectedItem(e.data)}
      loadPanel={{
        enabled: true,
        showIndicator: true,
        text: "Loading...",
      }}
      height={'100%'}
    >
      <Selection mode="single" />
      <Column
        alignment="center"
        caption="Id"
        dataField="Id" />
      <Column
        //  alignment="center"
        caption="Customer"
        dataField="CustomerDesc" />
      <Column
        //  alignment="center"
        caption="Currency"
        dataField="CurrencyDesc" />
      <Column
        //   alignment="center"
        caption="Start Date"
        dataField="StartDate" />
      <Column
        // alignment="center"
        caption="End Date"
        dataField="EndDate" />
      <Column
        //  alignment="center"
        caption="Status"
        dataField="StatusDesc" />
      <SearchPanel
        visible={showSearchPanel}
        width={240}
        placeholder="Search..." />
      <Paging
        defaultPageSize={10}
      />
      <Pager
        showInfo={true}
        infoText={"Page:"}
        visible={true}
        showPageSizeSelector={true}
        allowedPageSizes={allowedPageSizes}
      />
    </DataGrid>
  )
}

export default ContractDataGrid
import { DateBox, DropDownBox, TextBox } from "devextreme-react";
import React, { useState } from "react";

import { Card, Col, Row } from "react-bootstrap";
import MakeReservation from "./MakeReservation";

const AvailableReservations = (props) => {
  const [showModal, setShowModal] = useState(false);
  const toggleAddEdit = (obj) => {
    setShowModal(!showModal);
  };
  return (
    <>
      {showModal ? (
        <MakeReservation toggle={toggleAddEdit} show={showModal} />
      ) : null}
      <Card className="mb-4">
        <Card.Header>
          <Card.Title>Available Reservations for this guest</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xs={4}>
              <label className="me-2 mt-2">Amount of People</label>
              <br />
              <TextBox />
            </Col>
            <Col xs={4}>
              <label className="me-2 mt-2">Reservation Date</label>
              <br />
              <DateBox />
            </Col>
            <Col xs={4}>
              <label className="me-2 mt-2">Preference Zone</label>
              <br />
              <DropDownBox placeholder="Select option"></DropDownBox>
            </Col>
            <Col xs={4}>
              <Card className="my-4 bg-light">
                <Card.Header>
                  <Card.Title>Tropical</Card.Title>
                </Card.Header>
                <Card.Body>
                  <p>
                    Dominican Paradise
                    <br />
                    For: 2 persons
                    <br />
                    Main Loby
                    <br />
                    Type of food description
                  </p>
                  <h5>Available Schedule</h5>
                  <Row>
                    <Col className="bg-white">
                      <b> Morning</b>
                      <br />
                      <button
                        className="btn btn-link"
                        onClick={() => toggleAddEdit()}
                      >
                        6:30 am
                      </button>

                      <button
                        className="btn btn-link"
                        onClick={() => toggleAddEdit()}
                      >
                        7:30 am
                      </button>

                      <button
                        className="btn btn-link"
                        onClick={() => toggleAddEdit()}
                      >
                        8:30 am
                      </button>
                    </Col>
                    <Col className="bg-white">
                      <b>Afternoon</b>
                      <br />
                      <button
                        className="btn btn-link"
                        onClick={() => toggleAddEdit()}
                      >
                        12:30 pm
                      </button>

                      <button
                        className="btn btn-link"
                        onClick={() => toggleAddEdit()}
                      >
                        1:30 pm
                      </button>

                      <button
                        className="btn btn-link"
                        onClick={() => toggleAddEdit()}
                      >
                        2:30 pm
                      </button>
                    </Col>
                    <Col className="bg-white">
                      <b>Night</b>
                      <br />
                      <button
                        className="btn btn-link"
                        onClick={() => toggleAddEdit()}
                      >
                        6:30 pm
                      </button>

                      <button
                        className="btn btn-link"
                        onClick={() => toggleAddEdit()}
                      >
                        7:30 pm
                      </button>

                      <button
                        className="btn btn-link"
                        onClick={() => toggleAddEdit()}
                      >
                        8:30 pm
                      </button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={4}>
              <Card className="my-4 bg-light">
                <Card.Header>
                  <Card.Title>Occidental</Card.Title>
                </Card.Header>
                <Card.Body>
                  <p>
                    Shake & Burgers
                    <br />
                    For: 2 persons
                    <br />
                    Main Loby
                    <br />
                    Type of food description
                  </p>
                  <h5>Available Schedule</h5>
                  <Row>
                    <Col className="bg-white">
                      <b> Morning</b>
                      <br />
                      <button
                        className="btn btn-link"
                        onClick={() => toggleAddEdit()}
                      >
                        6:30 am
                      </button>

                      <button
                        className="btn btn-link"
                        onClick={() => toggleAddEdit()}
                      >
                        7:30 am
                      </button>

                      <button
                        className="btn btn-link"
                        onClick={() => toggleAddEdit()}
                      >
                        8:30 am
                      </button>
                    </Col>
                    <Col className="bg-white">
                      <b>Afternoon</b>
                      <br />
                      <button
                        className="btn btn-link"
                        onClick={() => toggleAddEdit()}
                      >
                        12:30 pm
                      </button>

                      <button
                        className="btn btn-link"
                        onClick={() => toggleAddEdit()}
                      >
                        1:30 pm
                      </button>

                      <button
                        className="btn btn-link"
                        onClick={() => toggleAddEdit()}
                      >
                        2:30 pm
                      </button>
                    </Col>
                    <Col className="bg-white">
                      <b>Night</b>
                      <br />
                      <button
                        className="btn btn-link"
                        onClick={() => toggleAddEdit()}
                      >
                        6:30 pm
                      </button>

                      <button
                        className="btn btn-link"
                        onClick={() => toggleAddEdit()}
                      >
                        7:30 pm
                      </button>

                      <button
                        className="btn btn-link"
                        onClick={() => toggleAddEdit()}
                      >
                        8:30 pm
                      </button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={4}>
              <Card className="my-4 bg-light">
                <Card.Header>
                  <Card.Title>Asian</Card.Title>
                </Card.Header>
                <Card.Body>
                  <p>
                    Ikigai
                    <br />
                    For: 2 persons
                    <br />
                    Main Loby
                    <br />
                    Type of food description
                  </p>
                  <h5>Available Schedule</h5>
                  <Row>
                    <Col className="bg-white">
                      <b> Morning</b>
                      <br />
                      <button
                        className="btn btn-link"
                        onClick={() => toggleAddEdit()}
                      >
                        6:30 am
                      </button>

                      <button
                        className="btn btn-link"
                        onClick={() => toggleAddEdit()}
                      >
                        7:30 am
                      </button>

                      <button
                        className="btn btn-link"
                        onClick={() => toggleAddEdit()}
                      >
                        8:30 am
                      </button>
                    </Col>
                    <Col className="bg-white">
                      <b>Afternoon</b>
                      <br />
                      <button
                        className="btn btn-link"
                        onClick={() => toggleAddEdit()}
                      >
                        12:30 pm
                      </button>

                      <button
                        className="btn btn-link"
                        onClick={() => toggleAddEdit()}
                      >
                        1:30 pm
                      </button>

                      <button
                        className="btn btn-link"
                        onClick={() => toggleAddEdit()}
                      >
                        2:30 pm
                      </button>
                    </Col>
                    <Col className="bg-white">
                      <b>Night</b>
                      <br />
                      <button
                        className="btn btn-link"
                        onClick={() => toggleAddEdit()}
                      >
                        6:30 pm
                      </button>

                      <button
                        className="btn btn-link"
                        onClick={() => toggleAddEdit()}
                      >
                        7:30 pm
                      </button>

                      <button
                        className="btn btn-link"
                        onClick={() => toggleAddEdit()}
                      >
                        8:30 pm
                      </button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default AvailableReservations;

import axios from "axios";
export const Settings = {
  APIPath: window.location.href.indexOf("qa.signumpms.signos-framework.com") > -1 ? "https://lifestylepmsapiqa.valegos.com/" : "https://lifestylepmsapi.valegos.com/", //"https://localhost:5001/",//
  // APIPath: "https://localhost:7093/",
  //APIAUTHPath: "https://auth.signos.com.do/",
};
const options = {
  headers: {
    "Content-Type": "application/json",
    accept: "text/plain",
    "Access-Control-Allow-Origin": "*",
  },
};


export const AxiosInstance = axios.create({
  baseURL: Settings.APIPath,
  ...options
})

const API = {
  postAction: async (endpoint, params, newOpts) =>
    AxiosInstance.post(
      endpoint,
      params !== null ? params : null,
      newOpts && newOpts,
    ),
  getAction: async (endpoint, params, newOpts) =>
    AxiosInstance.get(
      endpoint + (params !== null ? "?" + params : ""),
      newOpts && newOpts
    ),
  postActionExternal: async (endpoint, params, newOpts) =>
    axios.post(
      endpoint,
      params !== null ? params : null,
      newOpts !== null ? newOpts : options
    ),
  getActionExternal: async (endpoint, newOpts) => {
    let results = axios.get(endpoint, newOpts);

    return results;
  },
};

export default API;

import React, { useEffect, useState } from 'react'
import ContractDataGrid from '../ContractDataGrid/ContractDataGrid';
import { Col, Placeholder, Row } from 'react-bootstrap';
import { generateColumnsFromJson } from '../../../utils/generate-columns';
import ContractPolicySel from './ContractPolicySelector';
import { parsedUser } from '../../../utils/GetCurrentUser';
import API from '../../../api/api';
import { useToast } from '../../../contexts/toast';

const ContractPoliciesTab = (props) => {

  // PROPERTIES
  const user = parsedUser();
  const toast = useToast();
  const [data, setData] = useState([]);
  const [column, setColumn] = useState([]);
  const [contractPolicySelected, setContractPolicySelect] = useState(null);
  const [newPolicy, setNewPolicy] = useState(null)
  const [contractPoliceRecords, setContractPoliceRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // METHODS
  const bindDataRemoteContractPolicies = async () => {
    try {
      const id = 'd1aa67f5372ce22410146eeb0003120f30ff52d6fb8ea350d09c1a121f363c69';
      const query = `token=${user.Token}&id=${id}`;
      const request = await API.getAction("api/selector", query);
      const results = request.data[0];

      const parsedResults = JSON.parse(results.JSONData)[0];

      if (parsedResults.Error) {
        throw Error(parsedResults.Msg);
      }

      const recordResult = JSON.parse(parsedResults.JSONData);
      console.log('⚖️⚖️⚖️',recordResult)

      if (recordResult) {
        const parsedItems = recordResult.map((item) => ({
          id: item.Id,
          text: item.Description,
        }));

        setContractPoliceRecords(parsedItems);
      }

    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addPolice = () => {
    const checkIfExist = data.find((item) => item.id === Number(newPolicy.id));
    if (checkIfExist) return console.log('already exist');

    if (!newPolicy) return console.log('No option selected');
    setData((prev) => [...prev, newPolicy]);

    toast({
      type: "success",
      "message": "Contract Policy added successfully",
    })
  }

  const remove = (item) => {
    setData(data.filter((i) => i !== item))
    if (contractPolicySelected === item) setContractPolicySelect(null);
    toast({
      type: "error",
      "message": "Contract Policy removed successfully",
    })
  }

  // EVENTS
  // useEffect(() => {
  //   console.log('Contract Policies tab data:', data)
  //   if (data.length > 0) {
  //     setColumn(generateColumnsFromJson(data[0]))
  //   }
  // }, [data])

  useEffect(() => {
    props.update(data)
  }, [data])

  useEffect(() => {
    bindDataRemoteContractPolicies()
  }, [])

  if (!data) {
    return (<></>)
  }
  return (
    <div className={'container bg-white px-2 py-4'}>
      <Row style={{ minHeight: '150px' }} className='px-2'>
        <Col xs={12} className='d-flex gap-2'>
          {isLoading ?
            <Placeholder className="w-100 rounded" style={{ height: '2rem' }} animation='wave' bg='secondary' /> :
            <ContractPolicySel optionsList={contractPoliceRecords} optionSelected={(data) => setNewPolicy(data)}>
              <button className={`btn btn-sm ${!newPolicy && 'disabled'} btn-primary align-self-center`} onClick={addPolice} type="button">
                <i className="fas fa-plus"></i>
              </button>
            </ContractPolicySel>
          }
        </Col>
        <Col xs={12} className='d-flex justify-content-center align-items-center mt-4'>
          {(data.length > 0) ?
            <div className='overflow-auto d-flex align-items-start gap-2 px-1'>
              <ContractDataGrid
                keyExpr={'id'}
                data={data}
                //      columns={column}
                key={data.length}
                setSelectedItem={(selection) => setContractPolicySelect(selection)}
              />
              <div className='d-flex flex-column gap-2 w-100'>
                {contractPolicySelected && (
                  <>
                    <button className={`btn btn-sm ${!contractPolicySelected && 'disabled'}`} onClick={() => remove(contractPolicySelected)} type="button" title='Delete contract policy'>
                      <i className="fas fa-trash"></i>
                    </button>
                  </>
                )}
              </div>
            </div>
            : <p className="h4 text-center text-muted">Please assign a Contract Policy</p>
          }
        </Col>
      </Row>
    </div>
  )
}

export default ContractPoliciesTab
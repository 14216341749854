import { useEffect, useState } from "react";
import { actionsModel } from "../../../../enum/actionsModel.enum";
import { Fieldset } from "./CommentHistoryFields";
import FieldSelector from "../../../customComponents/FieldSelector";
import { Col, Form, Modal, Row } from "react-bootstrap";
import * as yup from 'yup'
import { useFormik } from "formik";

const CommentHistoryModal = ({ setShow, data, action, clearSelection, submitComment }) => {

  const [fields] = useState(Fieldset)
  const [validationSchema, setValidationSchema] = useState(null);

  useEffect(() => {
    prepareFormikObject();
    if (action === actionsModel.EDIT) {
      console.log('Data to edit:', data)
      fillFormikObject(data);
    }
  }, [data, action]);

  const prepareFormikObject = () => {
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        initialObject[item.FieldName] = item.value;

        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }
    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
  };

  const fillFormikObject = (record) => {
    if (fields?.length > 0) {
      fields.forEach((item) => {
        formik.setFieldValue(item.FieldName, record[item.FieldName]);
      });
    }
  };

  // Yup Type Validator
  const yupTypeValidator = (item) => {
    const { FieldType, FieldTitle } = item;
    if (FieldType === "string" || FieldType === "varchar") {
      return yup.string().label(FieldTitle).required();
    } else if (FieldType === "bool" || FieldType === "bit") {
      return yup.boolean().label(FieldTitle).required();
    } else if (FieldType === "int" || FieldType === "bigint") {
      return yup.number().label(FieldTitle).required();
    } else if (FieldType === "date") {
      return yup.date().label(FieldTitle).required();
    }
  };

  // SUBMIT FORM
  const handleSubmit = (values) => {
    if (action === actionsModel.EDIT) {
      values.id = data.id
    }
    submitComment(action, values)
    handleClose()
  }

  // CLOSE FORM
  const handleClose = () => {
    formik.resetForm();
    setShow(false);
  }

  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,

    validateOnChange: false,
    validateOnBlur: false,

    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Modal
      show={true}
      onHide={() => { }}
      backdrop="static"
      keyboard={false}
      size="md"
      className="modalRight"
    >
      <Modal.Header>
        Comment History
        <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              {fields?.map((item, index) => (
                <div key={index} className={`${item.cssClasss} mb-4`}>
                  <label className="me-2 mt-2">{item.FieldTitle}</label>
                  <br />
                  <FieldSelector
                    model={item}
                    updateField={updateField}
                    // data={data}
                    // action={action}
                    value={formik.values[item.FieldName] !== undefined ? formik.values[item.FieldName] : item.value}
                  />
                  {formik.errors[item.FieldName] && <div className="invalid text-sm">{formik.errors[item.FieldName]}</div>}
                </div>
              ))}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gx-3 align-items-center justify-content-end">
            <button className="btn btn-secondary me-2" onClick={handleClose} type="button">Close</button>
            <button className="btn btn-primary me-2" type="submit">Save</button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default CommentHistoryModal;

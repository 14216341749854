import React from 'react'

const CustomBreadcrumbs = ({pathTitle}) => {
  return (
    <nav aria-label="breadcrumb" className="mb-2">
      <ol className="breadcrumb border-bottom bg-light px-4 py-2">
        <li className="breadcrumb-item">
          {pathTitle}
        </li>
      </ol>
    </nav>
  )
}

export default CustomBreadcrumbs
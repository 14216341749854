const ContractFields = [
    {
        "IdAppForm": 62,
        "Id": 6200010,
        "FieldName": "Id",
        "FieldType": "bigint",
        "FieldTitle": "Contract Code",
        "Position": 1,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "text",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": null,
        "Required": false,
        "Visible": true,
        "ReadOnly": true
    },
    {
        "IdAppForm": 62,
        "Id": 6200002,
        "FieldName": "IdCustomer",
        "FieldType": "bigint",
        "FieldTitle": "Customer",
        "Position": 2,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "select",
        "PlaceHolder": "",
        "DefaultValue": "",
        "OptionValues": null,
        "DataSource": "{\"HashID\":\"846913eebc9b8aa7911a0eadac6ef961ed83fe2cad71fdf1d46d5770f889e56a\"}",
        "Required": true,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "IdAppForm": 62,
        "Id": 6200003,
        "FieldName": "StartDate",
        "FieldType": "datetime",
        "FieldTitle": "StartDate",
        "Position": 3,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "date",
        "PlaceHolder": "",
        "DefaultValue": "",
        "OptionValues": null,
        "DataSource": null,
        "Required": true,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "IdAppForm": 62,
        "Id": 6200004,
        "FieldName": "EndDate",
        "FieldType": "datetime",
        "FieldTitle": "EndDate",
        "Position": 4,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "date",
        "PlaceHolder": "",
        "DefaultValue": "",
        "OptionValues": null,
        "DataSource": null,
        "Required": true,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "IdAppForm": 62,
        "Id": 6200011,
        "FieldName": "ContractNumber",
        "FieldType": "varchar",
        "FieldTitle": "Reference",
        "Position": 5,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "text",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": null,
        "Required": false,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "IdAppForm": 62,
        "Id": 6200006,
        "FieldName": "Comments",
        "FieldType": "varchar",
        "FieldTitle": "Comments",
        "Position": 6,
        "cssClasss": "col-12 col-md-4",
        "HtmlType": "textarea",
        "PlaceHolder": "",
        "DefaultValue": "",
        "OptionValues": null,
        "DataSource": null,
        "Required": false,
        "Visible": false,
        "ReadOnly": false
    },
    {
        "IdAppForm": 62,
        "Id": 6200012,
        "FieldName": "Status",
        "FieldType": "char",
        "FieldTitle": "Contract Status",
        "Position": 7,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "radio",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": "[{\"id\": 0,\"text\": \"Active\"},{\"id\": 1,\"text\": \"Inactive\"}]",
        "DataSource": null,
        "Required": false,
        "Visible": false,
        "ReadOnly": false
    },
    {
        "IdAppForm": 62,
        "Id": 6200013,
        "FieldName": "IdCurrency",
        "FieldType": "bigint",
        "FieldTitle": "Currency",
        "Position": 8,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "select",
        "PlaceHolder": "USD",
        "DefaultValue": "1",
        "OptionValues": "[{\"id\": 1,\"text\": \"USD\"}]",
        "DataSource": "{\"HashID\":\"637f44b9bafa1fe74b0a72c887b1c3262c833080fea07d40768858638f0fc87b\"}",
        "Required": false,
        "Visible": true,
        "ReadOnly": true,
        "value": 1
    },
    {
        "IdAppForm": 62,
        "Id": 6200008,
        "FieldName": "Printed",
        "FieldType": "bit",
        "FieldTitle": "Printed",
        "Position": 8,
        "cssClasss": "col-12 col-md-4",
        "HtmlType": "radio",
        "PlaceHolder": "",
        "DefaultValue": "",
        "OptionValues": "[{\"id\": 0,\"text\": \"No\"},{\"id\": 1,\"text\": \"Si\"}]",
        "DataSource": null,
        "Required": false,
        "Visible": false,
        "ReadOnly": false
    },
    {
        "IdAppForm": 62,
        "Id": 6200009,
        "FieldName": "Active",
        "FieldType": "bit",
        "FieldTitle": "Active",
        "Position": 9,
        "cssClasss": "col-12 col-md-4",
        "HtmlType": "radio",
        "PlaceHolder": "",
        "DefaultValue": "",
        "OptionValues": "[{\"id\": 0,\"text\": \"No\"},{\"id\": 1,\"text\": \"Si\"}]",
        "DataSource": null,
        "Required": false,
        "Visible": false,
        "ReadOnly": false
    },
    {
        "IdAppForm": 62,
        "Id": 6200016,
        "FieldName": "IdStatus",
        "FieldType": "int",
        "FieldTitle": "Status",
        "Position": 9,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "select",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": "{\"HashID\":\"dcd725c8de4a7a0ea1c83801bb3a0ac523cfca2d02bddbe1e96d14b243de0391\"}",
        "Required": false,
        "Visible": true,
        "ReadOnly": false
    }
]

export default ContractFields;
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

/**
 * Export the content of an HTML element to a PDF file.
 * @param {HTMLElement} container - The HTML element whose content you want to export.
 */
export const exportToPDF = async (container) => {
  try {
    if (!container) {
      throw new Error("Container element is missing.");
    }

    // Get the dimensions of the container
    const containerHeight = container.clientHeight;
    const containerWidth = container.clientWidth;
    const ratio = containerHeight / containerWidth;

    // Use html2canvas to capture the content in the container
    const canvas = await html2canvas(container);

    // Create a new PDF document in landscape orientation
    const pdf = new jsPDF("landscape");

    // Calculate the height of the PDF based on the aspect ratio
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdfWidth * ratio;

    // Add the captured image to the PDF
    const imgData = canvas.toDataURL("image/jpeg");
    pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);

    // Save the PDF with a specific filename
    pdf.save("data.pdf");

    // Log a success message if the export is successful
    console.log("PDF file 'data.pdf' exported successfully.");
  } catch (error) {
    // Log an error message if there's an issue during export
    console.error("Error exporting to PDF:", error);
  }
};

import { DataGrid } from 'devextreme-react'
import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'

const BusinnesUnit = () => {

    return (
        <Container id="tab-grid-body" fluid className=" ">

            <Card>
                <Card.Body className="p-0">
                    <Row className="mx-0">
                        <Col className="py-2 " >
                            <DataGrid
                                dataSource={[]}
                                columns={
                                        [
                                            {
                                                dataField: "GUID",
                                                caption: "GUID",
                                            },
                                           {
                                                dataField: "IdBussinesUnit",
                                                caption: "IdBussinesUnit",
                                           },
                                             {
                                                    dataField: "IdCreditCondition",
                                                    caption: "IdCreditCondition",
                                             },
                                             {
                                                    dataField: "CreditLimit",
                                                    caption: "CreditLimit",
                                             },
                                             {
                                                    dataField: "RoomChargeTime",
                                                    caption: "RoomChargeTime",
                                             },
                                             {
                                                    dataField: "StopSales",
                                                    caption: "StopSales",
                                             },
                                             {
                                                    dataField: "Delete",
                                                    caption: "Delete",
                                             },
                                        ]
                                }
                                // columns={records.Columns.length > 0 ? records.Columns : null}
                                columnAutoWidth={true}
                                showColumnHeaders={true}
                                showBorders={true}
                                loadPanel={{
                                    enabled: true,
                                    showIndicator: true,
                                    text: "Loading...",
                                }}
                           />

                        </Col>
                        <Col className="col-auto ml-auto text-center bg-light border ">
                            <div className="d-flex flex-column gy-3 align-items-center justify-content-center">
                                <button
                                    type="button"
                                    className="btn btn-primary my-2 btn-sm"
                                    // onClick={() => toggleOpen("I")}
                                    title="Add"
                                    // disabled={actionMode === "C"}
                                >
                                    <i className="fa fa-plus"></i>
                                </button>
                               
                            </div>
                        </Col>
                    </Row>

                </Card.Body>
            </Card>

        </Container>
    )
}

export default BusinnesUnit

import { DataGrid } from 'devextreme-react';
import { Column,SearchPanel, Selection } from 'devextreme-react/data-grid';
import React, { useCallback, useRef, useState } from 'react';
import { Card, Col, Row, Modal } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';


const PoliciesList = {
    rows: [
        {
            Policy: "Cancellation policies",
            details: "Cancellation policies sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Cancellation policies sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Cancellation policies sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
        },

        {
            Policy: "No show policies" , 
            details: "No show policies sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. No show policies sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        },
        {
            Policy: "Deposit policies",
            details: "Deposit policies sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Deposit policies sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        }
    ]
}

const PoliciesTab = () => {
    const [open, setOpen] = useState(false);
    const [policies, setPolicies] = useState(PoliciesList.rows);
    const [showDetails, setShowDetails] = useState({ show: false, details: "" });
    const selectedItemRef = useRef(null);
    const [policySelected, SetPolicySelected] = useState(null);

    // const cancelPolicy = () => {
    //     setOpen(false);
    //     setShowDetails({ show: false, details: "" });
    // }
    const selectItem = useCallback((e) => {
        selectedItemRef.current = e.component;
        e.component.byKey(e.currentSelectedRowKeys[0]).done((item) => {
            SetPolicySelected(item);
        });
    }, []);
    const deleteConfirm = async (obj) => {
        confirmAlert({
            message: "Are you sure you want to delete this record?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        const newRows = policies.filter((item) => item !== obj);
                        setPolicies(newRows);
                        SetPolicySelected(null);
                    },
                },
                { label: "No" },
            ],
        });
    };

    return (
        <Row className="d-flex row  bg-body-tertiary border-1 shadow-sm mx-0">
            <Col className='col-table my-4'>
                <Card>
                    <Card.Body>
                        <DataGrid
                        onSelectionChanged={selectItem} dataSource={policies}>
                          <Selection mode="single" />

                           {/* <SearchPanel visible={true} /> */}
                            <Column alignment="center" caption="Cancellation policies" dataField="Policy" />
                            <Column alignment="center" caption="No show policies" dataField="Policy" />
                            <Column alignment="center" caption="Deposit policies" dataField="Policy" />
                        </DataGrid>
                    </Card.Body>
                </Card>
            </Col>
            <div style={{ width: "50px" }} className="px-0 mt-4">
                <div className="d-flex flex-column gy-3 align-items-center justify-content-center">
                    <button
                        type="button"
                        className="btn btn-primary mb-2"
                        onClick={() => setOpen(true)}
                        title="Add" >
                        <i className="fa fa-plus"></i>
                       
                    </button>
                    {
                                policySelected && (
                                    <>
                                        <button 
                                            type="button" className="btn btn-primary mb-2" title="Update">
                                            <i className="fa fa-pencil" />
                                        </button>
                                        <button id="grid-delete-button"
                                            onClick={() => deleteConfirm(policySelected)}
                                            type="button" className="btn btn-secondary mb-2" title="Delete">
                                            <i className="fa fa-trash" />
                                        </button>
                                    </>
                                )
                            }
                </div>
            </div>
           
        </Row>
    )
}

export default PoliciesTab;
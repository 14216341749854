const CHARGESDISCOUNTFIELDS = [
	{
		"IdAppForm": 76,
		"Id": 7600001,
		"FieldName": "IdContract",
		"FieldType": "bigint",
		"FieldTitle": "Contract",
		"Position": 1,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "select",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": "{\"HashID\":\"1a1a1446cb29d5afcaa119e8c68f86df56f645a22b7a13adce601a33721da780\"}",
		"Required": false,
		"Visible": false,
		"ReadOnly": false
	},
	{
		"IdAppForm": 76,
		"Id": 7600002,
		"FieldName": "IdBusinessUnit",
		"FieldType": "bigint",
		"FieldTitle": "Business Unit",
		"Position": 2,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "select",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": "{\"HashID\":\"be60cb6ae9e3b5bfc7eb84cb4127a5f0b071e40ad1a9dd2dc20035c778f507ef\"}",
		"Required": false,
		"Visible": false,
		"ReadOnly": false
	},
	{
		"IdAppForm": 76,
		"Id": 7600003,
		"FieldName": "IdContractRatePeriod",
		"FieldType": "bigint",
		"FieldTitle": "Contract Rate Period",
		"Position": 3,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "select",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": "{\"HashID\":\"c72b6b91ad943bda15484201660283a8eb56c01952d44ceee40ef3bb83b7aa92\"}",
		"Required": false,
		"Visible": false,
		"ReadOnly": false
	},
	{
		"IdAppForm": 76,
		"Id": 7600004,
		"FieldName": "IdRoomType",
		"FieldType": "bigint",
		"FieldTitle": "Room Type",
		"Position": 4,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "select",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": "{\"HashID\":\"c8cd1c30a5cd65d35b2dc5b4c0287d131a741f68a85fd9828e4216931f6ff384\"}",
		"Required": false,
		"Visible": false,
		"ReadOnly": false
	},
	{
		"IdAppForm": 76,
		"Id": 7600005,
		"FieldName": "IdGuestType",
		"FieldType": "bigint",
		"FieldTitle": "Guest Type",
		"Position": 5,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "select",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": "{\"HashID\":\"4d8534f864fae064935e9b1787252d68463e1cd28013ea426fd9a131249bc00e\"}",
		"Required": false,
		"Visible": false,
		"ReadOnly": false
	},
	{
		"IdAppForm": 76,
		"Id": 7600006,
		"FieldName": "Id",
		"FieldType": "bigint",
		"FieldTitle": "Id",
		"Position": 6,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "text",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": null,
		"Required": false,
		"Visible": false,
		"ReadOnly": false
	},
	{
		"IdAppForm": 76,
		"Id": 7600007,
		"FieldName": "Description",
		"FieldType": "varchar",
		"FieldTitle": "Description",
		"Position": 7,
		"cssClasss": "col-12 col-md-12",
		"HtmlType": "text",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": null,
		"Required": true,
		"Visible": true,
		"ReadOnly": false
	},
	{
		"IdAppForm": 76,
		"Id": 7600008,
		"FieldName": "DateStart",
		"FieldType": "datetime",
		"FieldTitle": "Date Start",
		"Position": 8,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "date",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": null,
		"Required": true,
		"Visible": true,
		"ReadOnly": false
	},
	{
		"IdAppForm": 76,
		"Id": 7600009,
		"FieldName": "DateEnd",
		"FieldType": "datetime",
		"FieldTitle": "Date End",
		"Position": 9,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "date",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": null,
		"Required": true,
		"Visible": true,
		"ReadOnly": false
	},
	{
		"IdAppForm": 76,
		"Id": 7600010,
		"FieldName": "Amount",
		"FieldType": "money",
		"FieldTitle": "Amount",
		"Position": 10,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "number",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": null,
		"Required": true,
		"Visible": true,
		"ReadOnly": false
	},
	{
		"IdAppForm": 76,
		"Id": 7600011,
		"FieldName": "Comments",
		"FieldType": "varchar",
		"FieldTitle": "Comments",
		"Position": 11,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "textarea",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": null,
		"Required": false,
		"Visible": true,
		"ReadOnly": false
	},
	{
		"IdAppForm": 76,
		"Id": 7600012,
		"FieldName": "Active",
		"FieldType": "bit",
		"FieldTitle": "Active",
		"Position": 12,
		"cssClasss": "col-12 col-md-6",
		"HtmlType": "radio",
		"PlaceHolder": "",
		"DefaultValue": "{\"1\"}",
		"OptionValues": "[{\"id\": 0,\"text\": \"No\"},{\"id\": 1,\"text\": \"Si\"}]",
		"DataSource": null,
		"Required": false,
		"Visible": false,
		"ReadOnly": false
	},
	{
		"IdAppForm": 76,
		"Id": 7600020,
		"FieldName": "GUID",
		"FieldType": "uniqueidentifier",
		"FieldTitle": "GUID",
		"Position": 20,
		"cssClasss": "col-12 col-md-4",
		"HtmlType": "text",
		"PlaceHolder": "",
		"DefaultValue": "",
		"OptionValues": null,
		"DataSource": null,
		"Required": false,
		"Visible": false,
		"ReadOnly": false
	}
]

export default CHARGESDISCOUNTFIELDS;
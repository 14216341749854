import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";
import RatePeriodFields from "../../rate-period/RatePeriodFields";
import FieldSelector from "../../customComponents/FieldSelector";
import API from "../../../api/api";
import { parsedUser } from "../../../utils/GetCurrentUser";
import RatePeriodSpecialRate from "./RatePeriodSpecialRate";
import RatePeriodChargesDiscounts from "./RatePeriodChargesDiscounts";
import RatePeriodPolicies from "./RatePeriodPolicies";
import yupTypeValidator from "../../../utils/YupTypeValidator";
import * as yup from "yup";
import { v4 as uuidv4 } from "uuid";

const RatePeriodDetails = props => {
	const [validationsSchema, setValidationSchema] = useState(null);
	const [record, setRecord] = useState(props.record ?? {});
	const fields = RatePeriodFields;
	const user = parsedUser();
	const [roomTypeSelected, setRoomTypeSelected] = useState(null);

	const [roomTypes, setRoomTypes] = useState([]);


	const handleSubmit = obj => {
		obj.RoomType = record.RoomType;
		obj.SpecialRate = record.SpecialRate;
		obj.ChargesDiscount = record.ChargesDiscount;
		obj.RatePolicies = record.RatePolicies;
		// console.log(props);
		if (props.actionMode === 'I') {
			debugger
			obj.GUID = uuidv4();
			obj.Delete = false;
		}
		//console.log(obj, record);
		formik.validateForm();
		if (!formik.isValid) {
			return;
		}

		props.toggle(obj, null);
	}

	const prepareFormikObject = () => {
		//("Preparing formik object", fields)
		let initialObject = {};
		let initialObjectValidation = {};


		if (fields) {
			fields.forEach((item) => {

				if (item.FieldName === "Id" || item.FieldName === "File") return;

				initialObject[item.FieldName] = item.value;

				//TODO: REMOVE THIS CONDITION!!!!!!
				if (item.Required) {
					initialObjectValidation[item.FieldName] = yupTypeValidator(item);
				}
			});
		}

		formik.initialValues = initialObject;

		setValidationSchema(yup.object(initialObjectValidation));
		//Now
	};
	const updateField = (fieldName, fieldValue) => {
		formik.setFieldValue(fieldName, fieldValue);
		setRecord({ ...record, fieldName: fieldValue });
	};


	const fillFormikObject = (record) => {
		//console.log("record", record, "model", fields);
		if (
			fields &&
			fields.length > 0
		) {
			fields.forEach((item) => {
				// //		console.log(
				// 			"field name",
				// 			item.FieldName,
				// 			"RecordValue ",
				// 			record[item.FieldName]
				// 		);
				formik.setFieldValue(item.FieldName, record[item.FieldName]);
			});
		}

		//Now
	};

	const updateFieldRoomType = (fieldName, fieldValue) => {
		//console.log(fieldName, fieldValue)
		if (fieldName === "Allotments") {
			setRoomTypeSelected({
				...roomTypeSelected,
				Allotments: fieldValue
			})
		} else
			if (fieldName === "Release") {
				setRoomTypeSelected({
					...roomTypeSelected,
					Release: fieldValue
				})
			}
	};


	const formik = useFormik({
		initialValues: {},
		enableReinitialize: true,

		validateOnChange: false,
		validateOnBlur: false,

		validationSchema: validationsSchema,
		onSubmit: handleSubmit,
	});


	const bindRoomTypesRemote = async () => {
		try {

			let id = "78e296a1adde0c27623089555b750a9d5e0ec5645b9766e7ba63ebf0112a2248";
			let query = `token=${user.Token}&id=${id}&column1=IdBusinessUnit&value1=${props.businessUnitSelected.IdBusinessUnit}`;
			var request = await API.getAction("api/selector", query);
			let results = request.data[0];
			let parsedResults = JSON.parse(results.JSONData)[0];


			if (parsedResults.Error) {
				console.error(parsedResults.Msg);
				return;
			}
			let recordResult = JSON.parse(parsedResults.JSONData);

			if (recordResult) {

				let assign = []
				recordResult.forEach((item) => {

					let newRecord = {
						GUID: uuidv4(),
						IdRoomType: item.Id,
						RoomTypeDesc: item.Description,
						Allotments: "",
						Release: "",
						Delete: false,
						Ocupations: []

					}

					assign.push(newRecord);


				});

				setRoomTypes(assign)


			}
		} catch (error) {
			console.error(error);
		}
	};

	// LOAD THE ROOM TYPE OCUPATION SELECTED
	const bindDataRoomTypeOccupationDetailsRemote = async (item) => {
		//First load records


		let query = `token=${user.Token}&IdBusinessUnit=${props.businessUnitSelected.IdBusinessUnit}&IdRoomType=${item.IdRoomType}`;
		let request = await API.getAction("api/ContractsManager/RatePeriodRoomTypeDetailsV2", query);
		let results = request.data[0];

		let parsedResults = JSON.parse(results.JSONData);

		if (parsedResults.Error) {
			alert(parsedResults.Msg);
			return;
		}

		let finalResult = parsedResults.JSONData;

		return finalResult;
	}

	const assignSpecialRate = obj => {
		console.log(obj);
		setRecord({
			...record,
			SpecialRate: obj
		})
	}
	const assignPolicies = obj => {
		console.log(obj);
		setRecord({
			...record,
			RatePolicies: obj
		})
	}

	const assignChargesDiscounts = obj => {
		console.log(obj);
		setRecord({
			...record,
			ChargesDiscount: obj
		})
	}
	const updateSelectedOcupation = (ocupation, rate, apply) => {


		ocupation.rate = rate;
		ocupation.apply = apply;
		let ocupations = roomTypeSelected.Ocupations;
		ocupations.filter(x => x.GUID === ocupation.GUID)[0] = ocupation;
		setRoomTypeSelected({
			...roomTypeSelected,
			Ocupations: ocupations
		})
	}

	const selectRoomType = async obj => {
		console.log(obj, record);

		//Try to find the record 
		//if(record && record.)
		if (obj.Ocupations && obj.Ocupations.length === 0) {

			let occupations = await bindDataRoomTypeOccupationDetailsRemote(obj);
			obj.Ocupations = occupations
			//Now that we have ocupation we must assign it
			let types = roomTypes;
			types.filter(x => x.GUID === obj.GUID)[0] = obj;
			setRoomTypes(types);
		}
		setRoomTypeSelected(obj);

	}

	useEffect(() => {
		bindRoomTypesRemote();
		prepareFormikObject();
		console.log(props)
		if (props.record && Object.keys(props.record).length > 0) {
			fillFormikObject(props.record)
		}
	}, [])



	useEffect(() => {

		console.log("room type selected", roomTypeSelected)
		if (roomTypeSelected === null) {
			return;
		}
		let types = roomTypes;
		types.filter(x => x.GUID === roomTypeSelected.GUID)[0] = roomTypeSelected;
		setRoomTypes(types);
	}, [roomTypeSelected])

	useEffect(() => {
		setRecord({ ...record, RoomType: roomTypes })
	}, [roomTypes]);

	useEffect(() => {
		console.log(record)
	}, [record])
	return (<Modal
		show={props.show}
		onHide={() => props.toggle(null)}
		backdrop="static"
		keyboard={false}
		size="xl"
		className='modalRight'
		dialogClassName='modal-90w'
	>
		<Modal.Header closeButton>
			<Row>
				<Col>
					<h4>
						{props.businessUnitSelected && props.businessUnitSelected.BusinessUnitDesc} - Rate Period
					</h4>
				</Col>
			</Row>

		</Modal.Header>
		<Form onSubmit={formik.handleSubmit}>
			<Modal.Body className='p-0'>
				<Container fluid>
					<Row>
						<Col xs={12} >
							{/* GENERIC */}
							<Row className='d-flex '>

								{
									fields.map((item, index) => (
										<Col
											className={
												item.cssClasss + (!item.Visible ? " d-none" : "")
											}
											key={index}
										>
											<label className="me-2 mt-2">{item.FieldTitle}</label>
											{item.Required ? <i className="required-asterisk ms-1 text-danger">*</i> : null}
											<br />

											<FieldSelector
												actionMode={props.actionMode}
												model={item}
												key={index}
												updateField={updateField}
												value={formik.values[item.FieldName]}
											></FieldSelector>
											{formik.errors[item.FieldName] ? (
												<div className="invalid text-sm">
													{formik.errors[item.FieldName]}
												</div>
											) : null}
										</Col>
									))
								}
							</Row>
							{/* TABS */}
							<Row>
								<div className='mt-5'>
									<Tabs defaultActiveKey={1} className='nav nav-tabs'>
										<Tab tabClassName="bg-light border shadow-sm" eventKey={1} title="Rate details">
											<div className="bg-white py-4 px-2 border">
												<Row>
													<Col xs={3}>
														<b>Room Type</b>
														<hr />
														<ul className='list-group px-2'>
															{roomTypes.length > 0 && roomTypes.map((item, index) => {
																// console.log(item);
																return (
																	<li key={index} className={`list-group-item list-group-item-action d-flex justify-content-between align-items-center ${(roomTypeSelected && item.IdRoomType === roomTypeSelected.IdRoomType ? "active" : "")}`} onClick={() => {
																		selectRoomType(item);

																	}}>
																		{item.RoomTypeDesc}
																	</li>)
															})}
														</ul>
													</Col>
													<Col>
														<div className="table-responsive ">
															{roomTypeSelected ?
																<table className="table table-bordered table-striped">
																	<thead>
																		<tr>
																			<th>Guest Type</th>
																			<th>Occupation Type</th>
																			<th>Rate</th>
																			<th>Apply</th>
																		</tr>
																	</thead>
																	<tbody>

																		{roomTypeSelected.Ocupations && roomTypeSelected.Ocupations.map((item, index) => {
																			//console.log(item)
																			return (<tr key={index}>
																				<td>
																					{item.AgeGroup}
																				</td>
																				<td>
																					{item.OccupationType}
																				</td>
																				<td>
																					<input type="number" className="form-control" min="0"
																						onKeyDown={e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
																						onChange={e => {
																							updateSelectedOcupation(item, e.target.value, true);
																						}
																						}
																						value={item.rate}
																					></input>
																				</td>
																				<td>
																					<div className="form-check form-switch">
																						<input className="form-check-input" type="checkbox" id="CheckDefault4"
																							checked={item.apply}
																							onChange={e => {
																								updateSelectedOcupation(item, item.rate, e.target.checked);
																							}
																							}
																							//	onBlur={handleBlur}
																							name='RowStatus'
																						/>

																					</div>

																				</td>
																			</tr>)
																		})}


																	</tbody>
																	<tfoot>
																		<tr>
																			<td colSpan={2}>
																				<label className="me-2 mt-2">Allotments</label>
																				{/* {item.Required ? <i className="required-asterisk ms-1 text-danger">*</i> : null} */}
																				<br />

																				<FieldSelector
																					actionMode={props.actionMode}
																					model={{
																						"IdAppForm": 64,
																						"Id": 6400001,
																						"FieldName": "Allotments",
																						"FieldType": "varchar",
																						"FieldTitle": "Allotments",
																						"Position": 1,
																						"cssClasss": "col-12 col-md-4",
																						"HtmlType": "number",
																						"PlaceHolder": "",
																						"DefaultValue": "",
																						"OptionValues": null,
																						"DataSource": null,
																						"Required": false,
																						"Visible": true,
																						"ReadOnly": false
																					}}
																					//	key={index}
																					updateField={updateFieldRoomType}
																					value={roomTypeSelected.Allotments}
																				></FieldSelector>

																			</td>
																			<td colSpan={2}>
																				<label className="me-2 mt-2">Release</label>
																				{/* {item.Required ? <i className="required-asterisk ms-1 text-danger">*</i> : null} */}
																				<br />

																				<FieldSelector
																					actionMode={props.actionMode}
																					model={{
																						"IdAppForm": 64,
																						"Id": 6400001,
																						"FieldName": "Release",
																						"FieldType": "varchar",
																						"FieldTitle": "Release",
																						"Position": 1,
																						"cssClasss": "col-12 col-md-4",
																						"HtmlType": "number",
																						"PlaceHolder": "",
																						"DefaultValue": "",
																						"OptionValues": null,
																						"DataSource": null,
																						"Required": false,
																						"Visible": true,
																						"ReadOnly": false,
																						"disabled": roomTypeSelected.Allotments > 0 ? false : true
																					}}
																					//	key={index}
																					updateField={updateFieldRoomType}
																					value={roomTypeSelected.Release}
																				></FieldSelector>

																			</td>
																		</tr>
																	</tfoot>
																</table> :
																<div className='d-flex justify-content-center align-items-center w-100 h-100'>
																	<p className="h4 text-center text-muted">Please select a Room Type</p>
																</div>}

														</div>
													</Col>
												</Row>
											</div>

										</Tab>

										<Tab tabClassName={`bg-light border shadow-sm   `}
											eventKey={3} title="Special rate" >
											<RatePeriodSpecialRate assignSpecialRate={assignSpecialRate} records={record.SpecialRate ?? []} />

										</Tab>
										<Tab tabClassName={`bg-white border shadow-sm `} eventKey={4} title="Charges/discount"
										>
											<RatePeriodChargesDiscounts assignChargesDiscounts={assignChargesDiscounts} records={record.ChargesDiscount ?? []} />
										</Tab>
										<Tab tabClassName="bg-light border shadow-sm" eventKey={5} title="Rates Policies">
											<RatePeriodPolicies assignPolicies={assignPolicies} records={record.RatePolicies ?? []}></RatePeriodPolicies>
										</Tab>
									</Tabs>
								</div>
							</Row>
						</Col >

					</Row>

				</Container>
			</Modal.Body>
			<Modal.Footer>
				<div className="d-flex gx-3 align-items-center justify-content-end">
					{Object.values(formik.errors).length > 0 && (
						<span className="invalid me-2">
							Please check the forms for errors
						</span>
					)}
					<button className="btn me-2" type="button" onClick={() => props.toggle(null)}>Close</button>
					<button className="btn btn-primary me-2" type="button" onClick={() => [formik.submitForm()]}>Save</button>
				</div>


			</Modal.Footer>
		</Form >

	</Modal >)
}

export default RatePeriodDetails;
import React, { useCallback, useEffect, useState } from "react";

import FieldSelector from "./FieldSelector";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { AddBox, Search } from "@mui/icons-material";
//import { DataGrid } from "devextreme-react";
import { DataGrid, Pager, Paging, Selection } from "devextreme-react/data-grid";
import { parsedUser } from "../../utils/GetCurrentUser";
import API from "../../api/api";
import { useFormik } from "formik";
import * as yup from "yup";
import Sha256 from "../../utils/sha256";
const Maintenance = (props) => {
  //PROPERTIES
  const user = parsedUser();
  const [model, setModel] = useState(props.model);
  const defaultRecordsPerPage = 12;
  const allowedPageSizes = [8, 12, 20];
  const [showAddEdit, setShowAddEdit] = useState(false);

  //END PROPERTIES
  // UI METHODS
  const selectItem = useCallback((e) => {
    e.component.byKey(e.currentSelectedRowKeys[0]).done((item) => {});
  }, []);
  const loadUI = () => {
    let items = null;

    if (model.type === "form" || model.type === undefined) {
      items = model.Fields.map((item, index) => {
        return (
          <Col
            className={item.cssClasss + (!item.Visible ? " d-none" : "")}
            key={index}
          >
            <label className="me-2 mt-2">{item.FieldTitle}</label>
            <br />

            <FieldSelector
              model={item}
              key={index}
              updateField={updateField}
              value={formik.values[item.FieldName]}
            ></FieldSelector>
            {formik.errors[item.FieldName] ? (
              <div className="invalid text-sm">
                {formik.errors[item.FieldName]}
              </div>
            ) : null}
          </Col>
        );
      });
    } else if (model.type === "grid") {
      return (
        <DataGrid
          id="dataGrid"
          dataSource={model.records.fieldsRows}
          keyExpr="Id"
          columnAutoWidth={true}
          hoverStateEnabled={true}
          showBorders={true}
          defaultSelectedRowKeys={[1]}
          onSelectionChanged={(r) => {
            debugger;
            console.log(r);
          }}
        >
          <Selection mode="single" />
          <Paging defaultPageSize={defaultRecordsPerPage} />
          <Pager
            visible={true}
            showPageSizeSelector={true}
            allowedPageSizes={allowedPageSizes}
          />
        </DataGrid>
      );
    }

    return items;
  };
  //END UI METHODS

  //METHODS
  const toggleAddEdit = (obj, mode, reload) => {
    setShowAddEdit(!showAddEdit);
    if (reload) {
      console.log("we should reload the data if possible here");
    }
  };
  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  const handleSubmit = async (obj, event) => {
    let objSPVersion = "";
    model.Fields.forEach((item) => {
      objSPVersion +=
        (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;

      if (
        item.FieldType === "int" ||
        item.FieldType === "bool" ||
        item.FieldType === "bit" ||
        item.FieldType === "bigint"
      ) {
        objSPVersion +=
          obj[item.FieldName] === undefined ? "null" : obj[item.FieldName];
      } else if (item.HtmlType === "password") {
        let passwordEncrypted =
          obj["LoginName"].toUpperCase() + obj[item.FieldName];
        passwordEncrypted = Sha256.hash(passwordEncrypted);
        objSPVersion +=
          obj[item.FieldName] === undefined
            ? "null"
            : "'" + passwordEncrypted + "'";
      } else {
        objSPVersion +=
          obj[item.FieldName] === undefined
            ? "null"
            : "'" + obj[item.FieldName] + "'";
      }
    });

    let queryString = `Id=${props.section}&Token=${user.Token}&ActionMode=${props.actionMode}`;
    let queryData = {
      Data: objSPVersion,
    };

    let requestSections = await API.postAction(
      "api/CRUD/AddEdit?" + queryString,
      queryData
    );

    toggleAddEdit(null, true);
  };

  const yupTypeValidator = (item) => {
    if (item.FieldType === "string" || item.FieldType === "varchar") {
      return yup.string().label(item.FieldTitle).required();
    } else if (item.FieldType === "bool" || item.FieldType === "bit") {
      return yup.boolean().label(item.FieldTitle).required();
    } else if (item.FieldType === "int" || item.FieldType === "bigint") {
      return yup.number().label(item.FieldTitle).required();
    } else if (item.FieldType === "date") {
      return yup.date().label(item.FieldTitle).required();
    }
  };

  const [validationsSchema, setValidationSchema] = useState(null);
  const prepareFormikObject = () => {
    let initialObject = {};
    let initialObjectValidation = {};
    model.Fields.forEach((item) => {
      initialObject[item.FieldName] = item.value;
      if (item.Required) {
        initialObjectValidation[item.FieldName] = yupTypeValidator(item);
      }
    });
    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  //END METHODS

  //EVENTS
  useEffect(() => {
    prepareFormikObject();
  }, []);
  //END EVENTS

  return (
    <>
      <Modal
        show={showAddEdit}
        onHide={toggleAddEdit}
        size="lg"
        className="modalRight"
      >
        <Modal.Header closeButton>
          <Modal.Title>{model.title}</Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Row>
              {model.Fields.map((item, index) => {
                return (
                  <Col className={item.cssClasss} key={index}>
                    <label className="me-2 mt-2">{item.FieldTitle}</label>
                    <br />
                    <FieldSelector
                      model={item}
                      updateField={updateField}
                    ></FieldSelector>
                  </Col>
                );
              })}
            </Row>
          </Modal.Body>
          <Modal.Footer className="p-1">
            <button className="btn btn-secondary">Close</button>
            <button className="btn btn-primary me-2">Save Changes</button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs={6}>{/* <h5>{model.title}</h5> */}</Col>
          <Col xs={6} className="text-end">
            {model.type === "grid" ? (
              <>
                <button className="btn">
                  <Search />
                </button>
                <button className="btn" onClick={() => toggleAddEdit(null)}>
                  <AddBox />
                </button>
              </>
            ) : null}
          </Col>
          <Col xs={12}>
            <Row className="resultContainer p-2">{loadUI()}</Row>
          </Col>

          <Col xs={12} className="text-end mt-4">
            {Object.values(formik.errors).length > 0 ? (
              <span className="invalid me-2">
                Please check the forms for errors
              </span>
            ) : null}
            <button className="btn btn-primary me-2" type="submit">
              Submit
            </button>
            <button
              className="btn btn-secondary"
              type="button"
              onClick={() => toggleAddEdit()}
            >
              Close
            </button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Maintenance;

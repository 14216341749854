import React from "react";
import { actionsModel } from "../../enum/actionsModel.enum";


const ColumnsModelsSettings = ({ children, actionSelected }) => {
    const columns = [
        // {
        //     title: "Date Add",
        //     render({ data }) {
        //         return (
        //             <span>{new Date(data.DateAdd).toLocaleDateString()}</span>
        //         )
        //     }
        // },
        {
            title: "Actions",
            render: ({ data }) => {
                return (
                    <div className="d-flex">
                        <button type="button" onClick={() => actionSelected(actionsModel.EDIT, data)} className="d-flex btn btn-outline-primary">
                            <i className="fas fa-pen"></i>
                        </button>
                        <button type="button" onClick={() => actionSelected(actionsModel.DELETE, data)} className="btn btn-outline-danger d-flex mx-1 ">
                            <i className="fas fa-trash"></i>
                        </button>
                    </div>

                )
            }
        },
        {
            title: "Field Name",
            render({ data }) {
                return (
                    <span>{data.FieldName}</span>
                )
            }
        },
        {
            title: "Field Title",
            render({ data }) {
                return (
                    <span>{data.FieldTitle}</span>
                );
            }
        },

        {
            title: "Field Type",
            render: ({ data }) => {
                return <span>{data?.FieldType}</span>;
            }
        },
        {
            title: "Html Type",
            render: ({ data }) => {
                return <span>{data?.HtmlType}</span>;
            }
        },
        {
            title: "ClasssName",
            render: ({ data }) => {
                return <span className="text-nowrap">{data?.cssClasss}</span>;
            }
        },
        // {
        //     title: "Id",
        //     render: ({ data }) => {
        //         return <span>{data?.Id}</span>;
        //     }
        // },
        {
            title: "Id App Form",
            render: ({ data }) => {
                return <span>{data?.IdAppForm}</span>;
            }
        },
        // {
        //     title: "Id user Add",
        //     render: ({ data }) => {
        //         return <span>{data?.IdUserAdd}</span>;
        //     }
        // },
        {
            title: "Position",
            render: ({ data }) => {
                return <span className="text-nowrap">{data?.Position}</span>;
            }
        },
        {
            title: "Placehorder",
            render: ({ data }) => {
                return <span className="text-nowrap">{data?.Placehorder || 'N/A'}</span>;
            }
        },
        {
            title: "ReadOnly",
            render: ({ data }) => {
                return (
                    <span className={`badge  ${data?.ReadOnly ? 'bg-primary' : 'bg-secondary'} `}>
                        {data?.ReadOnly ? 'True' : 'False'}
                    </span>

                )
            }
        },
        {
            title: "Required",
            render: ({ data }) => {
                return (
                    <span className={`badge  ${data?.Required ? 'bg-primary' : 'bg-secondary'} `}>
                        {data?.Required ? 'True' : 'False'}
                    </span>

                )
            }
        },
        {
            title: "Visible",
            render: ({ data }) => {
                return (
                    <span className={`badge  ${data?.Visible ? 'bg-primary' : 'bg-secondary'} `}>
                        {data?.Visible ? 'True' : 'False'}
                    </span>

                )
            }
        },
        {
            title: "RowStatus",
            render: ({ data }) => {
                return (
                    <span className={`badge  ${data?.RowStatus ? 'bg-primary' : 'bg-danger'} `}>
                        {data?.RowStatus ? 'True' : 'False'}
                    </span>

                )
            }
        },
        // {
        //     title: "GUID",
        //     render: ({ data }) => {
        //         return <span className="text-nowrap">{data?.GUID}</span>;
        //     }
        // },


    ]
    return children({ columns });
}
export default ColumnsModelsSettings;
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useToast } from '../contexts/toast';
import { parsedUser } from '../utils/GetCurrentUser';
import { db } from '../utils/db';

const useFavorite = () => {

  const user = parsedUser();
  const toast = useToast();
  const { pathname } = useLocation();
  const [isPinned, setIsPinned] = useState(false);

  const checkIfPinned = async () => {

    try {
      await db.favorites.where("username").equals(user.Username).toArray((item) => {
        if (item.length > 0) {
          const favExists = item[0].values.some((fav) => fav.path === pathname);
          setIsPinned(favExists)
        }
      });
      console.log('isPinned', isPinned)
    } catch (error) {
      console.error(error);
    }
  }

  const UpdateFavorites = async ({
    icon,
    path,
    formPath,
    title }) => {

    const newFavorite = {
      icon,
      path,
      formPath,
      title,
    }

    try {
      await db.favorites.where("username").equals(user.Username).modify((item) => {

        const favExists = item.values.some((fav) => fav.path === newFavorite.path);

        if (favExists) {
          setIsPinned(false)
          item.values = item.values.filter((fav) => fav.path !== newFavorite.path);
          toast({
            type: "warning",
            message: "Removed from favorites",
          });
          return;
        }

        setIsPinned(true)
        item.values.push(newFavorite);
        toast({
          type: "success",
          message: "Added to favorites",
        });
      });
    } catch (error) {
      console.error(error)
    }
  }

  return {
    checkIfPinned,
    isPinned,
    UpdateFavorites,
  }
}

export default useFavorite
import { Field, Formik } from 'formik'
import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import * as yup from 'yup'
import { actionsModel } from '../../enum/actionsModel.enum'
import FieldSelector from '../customComponents/FieldSelector'
import ContractFields from '../contracts/ContractFields'
import { useFormik } from "formik";
import CustomerFields from './customerFields'
import { TabsGroup } from '../tabs-group'
import CommentHistory from './submodules/commentHistory'
import BusinnesUnit from './submodules/bussinesUnit'
import Contactperson from './submodules/contactPerson'
import Files from './submodules/files'
import CommentHistoryTab from '../contracts/CommentHistoryTab'

const CustomerForm = ({ setShow, data, action, clearSelection }) => {
    const [comments, setComments] = useState([]);
    const { EDIT, VIEW } = actionsModel;



    const formik = useFormik({
        initialValues: {},
 
    });
    // INITIAL VALUES FORMIK
    const initialValues = {
        Name: action === EDIT || VIEW ? data?.name : '',
        Address1: action === EDIT || VIEW ? data?.Address1 : '',
        Address2: action === EDIT || VIEW ? data?.Address2 : '',
        City: action === EDIT || VIEW ? data?.City : '',
        Zip_Code: action === EDIT || VIEW ? data?.Zip_Code : '',
        WebSite: action === EDIT || VIEW ? data?.WebSite : '',
    }

    // validation schema
    const validation = yup.object().shape({
        Name: yup.string().required('Required'),
        Address1: yup.string().required('Required'),
        Address2: yup.string().required('Required'),
        City: yup.string().required('Required'),
        Zip_Code: yup.string().required('Required'),
        WebSite: yup.string().required('Required'),
    })

    // SUBMIT FORM
    const submit = (values) => {
        console.log(values)
        clearSelection(null);
    }

    // CLOSE FORM
    const handleClose = () => {
        // setActionMode(null);
        clearSelection(null);
        setShow(false);
    }
    const updateField = (fieldName, fieldValue) => {
        formik.setFieldValue(fieldName, fieldValue);
    };
    console.log(data)
    return (
        <div className='mx-3'>
            <Row>
                <Card>
                    <Card.Body>

                        <Row className='mb-2'>
                            {
                                CustomerFields.map((item, index) => (
                                    <Col md={3} key={index}>
                                        <label className="me-2 mt-2">{item.FieldTitle}</label>
                                        <br />
                                        <FieldSelector
                                            model={item}
                                            updateField={updateField}
                                            data={data}
                                            action={action}
                                        />
                                    </Col>
                                ))

                            }
                        </Row>
                    </Card.Body>
                </Card>

            </Row>

            <Row>
                <div className='mt-3'>
                    <div>
                        <div className='mb-4'>
                            <TabsGroup
                                tabList={[
                                    {
                                        title: 'Comment History',
                                        eventKey: 'comment',
                                        content: <CommentHistoryTab ParentState={data} ChildState={(data) => setComments(data)} />
                                    },
                                    {
                                        title: 'Bussines Units',
                                        eventKey: 'business',
                                        content: <BusinnesUnit />
                                    },
                                    {
                                        title: 'Contact Person',
                                        eventKey: 'contact',
                                        content: <Contactperson />
                                    },
                                    {
                                        title: 'Files',
                                        eventKey: 'files',
                                        content: <Files />
                                    },

                                ]} />
                        </div>
                    </div>
                </div>

            </Row>
            <Row>
                <Card className='mt-2'>
                    <Card.Body className='p-2'>
                        <Row className=''>
                            <div className="d-flex gx-3 align-items-center justify-content-end">
                                <button className="btn me-2" type={'button'} onClick={handleClose}>Cancel</button>
                                <button className="btn btn-primary me-2" type={'submit'}>Save</button>
                            </div>
                        </Row>
                    </Card.Body>
                </Card>

            </Row>

        </div>)
}

export default CustomerForm
import {

  CheckinPage,
  ClientsPage,
  ContractsV2,
  HomePage,
  ProfilePage,
  TasksPage,
  UserRolesPage,
  UsersPage,
  modelManagement,
} from "../pages";
import CRUDTest from "../pages/crud/ CRUDTest";
import CRUDContractManager from "../pages/contractsManager/CRUDContractManager";
import CRUDMaster from "../pages/crud/CRUDMaster";
import CRUDRateManager from "../pages/crud/CRUDRateManager";
import CRUDSimple from "../pages/crud/CRUDSimple";

import Login from "../pages/login/login";
import RestaurantReservationsPage from "../pages/restaurantReservations/RestaurantReservationsPage";
import CustomerPage from "../pages/customer/customer";

const ROUTES = {
  WithSidebar: [
    {
      path: "/restaurantReservations",
      element: RestaurantReservationsPage,
      clean: "/restaurantReservations",
    },
    {
      path: "/tasks",
      element: TasksPage,
      clean: "/tasks",
    },
    {
      path: "/checkin",
      element: CheckinPage,
      clean: "/checkin",
    },
    {
      path: "/profile",
      element: ProfilePage,
      clean: "/profile",
    },
    {
      path: "/users",
      element: UsersPage,
      clean: "/users",
    },
    {
      path: "/clients",
      element: ClientsPage,
      clean: "/clients",
    },
    {
      path: "/userroles",
      element: UserRolesPage,
      clean: "/userroles",
    },

    {
      path: "/crud/:id",
      element: CRUDSimple,
      clean: "/crud/",
    },
    {
      path: "/crudDynamic/:id/:idModule",
      element: CRUDSimple,
      clean: "/crudDynamic/",
    },
    {
      path: "/process/ContractsManager",
      element: CRUDContractManager,
      clean: "/process/ContractsManager",
    },
    {
      path: "/crudm/:id",
      element: CRUDMaster,
      clean: "/crudm/",
    },
    {
      path: "/process/RateManager",
      element: CRUDRateManager,
      clean: "/process/RateManager",
    },
    {
      path: "/process/contractV2",
      element: ContractsV2,
      clean: "/process/contractV2",
    },
    {
      path: "/process/customer",
      element: CustomerPage,
      clean: "/process/customer",
    },
    {
      path: "/test",
      element: CRUDTest,
      clean: "/test",
    },
    {
      path: "/modelManagement",
      element: modelManagement,
      clean: "/modelManagement",
    },
  ],

  Default: {
    Path: "login",
    Clean: "/login",
    Page: Login,
  },
  DefaultWithSideBar: {
    Path: "dashboard",
    Clean: "/dashboard",
    Page: HomePage,
  },
};

export default ROUTES;

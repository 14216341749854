import React, { useCallback, useEffect, useRef, useState } from "react";
import TextBox from "devextreme-react/text-box";

import { DateBox, List, NumberBox, RadioGroup, SelectBox, TextArea } from "devextreme-react";
import moment from "moment/moment";
import API from "../../api/api";
import { parsedUser } from "../../utils/GetCurrentUser";

const FieldSelector = (props) => {
  const model = props.model;

  const [items, setItems] = useState([]);
  const dropDownBoxRef = useRef(null);
  const changeDropDownBoxValue = useCallback((arg) => {
    let val = arg.addedItems[0];
    if (val) {
      props.updateField(model.FieldName, val.id);
    }
    dropDownBoxRef.current.instance.close();
  }, []);

  useEffect(() => {

    if (!props.model) {
      return
    }
    if (model.DataSource) {
      console.log(model, model.DataSource);
      if (model.DataSource !== "NULL") {
        let dataSource = JSON.parse(model.DataSource);
        requestRecords(dataSource.HashID);
      } else {
        console.log(model, model.DataSource);

      }
    }
  }, []);

  //REQUEST RECORDS
  const requestRecords = async (id) => {
    try {
      const user = parsedUser();
      let query = `token=${user.Token}&id=${id}`;
      var request = await API.getAction("api/selector", query);
      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];

      if (parsedResults.Error) {
        console.error(parsedResults.Msg);
        return;
      }
      let recordResult = JSON.parse(parsedResults.JSONData);
      if (recordResult) {
        let parsedItems = recordResult.map((item) => ({
          id: item.Id,
          text: item.Description,
        }));

        setItems(parsedItems);
      }
    } catch (error) {
      console.error(error);
    }
  };
  if (!props.model) {
    return
  }
  let defaultValue = props.model.DefaultValue === '0' ? 0 : props.model.DefaultValue === '1' ? 1 : props.model.DefaultValue === true ? 1 : props.model.DefaultValue === false ? 0 : props.model.DefaultValue;

  switch (model.HtmlType) {
    case "email":
    case "text":
    case "file":

      return (
        <TextBox
          // className="form-control "
          className={`${(props.actionMode === 'C' || model.ReadOnly) ? "form-read-only" : ""}`}
          readOnly={(props.actionMode === 'C' || model.ReadOnly) ? true : false}
          id={model.FieldName}
          name={model.FieldName}
          mode={model.HtmlType}
          placeholder={model.PlaceHolder}
          required={model.Required}
          inputAttr={{ "autocomplete": "off" }}
          value={props.value ?? ""}
          onValueChanged={(e) => {
            props.updateField(model.FieldName, e.value);
          }}
        />
      );
    case "number":
      return (
        <NumberBox
          disabled={model?.disabled}
          showSpinButtons={true}
          className={`${(props.actionMode === 'C' || model.ReadOnly) ? "form-read-only" : ""}`}
          readOnly={(props.actionMode === 'C' || model.ReadOnly) ? true : false}
          format={'#0.##'}
          id={model.FieldName}
          name={model.FieldName}
          mode={model.HtmlType}
          placeholder={model.PlaceHolder}
          required={model.Required}
          inputAttr={{ "autocomplete": "off" }}
          value={props.value}
          onValueChanged={(e) => {
            props.updateField(model.FieldName, e.value);
          }}
          min={0}
        />
      )


    case "date":
      let dateValue = props.value === "Invalid date" ? "" : props.value;
      console.log(dateValue);
      return (
        <DateBox
          className={`${(props.actionMode === 'C' || model.ReadOnly) ? "form-read-only" : ""}`}
          readOnly={(props.actionMode === 'C' || model.ReadOnly) ? true : false}
          id={model.FieldName}
          name={model.FieldName}
          placeholder={model.PlaceHolder}
          required={model.Required}
          value={dateValue ?? ""}
          onValueChanged={(e) => {
            let format = moment(e.value).format("YYYY-MM-DD");
            props.updateField(model.FieldName, format);
          }}
        />
      );

    case "password":

      return (
        <TextBox
          // className="form-control "
          id={model.FieldName}
          className={`${(props.actionMode === 'C' || props.actionMode === "U" || model.ReadOnly) ? "form-read-only" : ""}`}
          readOnly={(props.actionMode === 'C' || props.actionMode === "U" || model.ReadOnly) ? true : false}
          mode="password"
          name={model.FieldName}
          type={model.HtmlType}
          placeholder={model.PlaceHolder}
          required={model.Required}
          inputAttr={{ "autocomplete": "new-password" }}
          value={props.actionMode === "D" ? "" : props.value}
          onValueChanged={(e) => {
            props.updateField(model.FieldName, e.value);
          }}
        />
      )

    case "textarea":
      return (
        <TextArea
          // className="form-control "
          id={model.FieldName}
          className={`${(props.actionMode === 'C' || model.ReadOnly) ? "form-read-only" : ""}`}
          readOnly={(props.actionMode === 'C' || model.ReadOnly) ? true : false}
          name={model.FieldName}
          type={model.HtmlType}
          placeholder={model.PlaceHolder}
          required={model.Required}
          value={props.value}
          onValueChanged={(e) => {
            props.updateField(model.FieldName, e.value);
          }}
        />
      );

    case "select":
      return (

        <SelectBox dataSource={items}
          displayExpr="text"
          searchEnabled={true}
          valueExpr={"id"}
          inputAttr={{ 'aria-label': model.FieldName }}
          searchMode={'contains'}
          showDataBeforeSearch={true}
          searchExpr={'text'}
          searchTimeout={200}
          minSearchLength={1}
          showClearButton={true}
          className={`${(props.actionMode === 'C' || model.ReadOnly) ? "form-read-only" : ""}`}
          readOnly={(props.actionMode === 'C' || model.ReadOnly) ? true : false}
          id={model.FieldName}
          onValueChanged={(e) => {
            props.updateField(model.FieldName, e.value);
          }}
          name={model.FieldName}
          value={props.value}
        />
      );
    //This return the entire object instead of just the value
    case "selectobj":
      return (

        <SelectBox dataSource={items}
          displayExpr="text"
          searchEnabled={true}
          valueExpr={"id"}
          inputAttr={{ 'aria-label': model.FieldName }}
          searchMode={'contains'}
          showDataBeforeSearch={true}
          searchExpr={'text'}
          searchTimeout={200}
          minSearchLength={1}
          showClearButton={true}
          className={`${(props.actionMode === 'C' || model.ReadOnly) ? "form-read-only" : ""}`}
          readOnly={(props.actionMode === 'C' || model.ReadOnly) ? true : false}
          id={model.FieldName}
          onValueChanged={(e) => {
            //Value to evaluate 
            let val = e.value && e.value.id ? e.value.id : e.value;
            let record = items.filter(x => x.id === val)[0];
            console.log(e.value, items, record);

            props.updateField(model.FieldName, record);
          }}
          name={model.FieldName}
          value={props.value && props.value.id ? props.value.id : props.value}
        />
      );

    case "radio":
      let radioDefaultValue = typeof defaultValue === 'string' ? +defaultValue.replace(/['"{}]+/g, '') : 0;
      let options = model.OptionValues ? JSON.parse(model.OptionValues) : null;
      let radioValue = +props.value >= 0 ? +props.value : radioDefaultValue;
      return (
        <RadioGroup
          className={`${(props.actionMode === 'C' || model.ReadOnly) ? "form-read-only" : ""}`}
          // defaultValue={radioDefaultValue}
          readOnly={(props.actionMode === 'C' || model.ReadOnly) ? true : false}
          dataSource={options}
          value={radioValue}
          layout="horizontal"
          valueExpr={"id"}
          displayExpr={"text"}
          onInitialized={() => {
            if (props.value === null || props.value === undefined) props.updateField(model.FieldName, radioValue);
          }}
          onValueChanged={(e) => {
            let valueToAdd = e.value.id ? e.value.id : e.value;
            props.updateField(model.FieldName, valueToAdd);
          }}
        />
      );

    case "radioobj":
      let radioDefaultValueObj = typeof defaultValue === 'string' ? +defaultValue.replace(/['"{}]+/g, '') : 0;
      let optionsObj = model.OptionValues ? JSON.parse(model.OptionValues) : null;
      let radioValueObj = +props.value >= 0 ? +props.value : radioDefaultValueObj
      console.log(radioValueObj)
      return (
        <RadioGroup
          className={`${(props.actionMode === 'C' || model.ReadOnly) ? "form-read-only" : ""}`}
          // defaultValue={radioDefaultValue}
          readOnly={(props.actionMode === 'C' || model.ReadOnly) ? true : false}
          dataSource={optionsObj}
          value={radioValueObj}
          layout="horizontal"
          valueExpr={"id"}
          displayExpr={"text"}
          onInitialized={() => {
            if (props.value === null || props.value === undefined) props.updateField(model.FieldName, radioValueObj);
          }}
          onValueChanged={(e) => {
            let valueToAdd = e.value;

            let val = e.value && e.value.id ? e.value.id : e.value;
            let record = optionsObj.filter(x => x.id === val)[0];
            console.log(valueToAdd, optionsObj, record)
            props.updateField(model.FieldName, record);
          }}
        />
      );
    case "separator":

      return (
        <hr className="separatorLine" />
      );

    default:
      return <></>

  }
};

export default FieldSelector;

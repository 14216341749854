import { Formik } from 'formik'
import React from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import * as yup from 'yup'
import { actionsModel } from '../../enum/actionsModel.enum'
import API from '../../api/api'
import { parsedUser } from '../../utils/GetCurrentUser'
import FieldSelector from '../customComponents/FieldSelector'

const ModalSample = (props) => {

    const updateField = (name, value) => {

    }
    return (
        <Modal
            show={props.show}
            onHide={props.toggle}
            backdrop="static"
            className='modalRight'
            size="md">

            <Modal.Header closeButton>
                <Modal.Title>Sample Model </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <Row>
                        {props.data &&
                            props.data.map((item, index) => {
                                return (
                                    <Col
                                        className={
                                            item.cssClasss + (!item.Visible ? " d-none" : "")
                                        }
                                        key={index}
                                    >
                                        <label className={item.HtmlType === 'separator' ? `me-2 ${(index !== 0 ? "mt-4 pt-4" : "mt-2")}  separatorTitle` : "me-2 mt-2"}>{item.FieldTitle}
                                            {item.Required ? <i className="required-asterisk ms-1 text-danger">*</i> : null}
                                        </label>
                                        <br />
                                        {
                                            // console.log("item", item, "formik", formik.values[item.FieldName])
                                        }
                                        <FieldSelector
                                            //   actionMode={actionMode}
                                            model={item}
                                            key={index}
                                            // limit longitudes
                                            updateField={updateField}
                                        //   value={formik.values[item.FieldName]}
                                        ></FieldSelector>
                                        {/* {formik.errors[item.FieldName] ? (
                          <div className="invalid text-sm">
                            {formik.errors[item.FieldName]}
                          </div>
                        ) : null} */}
                                    </Col>
                                );
                            })}
                    </Row>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Row className='mt-2'>
                    <Col md={12} xs={12} className=' d-flex  justify-content-end'>
                        <button className='btn btn-primary me-2' type='submit'>Save Changes</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalSample
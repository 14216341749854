const CustomerFields = [
    {
        "IdAppForm": 62,
        "Id": 6200010,
        "FieldName": "Name",
        "FieldType": "bigint",
        "FieldTitle": "Name",
        "Position": 1,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "text",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": null,
        "Required": false,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "IdAppForm": 19,
        "Id": 1901003,
        "FieldName": "IdCustomerType",
        "FieldType": "bigint",
        "FieldTitle": "Customer Type",
        "Position": 2,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "select",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": null,
        "Required": false,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "IdAppForm": 62,
        "Id": 6200010,
        "FieldName": "IdCountry",
        "FieldType": "bigint",
        "FieldTitle": "Country",
        "Position": 3,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "select",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": null,
        "Required": false,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "IdAppForm": 62,
        "Id": 6200010,
        "FieldName": "IdParent",
        "FieldType": "bigint",
        "FieldTitle": "Parent",
        "Position": 4,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "select",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": null,
        "Required": false,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "IdAppForm": 62,
        "Id": 6200010,
        "FieldName": "IdCountryState",
        "FieldType": "bigint",
        "FieldTitle": "State",
        "Position": 5,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "select",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": null,
        "Required": false,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "IdAppForm": 62,
        "Id": 6200010,
        "FieldName": "Address1",
        "FieldType": "bigint",
        "FieldTitle": "Address 1",
        "Position": 6,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "text",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": null,
        "Required": false,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "IdAppForm": 62,
        "Id": 6200010,
        "FieldName": "Address2",
        "FieldType": "bigint",
        "FieldTitle": "Address 2",
        "Position": 7,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "text",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": null,
        "Required": false,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "IdAppForm": 62,
        "Id": 6200010,
        "FieldName": "City",
        "FieldType": "bigint",
        "FieldTitle": "City",
        "Position": 8,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "text",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": null,
        "Required": false,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "IdAppForm": 62,
        "Id": 6200010,
        "FieldName": "ZipCode",
        "FieldType": "bigint",
        "FieldTitle": "Zip Code",
        "Position": 9,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "text",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": null,
        "Required": false,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "IdAppForm": 62,
        "Id": 6200010,
        "FieldName": "WebSite",
        "FieldType": "bigint",
        "FieldTitle": "Web Site",
        "Position": 10,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "text",
        "PlaceHolder": null,
        "DefaultValue": null,
        "OptionValues": null,
        "DataSource": null,
        "Required": false,
        "Visible": true,
        "ReadOnly": false
    },


]

export default CustomerFields;
import { DataGrid } from 'devextreme-react';
import { Column, Selection } from 'devextreme-react/data-grid';
import { useFormik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Container, Form, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import FieldSelector from '../customComponents/FieldSelector';
import RatePeriodDetails from './tabs/RatePeriodDetails';

import { confirmAlert } from 'react-confirm-alert';
import { parsedUser } from '../../utils/GetCurrentUser';
import API from '../../api/api';
import CRUDPageGRIDCustom from '../../pages/crud/CRUDPageGridCustom';
import { useToast } from "../../contexts/toast";

import { v4 as uuidv4 } from "uuid"
import Sha256 from '../../utils/sha256';
import RatePeriodFields from './RatePeriodFields';
import ratePeriodSpecialRateFields from './RatePeriodSpecialRateFields';

const RatePeriod = (props) => {
    const user = parsedUser();
    const [records, setRecords] = useState({
        Columns: [],
        Rows: [],
    })

    const [generalError, setGeneralError] = useState(null);
    const [recordId, setRecordId] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const selectedItemRef = useRef(null);
    const [columsSelected, setColumsSelected] = useState(null);
    const toast = useToast();
    const [ratePeriod, setRatePeriod] = useState(
        {
            // father
            contractID: "",
            code: "",
            RateDescription: "",
            startDate: "",
            endDate: "",

            // children
            ratePeriodDetails: {},
            rateType: {},
            specialRate: {},
            discount: {},
            policies: {}
        }
    );

    const [businessUnitAvailable, setBusinessUnitAvailable] = useState([]);

    const updateField = (fieldName, fieldValue) => {
        formik.setFieldValue(fieldName, fieldValue);
        setRatePeriod({ ...ratePeriod, [fieldName]: fieldValue });
    };

    // RATE PERIOD

    // END RATE PERIOD

    // BUSINESS UNIT ASSIGNED
    const [businessUnitRecords, setBusinessUnitRecords] = useState([]);
    const bindDataRemoteBusinessUnit = async (cleanFilter) => {


        try {
            let id = 'be60cb6ae9e3b5bfc7eb84cb4127a5f0b071e40ad1a9dd2dc20035c778f507ef';
            let query = `token=${user.Token}&id=${id}`;

            var request = await API.getAction("api/selector", query);
            let results = request.data[0];

            let parsedResults = JSON.parse(results.JSONData)[0];

            if (parsedResults.Error) {
                console.error(parsedResults.Msg);
                return;
            }
            let recordResult = JSON.parse(parsedResults.JSONData);
            if (recordResult) {
                let parsedItems = recordResult.map((item) => ({
                    id: item.Id,
                    text: item.Description,
                }));

                setBusinessUnitRecords(parsedItems);
            }

        } catch (error) {
            console.error(error);
        } finally {
            // setIsLoading(false);
        }
    };
    const bindDataRemoteBusinessUnitAssigned = async (cleanFilter) => {


        try {

            let query = `token=${user.Token}&ParentGUID=${props.ParentGUID}`;

            var request = await API.getAction("api/contractsManager/BusinessUnitRecords", query);
            let results = request.data[0];
            let parsedResults = JSON.parse(results.JSONData)[0];

            if (parsedResults.Error) {
                console.error(parsedResults.Msg);
                return;
            }
            if (parsedResults.JSONData === null) {
                //Came null 
                console.log("came null", request);
                setBusinessUnitAvailable([])
                return;
            }


            let recordResult = parsedResults.JSONData;

            if (recordResult) {

                let parsedItems = recordResult.map((item) => ({
                    id: item.IdBusinessUnit,
                    text: item.BusinessUnit,
                    guid: item.GUID
                }));

                setBusinessUnitAvailable(parsedItems);
            }

        } catch (error) {
            console.error(error);
        } finally {
            // setIsLoading(false);
        }
    };
    const [businessUnitSelected, setBusinessUnitSelected] = useState(null);
    // END BUSINESS UNIT ASSIGNED


    const [rateRecords, setRateRecords] = useState([
        

    ]
    )


    const selectItem = useCallback((e) => {
        console.log(e)
        selectedItemRef.current = e.component;
        e.component.byKey(e.currentSelectedRowKeys[0]).done((item) => {
            setColumsSelected(item);
        });
    }, []);


    const deleteConfirm = async (obj) => {
        confirmAlert({
            message: "Are you sure you want to delete this record?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        const newRows = rateRecords.filter((item) => item !== obj);
                        setRateRecords(newRows);
                        setColumsSelected(null);
                        
                        let query = `token=${user.Token}&ParentGUID=${props.ParentGUID}&RecordId=${obj.GUID}`;
                        await API.getAction("api/ContractsManager/RatePeriodDelete", query)
                        await bindDataRemoteRatePeriod();
                    },
                },
                { label: "No" },
            ],
        });
    };
   
    const removeBusinessUnit = async (obj) => {
        confirmAlert({
            message: "Are you sure you want to delete this record?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {

                        let query = `token=${user.Token}&ParentGUID=${props.ParentGUID}&RecordId=${obj.guid}`;
                        await API.getAction("api/ContractsManager/BusinessUnitDelete", query)
                        await bindDataRemoteBusinessUnitAssigned();
                    },
                },
                { label: "No" },
            ],
        });
    };


    const [showAddEdit, setShowAddEdit] = useState(false);
    const [actionMode, setActionMode] = useState(null);//I=Insert, U= Update = C= Consultar, D= Delete
    const toggle = async (action, reload) => {
        setActionMode(action);
        setShowAddEdit(!showAddEdit);

        if (action === null) {
            setSelectedItem(null);
            formik.resetForm();
        }

        if (action === "I") {

            setRecordId(uuidv4());
            setSelectedItem(null)
        }
        if (action === "U" || action === "C" || action === "D") {
            //  await bindDataDetailsRemote(selectedItem.Id);
        }
        if (reload) {
            await bindDataRemoteRatePeriod();
        }

    }



    //This method is to save the record we are working on
    const handleSubmit = async (obj, event) => {

        let actions = {
            "I": "I",
            "U": "U",
            "D": "I"
        };

        if (actionMode === "D") {
            const { GUID, Id, ...rest } = obj;
            obj = rest;
        }


        confirmAlert({
            message: "Are you sure you want to save this record?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        console.log("Fields to evaluate", fields)

                        let objSPVersion = "";
                        fields.forEach((item) => {

                            if (item.HtmlType !== "separator") {
                                objSPVersion +=
                                    (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
                            }
                            if (
                                item.FieldType === "int" ||
                                item.FieldType === "bool" ||
                                item.FieldType === "bit" ||
                                item.FieldType === "bigint"
                            ) {
                                objSPVersion +=
                                    obj[item.FieldName] === undefined || obj[item.FieldName] === null
                                        ? item.DefaultValue && item.DefaultValue.length > 0 ? item.DefaultValue : "null"
                                        : "'" + obj[item.FieldName] + "'";
                                //objSPVersion +=
                                //obj[item.FieldName] === undefined ? "null" : obj[item.FieldName];
                            } else if (item.HtmlType === "password") {
                                let passwordEncrypted = null;
                                if (obj[item.FieldName] && obj[item.FieldName].length > 0) {
                                    passwordEncrypted =
                                        obj["UserName"].toUpperCase() + obj[item.FieldName];
                                    passwordEncrypted = Sha256.hash(passwordEncrypted);
                                    ;
                                }
                                objSPVersion +=
                                    obj[item.FieldName] === undefined
                                        ? "null"
                                        : "'" + passwordEncrypted + "'"
                            } else
                                if (item.HtmlType === 'radio') {

                                    objSPVersion +=
                                        obj[item.FieldName] === undefined || obj[item.FieldName] === null
                                            ? item.DefaultValue && item.DefaultValue.length > 0 ? item.DefaultValue : "null"
                                            : "'" + obj[item.FieldName] + "'";


                                } else if (item.HtmlType !== 'separator') {
                                    objSPVersion +=
                                        obj[item.FieldName] === undefined || obj[item.FieldName] === null
                                            ? "null"
                                            : "'" + obj[item.FieldName] + "'";
                                }
                        });

                        objSPVersion += ",@ParentGUID='" + props.ParentGUID + "'";
                        objSPVersion += ",@ChildGUID='" + businessUnitSelected.guid + "'";
                        objSPVersion += ",@IdBusinessUnit=" + businessUnitSelected.id + "";
                        console.log("❗ ~ file: RatePeriod.js:322 ~ onClick: ~ action:", actions[actionMode])
                        let queryString = `Token=${user.Token}&ActionMode=${actions[actionMode]}`;
                        let queryData = {
                            Data: objSPVersion,
                        };

                        let request = await API.postAction(
                            "api/ContractsManager/RatePeriodAddEdit?" + queryString,
                            queryData
                        );


                        if (request.status === 200) {
                            let response = JSON.parse(request.data[0].JSONData)[0];
                            if (response.Error) {
                                console.error(response.Error);
                                setGeneralError(response.Msg);
                                return;
                            }
                        }
                        // setGeneralError(null);
                        setSelectedItem(null);

                        formik.resetForm();

                        toast({
                            type: "success",
                            "message": "Record saved successfully",
                        })

                        // if (actionMode === 'I') {
                        //   return;
                        // }
                        toggle(null, true);
                    },
                },
                {
                    label: "No",
                },
            ],
        });


    };
    const [roomSelected, setRoomSelected] = useState(null);
    const roomSelection =
        ({ itemData, event, node }) => {
            console.log("item data", itemData);
            setRoomSelected(itemData);

        };

    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        onSubmit: handleSubmit,

        //enableReinitialize: true,

        validateOnChange: false,
        validateOnBlur: false,

        //validationSchema: validationsSchema,
        //onSubmit: handleSubmit,
    });


    const [selectedBusinessUnitToAssign, setSelectedBusinessUnitToAssign] = useState(null)
    const assignBusinessUnit = async () => {
        if (selectedBusinessUnitToAssign === null) {
            return;
        }
        let objSPVersion = '@IdBusinessUnit= ' + selectedBusinessUnitToAssign;
        let queryString = `Token=${user.Token}&ActionMode=${'I'}`;
        objSPVersion += `, @ParentGUID='${props.ParentGUID}'`;
        let queryData = {
            Data: objSPVersion,
        };

        await API.postAction(
            'api/ContractsManager/BusinessUnitAddEdit' + "?" + queryString,
            queryData
        );
        await bindDataRemoteBusinessUnitAssigned();
    }
    const [ratePeriodRecords, setRatePeriodRecords] = useState([]);
    const [fields, setFields] = useState(RatePeriodFields)
    const [headers, setHeaders] = useState([]);

    const bindDataRemoteRatePeriod = async (cleanFilter) => {

        // First start loading the model
        // setLoading(true);

        //First load records
        if (!businessUnitSelected) {
            return;
        }
        try {
            let query = `token=${user.Token}&ParentGUID=${props.ParentGUID}&IdBusinessUnit=${businessUnitSelected.id}&ChildGUID=${businessUnitSelected.guid}`;
     
            let request = await API.getAction("api/ContractsManager/RatePeriodRecords", query);
            let results = request.data[0];
            let parsedResults = results.JSONData;

            var parsedResultsLevel1 = JSON.parse(parsedResults)[0]
            if (!parsedResults || !parsedResultsLevel1) {
                setRatePeriod([]);
                return;
            }


            setRatePeriodRecords(parsedResultsLevel1.JSONData);


        } catch (error) {
            console.error(error);
        } finally {
            // setLoading(false);
        }
    };


  const fillFormikObject = (record) => {
    
    if (
      fields &&

      fields.length > 0
    ) {
      fields.forEach((item) => {
        console.log(
          "field name",
          item.FieldName,
          "RecordValue ",
          record[item.FieldName]
        );
        formik.setFieldValue(item.FieldName, record[item.FieldName]);
      });
    }

    //Now
  };


    const bindDataRemoteRatePeriodDetails = async () => {

       
        try {
            
            let query = `token=${user.Token}&ParentGUID=${props.ParentGUID}&RecordId=${columsSelected.GUID}`;
     
            let request = await API.getAction("api/ContractsManager/RatePeriodDetails", query);
            let results = request.data[0];
            let parsedResults = results.JSONData;
            
            var parsedResultsLevel1 = JSON.parse(parsedResults)[0]
            var record = parsedResultsLevel1.JSONData[0];
            
            fillFormikObject(record);
            toggle();

        } catch (error) {
            console.error(error);
        } finally {
            // setLoading(false);
        }
    };

    useEffect(() => {
        bindDataRemoteBusinessUnit();
        bindDataRemoteBusinessUnitAssigned();
        //  bindDataRateModelRemote();

    }, [])


    useEffect(() => {
        bindDataRemoteRatePeriod();
        console.log("the business unit", businessUnitSelected);
        //TODO activate
    }, [businessUnitSelected])

    return (
        <React.Fragment>
            {showAddEdit ?
                <Modal
                    show={showAddEdit}
                    onHide={() => toggle(null)}
                    backdrop="static"
                    keyboard={false}
                    size="xl"
                    className='modalRight'
                    dialogClassName='modal-90w'
                >
                    <Modal.Header closeButton>
                        <Row>
                            <Col>
                                <h4>
                                    {businessUnitSelected && businessUnitSelected.text} - Rate Period
                                </h4>
                            </Col>
                        </Row>

                    </Modal.Header>
                    <Form onSubmit={formik.handleSubmit}>
                        <Modal.Body className='p-0 bg-secondary-subtle '>
                            <Container fluid>
                                <Row>
                                    <Col xs={12} >
                                        <Row className='d-flex '>

                                            {
                                                fields.map((item, index) => (
                                                    <Col
                                                        className={
                                                            item.cssClasss + (!item.Visible ? " d-none" : "")
                                                        }
                                                        key={index}
                                                    >
                                                        <label className="me-2 mt-2">{item.FieldTitle}</label>
                                                        {item.Required ? <i className="required-asterisk ms-1 text-danger">*</i> : null}
                                                        <br />

                                                        <FieldSelector
                                                            actionMode={props.actionMode}
                                                            model={item}
                                                            key={index}
                                                            updateField={updateField}
                                                            value={formik.values[item.FieldName]}
                                                        ></FieldSelector>
                                                        {formik.errors[item.FieldName] ? (
                                                            <div className="invalid text-sm">
                                                                {formik.errors[item.FieldName]}
                                                            </div>
                                                        ) : null}
                                                    </Col>
                                                ))
                                            }
                                        </Row>
                                        <Row>
                                            <div className='mt-5'>
                                                <Tabs defaultActiveKey={1} className='nav nav-tabs'>
                                                    <Tab tabClassName="bg-light border shadow-sm" eventKey={1} title="Rate details">
                                                        <RatePeriodDetails
                                                            businessUnitSelected={businessUnitSelected}
                                                            roomSelected={roomSelected} setRatePeriod={setRatePeriod}
                                                            ParentGUID={props.ParentGUID}
                                                            ChildGUID={businessUnitSelected.guid}
                                                            ratePeriod={ratePeriod} />
                                                    </Tab>

                                                    <Tab tabClassName={`bg-light border shadow-sm   `}
                                                        eventKey={3} title="Special rate" >
                                                        <CRUDPageGRIDCustom
                                                            getEndpoint="api/ContractsManager/RatePeriodSpecialRecords"
                                                            modelEndpoint="api/ContractsManager/RatePeriodSpecialModel"
                                                            addEditEndpoint="api/ContractsManager/RatePeriodSpecialAddEdit"
                                                            deleteEndpoint="api/ContractsManager/RatePeriodSpecialDelete"
                                                            detailsEndpoint="api/ContractsManager/RatePeriodSpecialDetails"
                                                            ParentGUID={props.ParentGUID}
                                                            ChildGUID={businessUnitSelected.guid}
                                                 //           fields={ratePeriodSpecialRateFields}
                                                            page={{
                                                                "PageIndex": 3,
                                                                "PageTitle": "Special rate",
                                                                "PageIcon": "fa-solid fa-comments",
                                                                "MultiRecord": true,
                                                                "Active": true,

                                                            }}
                                                            PageIndex={3}
                                                            id={3}
                                                        />
                                                        {/* <SpecialRate
                                                            actionMode={props.actionMode}
                                                            ParentGUID={props.ParentGUID}
                                                        /> */}
                                                    </Tab>
                                                    <Tab tabClassName={`bg-white border shadow-sm `} eventKey={4} title="Charges/discount"
                                                    >
                                                        <CRUDPageGRIDCustom
                                                            getEndpoint="api/ContractsManager/RatePeriodSChargeDiscountsRecords"
                                                            modelEndpoint="api/ContractsManager/RatePeriodChargeDiscountsModel"
                                                            addEditEndpoint="api/ContractsManager/RatePeriodChargeDiscountsAddEdit"
                                                            deleteEndpoint="api/ContractsManager/RatePeriodChargeDiscountsDelete"
                                                            detailsEndpoint="api/ContractsManager/RatePeriodChargeDiscountsDetails"
                                                            ParentGUID={props.ParentGUID}
                                                            ChildGUID={businessUnitSelected.guid}
                                                            page={{
                                                                "PageIndex": 2,
                                                                "PageTitle": "Charges/Discounts",
                                                                "PageIcon": "fa-solid fa-comments",
                                                                "MultiRecord": true,
                                                                "Active": true,

                                                            }}
                                                            id={2}
                                                        />
                                                    </Tab>
                                                    <Tab tabClassName="bg-light border shadow-sm" eventKey={5} title="Rates Policies">
                                                        <CRUDPageGRIDCustom
                                                            getEndpoint="api/ContractsManager/RatePeriodPoliciesRecords"
                                                            modelEndpoint="api/ContractsManager/RatePeriodPoliciesModel"
                                                            addEditEndpoint="api/ContractsManager/RatePeriodPoliciesAddEdit"
                                                            deleteEndpoint="api/ContractsManager/RatePeriodPoliciesDelete"
                                                            detailsEndpoint="api/ContractsManager/RatePeriodPoliciesDetails"
                                                            ParentGUID={props.ParentGUID}
                                                            ChildGUID={businessUnitSelected.guid}
                                                            page={{
                                                                "PageIndex": 5,
                                                                "PageTitle": "Rate Policies",
                                                                "PageIcon": "fa-solid fa-comments",
                                                                "MultiRecord": true,
                                                                "Active": true,

                                                            }}
                                                            id={5}
                                                        />
                                                    </Tab>
                                                </Tabs>
                                            </div>
                                        </Row>
                                    </Col >

                                </Row>

                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="d-flex gx-3 align-items-center justify-content-end">
                                {Object.values(formik.errors).length > 0 && (
                                    <span className="invalid me-2">
                                        Please check the forms for errors
                                    </span>
                                )}
                                <button className="btn btn-secondary me-2" type="button" onClick={() => toggle(null)}>Close</button>
                                <button className="btn btn-primary me-2" type="button" onClick={() => [formik.submitForm()]}>Save</button>
                            </div>


                        </Modal.Footer>
                    </Form>

                </Modal> : null}
            <Row>

                <Col xs={12} md={3} className='text-dark insideMenu bg-light'>
                    <Row className='p-2 mt-2'>
                        <Col >
                            <div className='input-group '>
                                <select className='form-control form-control-sm'
                                    value={selectedBusinessUnitToAssign ?? ""}
                                    onChange={e => {
                                        setSelectedBusinessUnitToAssign(e.target.value)
                                    }}
                                >
                                    <option value="">Assign Business Unit</option>
                                    {businessUnitRecords && businessUnitRecords.map((item, index) => {
                                        return (<option value={item.id} key={index}>{item.text}</option>)
                                    })}
                                </select>
                                <div className='input-group-append'>
                                    <button className=' btn btn-primary btn-sm' type="button"
                                        disabled={!selectedBusinessUnitToAssign}
                                        onClick={assignBusinessUnit}>
                                        <i className='fa fa-plus'></i></button>
                                </div>
                            </div>
                        </Col>

                    </Row>
                    <hr className='my-1' />
                    {businessUnitAvailable.map((item, index) => {

                        return (<Row key={index} className={'py-2  mx-0 clickable ' + (businessUnitSelected && item.id === businessUnitSelected.id ? "active" : "")}
                            onClick={() => {
                                setBusinessUnitSelected(item)
                            }}
                        >
                            <Col>
                                {item.text}</Col>
                            <Col className='ml-auto col-auto'>
                                <button className='btn btn-sm btn-danger' type="button"
                                    onClick={() => {
                                        removeBusinessUnit(item)
                                    }}>
                                    <i className='fa fa-times'></i>
                                </button>
                            </Col>

                        </Row>)
                    })}

                </Col>
                <Col xs={12} md={9} className="bg-white">

                    <Row className='mt-4'>
                        <div className='col col-table'>
                            {/* {businessUnitSelected !== null ? */}
                            <DataGrid
                                dataSource={ratePeriodRecords}
                                columns={headers.length > 0 ? headers.map(item => item.FieldName) : null}

                                showBorders={true}
                                columnAutoWidth={true}
                                onSelectionChanged={selectItem}
                                style={{ marginBottom: "1rem" }}>
                                <Selection mode="single" />
                                {/* <Column
                                        alignment="center"
                                        caption="Rate Type"
                                        dataField="RateType" /> */}
                                <Column
                                    alignment="center"
                                    caption="Rate Description"
                                    dataField="RateDescription" />

                                <Column
                                    alignment="center"
                                    caption="Rate Code"
                                    dataField="RateCode" />
                                <Column
                                    alignment="center"
                                    caption="Start Date"
                                    dataField="DateStart" />

                                <Column
                                    alignment="center"
                                    caption="End Date"
                                    dataField="DateEnd" />
                                <Column
                                    alignment="center"
                                    caption="Charges/Discount"
                                    dataField="ChargesDiscount" />
                            </DataGrid>
                            {/* : null} */}
                        </div>
                        <div className='me-2' style={{ width: "50px" }}>
                            <div className="d-flex flex-column gy-3 align-items-center justify-content-center">
                                <button
                                    type="button"
                                    disabled={!businessUnitSelected}
                                    className="btn btn-primary btn-sm mb-2"
                                    onClick={() => toggle('I')}
                                    title="Add" >
                                    <i className="fa fa-plus"></i>
                                </button>
                                {
                                    columsSelected && (
                                        <>
                                            <button
                                                disabled={!businessUnitSelected}
                                                type="button" className="btn btn-sm btn-primary mb-2" 
                                                onClick={()=>{
                                                    bindDataRemoteRatePeriodDetails();
                                                }}
                                                title="Update">
                                                <i className="fa fa-pencil" />
                                            </button>
                                            <button id="grid-delete-button"
                                                disabled={!businessUnitSelected}
                                                onClick={() => deleteConfirm(columsSelected)}
                                                type="button" className="btn btn-sm btn-secondary mb-2" title="Delete">
                                                <i className="fa fa-trash" />
                                            </button>
                                        </>
                                    )
                                }
                            </div>

                        </div>

                    </Row>
                    {generalError ? (
                        <Row>
                            <Col xs={12}>
                                <div className="alert alert-danger" role="alert">
                                    {generalError}
                                </div>
                            </Col>
                        </Row>
                    ) : null}
                </Col>

            </Row >
        </React.Fragment >
    )
}

export default RatePeriod;
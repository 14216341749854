import React, { useEffect, useState } from 'react'
import { db } from "../../utils/db";
import { Card, Container, Dropdown } from 'react-bootstrap';
import { parsedUser } from '../../utils/GetCurrentUser';
import { useLocation } from 'react-router-dom';
import { generateColumnsFromJson } from '../../utils/generate-columns';
import { actionsModel } from '../../enum/actionsModel.enum';
import { confirmAlert } from 'react-confirm-alert';

import { exportToExcel } from '../../utils/export-to-excel';
import { exportToPDF } from '../../utils/export-to-pdf';
import { useToast } from '../../contexts/toast';
import CustomerForm from '../../components/customers/customerForm';
import ContractDataGrid from '../../components/contracts/ContractDataGrid/ContractDataGrid';
import useFavorite from '../../hooks/useFavorite';


const INITIAL_STATE = {
  "GUID": "",
  "Name": "",
  "IdCustomerType": 0,
  "CustomerTypeDesk": {},
  "IdParent": 0,
  "IdCountry": 0,
  "IdCountryState": 0,
  "Address1": "",
  "Address2": "",
  "City": "",
  "Zip_Code": "",
  "WebSite": "",
  "Delete": false,
  "CommentHistory": [
    {
      "GUID": "",
      "IdCommentType": 0,
      "Comments": "",
      "Delete": false
    }
  ],
  "BussinesUnits": [
    {
      "GUID": "",
      "IdBussinesUnit": 0,
      "IdCreditCondition": "",
      "CreditLimit": "",
      "RoomChargeTime": "",
      "StopSales": "",
      "Delete": false
    }
  ],
  "ContactPerson": [
    {
      "GUID": "",
      "Name": "",
      "Phone1": "",
      "Phone2": "",
      "Email": "",
      "Delete": false
    }
  ],
  "Files": [
    {
      "GUID": "",
      "IdFileType": 0,
      "Description": "",
      "OriginalFileName": "",
      "FilePath": "",
      "Comments": "",
      "Delete": false
    }
  ]
}
const CustomerPage = () => {

  const user = parsedUser();
  const { pathname } = useLocation();
  const toast = useToast();
  const [model, setModel] = React.useState(INITIAL_STATE)
  const [actionMode, setActionMode] = useState(null);//I=Insert, U= Update = C= Consultar, D= Delete
  const [isLoading, setIsLoading] = useState(false);

  const [isSearching, setIsSearching] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([])
  const [showForm, setShowForm] = useState(false);
  const [action, setAction] = useState(null)
  const { UpdateFavorites, checkIfPinned, isPinned } = useFavorite()

  useEffect(() => {
    checkIfPinned();
    console.log(INITIAL_STATE)
    setModel(INITIAL_STATE);
  }, []);

  useEffect(() => {
    setColumns(generateColumnsFromJson(model, ["businessUnits", "Policies", "GUID", "Delete"]));
  }, [model]);

  const actionSelected = (action) => {

    setAction(action)
    // TODO For edit and create, we need to pass the data to the modal.
    // setData([])

    if (action === actionsModel.CREATE) {
      setSelectedItem(null)
      toggleOpen("I")
    };
    setShowForm(action === actionsModel.EDIT || action === actionsModel.CREATE || action === actionsModel.VIEW);
    if (action === actionsModel.VIEW) toggleOpen("C");
    if (action === actionsModel.EDIT) toggleOpen("U");
    if (action === actionsModel.DELETE) {
      confirmAlert({
        message: 'Are you sure to delete this item?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => console.log('Click Yes')
          },
          {
            label: 'No',
            onClick: () => console.log('Click No')
          }
        ]
      });
    }
  }

  const toggleOpen = async (action) => {
    setActionMode(action);
  };

  const addFavorite = async () => {
    UpdateFavorites({
      icon: 'fa-solid fa-users-gear m-2',
      path: pathname,
      formPath: 'Rates Management / Rates & Contracts / Customers',
      title: 'Customers',
      user
    })
  }

  //Delete the record
  const deleteConfirm = async (obj) => {
    confirmAlert({
      message: "Are you sure you want to delete this record?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            console.log('Delete confirmed')
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const clearSelection = () => {
    setSelectedItem(null);
    setActionMode(null);
  }



  return (
    <React.Fragment>
      <Container>
        <nav aria-label="breadcrumb" className="mb-2">
          <ol className="breadcrumb border-bottom bg-light px-4 py-2">
            <li className="breadcrumb-item">
              Rates Management / Rates & Contracts / Customers
            </li>
          </ol>
        </nav>
        <div className="row mx-0" id="contentContainer">
          <div className="col-12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 mt-2">
              {isLoading ? (
                <React.Fragment>
                  <div className="">
                    <div className="w-25 mx-auto my-1" style={{ paddingTop: "" }}>
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="d-flex align-items-center my-1">
                    <i className={"fa-solid fa-users-gear me-3 fa-2x"}></i>
                    <div className="d-flex flex-row">
                      <h5 className={"my-1"}>Customer</h5>
                    </div>
                  </div>
                </React.Fragment>
              )}
              <div className="d-flex">
                {actionMode === null ? (
                  <>
                    <button className="btn btn-primary btn-sm my-1 me-2" title="Create" disabled={isLoading} onClick={() => actionSelected(actionsModel.CREATE, {})}>
                      <i className="fa fa-plus"></i>
                    </button>
                    {
                      <button className={`btn btn-sm my-1 me-2`} title="Search" onClick={() => setIsSearching(prev => !prev)}>
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </button>
                    }
                    {selectedItem &&
                      <>
                        <button className="btn btn-sm my-1 me-2" title="Update" disabled={isLoading} onClick={() => actionSelected(actionsModel.EDIT, data)}>
                          <i className="fa fa-pencil"></i>
                        </button>
                        <button className="btn btn-sm my-1 me-2" title="View" disabled={isLoading} onClick={() => actionSelected(actionsModel.VIEW, data)}>
                          <i className="fa fa-eye"></i>
                        </button>
                      </>
                    }
                  </>
                ) : null}
                <Dropdown>
                  <Dropdown.Toggle variant="button" id="dropdown-basic">
                    <i className="fa fa-ellipsis-v" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {isPinned ? <Dropdown.Item onClick={() => { addFavorite() }}>Remove from favorites</Dropdown.Item> : <Dropdown.Item onClick={() => { addFavorite() }}>Add to favorites</Dropdown.Item>}
                    {(actionMode === null && selectedItem) && <Dropdown.Item onClick={() => { toggleOpen('D') }}>Duplicar</Dropdown.Item>}
                    {actionMode === null ?
                      <><Dropdown.Divider /><Dropdown.Item onClick={() => exportToExcel(data, 'Customer')}>Export to Excel</Dropdown.Item></> : null}
                    {actionMode === "C" ? <Dropdown.Item onClick={() => exportToPDF()}>Export to PDF</Dropdown.Item> : null}
                    {(actionMode === null && selectedItem) && <><Dropdown.Divider /><Dropdown.Item onClick={() => deleteConfirm()}>Delete</Dropdown.Item></>}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>


            {showForm ? <CustomerForm action={action} data={selectedItem} setShow={setShowForm} clearSelection={clearSelection} /> :
              <ContractDataGrid
                columns={columns}
                setSelectedItem={setSelectedItem}
                data={model}
                showSearchPanel={isSearching}
              />}


          </div>
        </div>
      </Container>
    </React.Fragment>
  )
}

export default CustomerPage
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Search,
  CleaningServices,
} from "@mui/icons-material";
import "devextreme/data/odata/store";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Card, Col, Form, Row } from "react-bootstrap";
import * as yup from "yup";
import { DataGrid, Pager, Paging, Selection } from "devextreme-react/data-grid";

import API from "../../api/api";
import { parsedUser } from "../../utils/GetCurrentUser";
import { useLocation, useParams } from "react-router-dom";
import { DateBox, DropDownBox, List, RadioGroup, Tabs, Template, TextBox } from "devextreme-react";
import { Button as TextBoxButton } from "devextreme-react/text-box";
import FieldSelector from "../../components/customComponents/FieldSelector";
import { useFormik } from "formik";
import Sha256 from "../../utils/sha256";
import ROUTES from "../../utils/routes";
import ClearUser from "../../utils/ClearUser";
import { db } from "../../utils/db";
import { useToast } from "../../contexts/toast";
import RatePeriod from "../../components/rate-period/RatePeriod";

import { utils as SheetUtils, writeFile as writeExcel } from "xlsx";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import PoliciesTab from "../../components/accordion";


export default function CRUDRateManager() {
  // PROPERTIES
  const user = parsedUser();
  const { pathname } = useLocation();
  console.log("❗ ~ file: CRUDSimple.js:34 ~ CRUDSimple ~ pathname:", pathname)
  const params = useParams();
  const toast = useToast();

  const [generalError, setGeneralError] = useState(null);
  const [open, setOpen] = useState(false);
  const [actionMode, setActionMode] = useState(null);//I=Insert, U= Update = C= Consultar, D= Delete
  const [loading, setLoading] = useState(false);
  const [isPinned, setIsPinned] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const initialModel = {
    section: {},
    data: {
      Rows: [],
    },
  }
  const [model, setModel] = useState(initialModel);
  const [search, setSearch] = useState("");
  const allowedPageSizes = [10, 20, 50, 100, "all"];
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const dropDownBoxRef = useRef(null);
  const changeDropDownBoxValue = useCallback((arg) => {
    let val = arg.addedItems[0];
    if (val) {
      updateField("ContractStatus", val.id);
    }
    dropDownBoxRef.current.instance.close();
  }, []);
  // END PROPERTIES

  //METHODS
  ///BRING THE DATA FROM THE SERVER FOR MODEL AND RECORDS
  const bindDataRemote = async (cleanFilter) => {

    // First start loading the model
    setLoading(true);

    //First load records
    try {
      // setModel({
      //   ...model,
      //   section: recordResultSection[0],
      //   data: {
      //     ...model.data,
      //     Columns: gridColumns,
      //     Rows: gridData || dataFromAPI,
      //   },
      // });

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const bindDataDetailsRemote = async (recordId) => {
    //First load records
    let query = `id=${params.id}&token=${user.Token}&RecordId=${recordId}`;
    let request = await API.getAction("api/CRUD/Details", query);
    let results = request.data[0];

    let parsedResults = JSON.parse(results.JSONData)[0];
    if (parsedResults.Error) {
      alert(parsedResults.Msg);
      return;
    }
    let finalResult = parsedResults.JSONData[0];
    fillFormikObject(finalResult);
    setSelectedItem(null)
    //Fill object with new values
  };

  //Open edit/add mode
  const toggleOpen = async (action, reload) => {
    setActionMode(action);

    if (action === null) {
      setSelectedItem(null);
      formik.resetForm();
    }

    if (action === "I") {
      setSelectedItem(null)
    }
    if (action === "U" || action === "C" || action === "D") {
      await bindDataDetailsRemote(selectedItem.Id);
    }

    setGeneralError(null);
    setOpen(!open);
    if (reload) {
      bindDataRemote();
    }
  };

  //Delete the record
  const deleteConfirm = async (obj) => {
    confirmAlert({
      message: "Are you sure you want to delete this record?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {

            let query = `id=${params.id}&token=${user.Token}&RecordId=${selectedItem.Id}`;
            await API.getAction("api/CRUD/Delete", query);
            await bindDataRemote();
            setSelectedItem(null);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  //Update the field value on formik according to what has been updated
  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  const yupTypeValidator = (item) => {
    if (item.FieldType === "string" || item.FieldType === "varchar") {
      return yup.string().label(item.FieldTitle).required();
    } else if (item.FieldType === "bool" || item.FieldType === "bit") {
      return yup.boolean().label(item.FieldTitle).required();
    } else if (item.FieldType === "int" || item.FieldType === "bigint") {
      return yup.number().label(item.FieldTitle).required();
    } else if (item.FieldType === "date") {
      return yup.date().label(item.FieldTitle).required();
    }
  };

  const [validationsSchema, setValidationSchema] = useState(null);

  const fillFormikObject = (record) => {
    console.log("record", record, "model", model.section);
    if (
      model.section &&
      model.section.Fields &&
      model.section.Fields.length > 0
    ) {
      model.section.Fields.forEach((item) => {
        console.log(
          "field name",
          item.FieldName,
          "RecordValue ",
          record[item.FieldName]
        );
        formik.setFieldValue(item.FieldName, record[item.FieldName]);
      });
    }

    //Now
  };

  const prepareFormikObject = () => {
    console.log("Preparing formik object", model.section)
    let initialObject = {};
    let initialObjectValidation = {};

    if (model.section.Fields) {
      model.section.Fields.forEach((item) => {
        initialObject[item.FieldName] = item.value;

        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }
    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };

  //This method is to save the record we are working on
  const handleSubmit = async (obj, event) => {

    let actions = {
      "I": "I",
      "U": "U",
      "D": "I"
    };

    if (actionMode === "D") {
      const { GUID, Id, ...rest } = obj;
      obj = rest;
    }


    confirmAlert({
      message: "Are you sure you want to save this record?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            let objSPVersion = "";
            model.section.Fields.forEach((item) => {

              if (item.HtmlType !== "separator") {
                objSPVersion +=
                  (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
              }
              if (
                item.FieldType === "int" ||
                item.FieldType === "bool" ||
                item.FieldType === "bit" ||
                item.FieldType === "bigint"
              ) {
                objSPVersion +=
                  obj[item.FieldName] === undefined || obj[item.FieldName] === null
                    ? item.DefaultValue && item.DefaultValue.length > 0 ? item.DefaultValue : "null"
                    : "'" + obj[item.FieldName] + "'";
                //objSPVersion +=
                //obj[item.FieldName] === undefined ? "null" : obj[item.FieldName];
              } else if (item.HtmlType === "password") {
                let passwordEncrypted = null;
                if (obj[item.FieldName] && obj[item.FieldName].length > 0) {
                  passwordEncrypted =
                    obj["UserName"].toUpperCase() + obj[item.FieldName];
                  passwordEncrypted = Sha256.hash(passwordEncrypted);
                  ;
                }
                objSPVersion +=
                  obj[item.FieldName] === undefined
                    ? "null"
                    : "'" + passwordEncrypted + "'"
              } else
                if (item.HtmlType === 'radio') {

                  objSPVersion +=
                    obj[item.FieldName] === undefined || obj[item.FieldName] === null
                      ? item.DefaultValue && item.DefaultValue.length > 0 ? item.DefaultValue : "null"
                      : "'" + obj[item.FieldName] + "'";

                  debugger;
                  // } else if(item.HtmlType==="separator"){
                  //   //DO NOTHING, CONTINUE NEXT RECORD
                  //   debugger;
                } else {
                  objSPVersion +=
                    obj[item.FieldName] === undefined || obj[item.FieldName] === null
                      ? "null"
                      : "'" + obj[item.FieldName] + "'";
                }
            });

            console.log("❗ ~ file: CRUDSimple.js:322 ~ onClick: ~ action:", actions[actionMode])
            let queryString = `Id=${params.id}&Token=${user.Token}&ActionMode=${actions[actionMode]}`;
            let queryData = {
              Data: objSPVersion,
            };

            let request = await API.postAction(
              "api/CRUD/AddEdit?" + queryString,
              queryData
            );

            if (request.status === 200) {
              let response = JSON.parse(request.data[0].JSONData)[0];
              if (response.Error) {
                setGeneralError(response.Msg);
                return;
              }
            }
            setGeneralError(null);
            setSelectedItem(null);

            formik.resetForm();

            toast({
              type: "success",
              "message": "Record saved successfully",
            })

            if (actionMode === 'I') {
              return;
            }
            toggleOpen(null, true);
          },
        },
        {
          label: "No",
        },
      ],
    });


  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,

    validateOnChange: false,
    validateOnBlur: false,

    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  const checkIfPinned = async () => {
    try {
      await db.favorites.where("username").equals(user.Username).toArray((item) => {
        if (item.length > 0) {
          const favExists = item[0].values.some((fav) => fav.path === pathname);
          setIsPinned(favExists)
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  const addFavorite = async () => {

    const newFavorite = {
      icon: `${model.section.FormIcon}`,
      path: pathname,
      formPath: model.section.FormPath,
      title: model.section.FormTitle,
    }

    try {
      await db.favorites.where("username").equals(user.Username).modify((item) => {

        const favExists = item.values.some((fav) => fav.path === newFavorite.path);

        if (favExists) {
          setIsPinned(false)
          item.values = item.values.filter((fav) => fav.path !== newFavorite.path);
          toast({
            type: "warning",
            message: "Removed from favorites",
          });
          return;
        }

        setIsPinned(true)
        item.values.push(newFavorite);
        toast({
          type: "success",
          message: "Added to favorites",
        });
      });
    } catch (error) {
      console.error(error)
    }
  }

  const exportToExcel = useCallback(async () => {

    const worksheet = SheetUtils.json_to_sheet(model.data.Rows);

    const workbook = SheetUtils.book_new();

    SheetUtils.book_append_sheet(workbook, worksheet, "Sheet1");

    writeExcel(workbook, `${model.section.FormTitle}.xlsx`);
  }, [])

  const exportToPDF = useCallback(async () => {

    const container = document.getElementById("contentContainer");

    const containerHeight = container.clientHeight
    const containerWidth = container.clientWidth
    const ratio = containerHeight / containerWidth;

    html2canvas(container).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg");
      const pdf = new jsPDF("l", "mm", "a0");

      const width = pdf.internal.pageSize.getWidth();
      let height = pdf.internal.pageSize.getHeight();
      height = ratio * width;

      pdf.addImage(imgData, "JPEG", 0, 0, width, height);
      pdf.save(`data.pdf`);
    })
  }, [])

  // END METHODS

  // UI METHODS
  const loadGRIDUI = () => {
    return (
      <>
        {isSearching && (
          <Card className="mb-4">
            <Card.Header className="w-full d-flex justify-content-between align-items-center">
              <Card.Title>Filters</Card.Title>

              <button
                className="btn btn-secondary"
                type="button"
                onClick={() => { setIsSearching(false) }}
                style={{
                  "padding": "0px 8px",
                  "lineHeight": "23px"
                }}
              >
                <i style={{ fontSize: "10px" }} className="fa-solid fa-x"></i>
              </button>
            </Card.Header>
            <Form
              onSubmit={async (e) => {
                e.preventDefault();
                await bindDataRemote();
              }}
            >
              <Card.Body className="formBackground p-0">
              </Card.Body>
              <Card.Footer className="text-end">
                <button
                  className="btn btn-sm btn-secondary me-2"
                  type="button"
                  onClick={() => {
                    setSearch("");
                    setTimeout(async () => {
                      await bindDataRemote(true);
                    }, 1000);
                  }}
                >
                  <CleaningServices />
                </button>
                <button className="btn btn-sm btn-primary" type="submit">
                  <Search />
                </button>
              </Card.Footer>
            </Form>
          </Card>
        )
        }
        <Card>

          <Card.Body className="p-0">
            <DataGrid
              id="table-grid"
              dataSource={model.data.Rows}
              columns={model.data.Columns}
              columnAutoWidth={true}
              showBorders={true}
              onSelectionChanged={selectItem}
              loadPanel={{
                enabled: true,
                showIndicator: true,
                text: "Loading...",
              }}
            >
              <Selection mode="single" />
              <Paging
                defaultPageSize={10}
              />
              <Pager
                showInfo={true}
                infoText={"Page:"}
                visible={true}
                showPageSizeSelector={true}
                allowedPageSizes={allowedPageSizes}
              />
            </DataGrid>
          </Card.Body>
        </Card>
      </>
    );
  };


  //* TAB UI AND STATE METHODS
  const loadTabFormUI = ({ name }) => {
    return (
      <>
        <Row>
          <Col>
            <h6>{name}</h6>
          </Col>
        </Row>
        <Row>
          <Col className="col-12 col-md-4">
            <label className={"my-3 text-blue"}>
              Customer Name
              <i className="required-asterisk ms-1 text-danger">*</i>
            </label>
            <br />
            <TextBox
              className="form-input"
              id={"CustomerName"}
              name="CustomerName"
              mode={"text"}
              value={formik.values["CustomerName"]}
              onValueChanged={(e) => {
                updateField("CustomerName", e.value);
              }}
            />
          </Col>

          <Col>
            <label className={"my-3 text-blue"}>
              Start Date
              <i className="required-asterisk ms-1 text-danger">*</i>
            </label>

            <DateBox
              className="form-input"
              id={"StartDate"}
              name="StartDate"
              // mode={"text"}
              value={formik.values["StartDate"]}
              onValueChanged={(e) => {
                updateField("StartDate", e.value);
              }}
            />
          </Col>
          <Col>
            <label className={"my-3 text-blue"}>
              End Date
              <i className="required-asterisk ms-1 text-danger">*</i>
            </label>

            <DateBox
              className="form-input"
              id={"EndDate"}
              name="EndDate"
              // mode={"text"}
              value={formik.values["EndDate"]}
              onValueChanged={(e) => {
                updateField("EndDate", e.value);
              }}
            />
          </Col>
        </Row>
      </>
    )
  }

  const [formTabs, setFormTabs] = useState([
    {
      id: 0,
      text: 'General Information',
      // icon: 'user',
      content: loadTabFormUI({ name: "General Information" }),
    },
    {
      id: 1,
      text: 'Rate Period',
      // icon: 'comment',
      content: <RatePeriod />,
    },
    {
      id: 2,
      text: 'Contract Policies',
      // icon: 'find',
      content: <PoliciesTab />,
    },
    {
      id: 3,
      text: 'Comment History',
      // icon: 'find',
      content: 'Comment History Tab',
    },
  ]);

  const loadFormUI = () => {
    return (
      <div className="card">
        <Form autoComplete="off" onSubmit={formik.handleSubmit}>
          <div id="form-body" className="card-body p-4 formBackground">
            <Row>
              {/* CONTENT */}
              <Col className="col-12 col-md-3">
                <label className={"my-3 text-blue"}>
                  Contract Code
                  <i className="required-asterisk ms-1 text-danger">*</i>
                </label>
                <br />
                <TextBox
                  className="form-input"
                  id={"ContractCode"}
                  name="ContractCode"
                  mode={"text"}
                  value={formik.values["ContractCode"]}
                  onValueChanged={(e) => {
                    updateField("ContractCode", e.value);
                  }}
                />
              </Col>
              <Col className="col-12 col-md-3">
                <label className={"my-3 text-blue"}>
                  Description
                  <i className="required-asterisk ms-1 text-danger">*</i>
                </label>
                <br />
                <TextBox
                  className="form-input"
                  id={"Description"}
                  mode={"text"}
                  value={formik.values["Description"]}
                  onValueChanged={(e) => {
                    updateField("Description", e.value);
                  }}
                >
                  <TextBoxButton
                    location="after"
                    name="Description"
                    options={{
                      icon: "find",
                      onClick: () => {
                        console.log("it works!!")
                      }
                    }}

                  />
                </TextBox>
              </Col>
              <Col className="col-12 col-md-3">
                <label className={"my-3 text-blue"}>
                  Contract Status
                  <i className="required-asterisk ms-1 text-danger">*</i>
                </label>
                <br />

                <DropDownBox
                  dataSource={[{ "id": 1, "text": "Active" }, { "id": 2, "text": "Inactive" }]}
                  className="form-input"
                  id={"ContractStatus"}
                  valueExpr={"id"}
                  displayExpr={"text"}
                  value={formik.values["ContractStatus"] || 1}
                  defaultValue={1}
                  onValueChanged={(e) => {
                    updateField("ContractStatus", e.value);
                  }}
                  ref={dropDownBoxRef}
                  dropDownButtonTemplate="dropdownButtonIcon"
                //   placeholder="Select option"
                >
                  <Template name="dropdownButtonIcon">
                    <div className="d-flex justify-content-center align-items-center h-100 w-100">
                      <i className="fa-solid fa-caret-down"></i>
                    </div>
                  </Template>
                  <List
                    dataSource={[{ "id": 1, "text": "Active" }, { "id": 2, "text": "Inactive" }]}
                    selectionMode="single"
                    onSelectionChanged={changeDropDownBoxValue}
                  />
                </DropDownBox>
              </Col>


              <Col className="col-12 col-md-3">
                <label className={"my-3 text-blue"}>
                  Active
                  {/* <i className="required-asterisk ms-1 text-danger">*</i> */}
                </label>
                <br />
                <RadioGroup
                  id={"IsActive"}
                  defaultValue={0}
                  dataSource={[{ "id": 1, "text": "Yes" }, { "id": 0, "text": "No" }]}
                  value={formik.values["IsActive"] || 0}
                  layout="horizontal"
                  valueExpr={"id"}
                  displayExpr={"text"}
                  onValueChanged={(e) => {
                    let valueToAdd = e.value.id ? e.value.id : e.value;

                    updateField("IsActive", valueToAdd);
                  }}
                />
              </Col>
            </Row>

            <Row className="px-2 mt-5">
              <Tabs
                dataSource={formTabs}
                selectedIndex={selectedTabIndex}
                onSelectionChanged={(e) => {
                  console.log("❗ ~ file: CRUDTest.js:573 ~ loadFormUI ~ e:", e)
                  setSelectedTabIndex(e.component.option("selectedIndex"));
                }}
              />

              <div className="tab-form col-12">
                {(formTabs && formTabs[selectedTabIndex] && formTabs[selectedTabIndex].content)}
              </div>
            </Row>
          </div>
          <div
            className="card-footer"
            data-html2canvas-ignore="true"
          >
            {generalError ? (
              <Row>
                <Col xs={12}>
                  <div className="alert alert-danger" role="alert">
                    {generalError}
                  </div>
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col xs={12} className="text-end">
                {Object.values(formik.errors).length > 0 ? (
                  <span className="invalid me-2">
                    Please check the forms for errors
                  </span>
                ) : null}
                <button className="btn btn-primary me-2" type="submit" disabled={actionMode === "C"}>
                  Save
                </button>
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={() => {

                    confirmAlert({
                      message: "Are you sure you want to close this screen?",
                      buttons: [
                        {
                          label: "Yes",
                          onClick: () => {
                            toggleOpen(null)
                          },
                        },
                        {
                          label: "No",
                        },
                      ],
                    });
                  }
                  }
                >
                  Close
                </button>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    );
  };


  //EVENTS
  const selectItem = useCallback((e) => {
    e.component.byKey(e.currentSelectedRowKeys[0]).done((item) => {
      setSelectedItem(item);
    });
  }, []);

  useEffect(() => {
    setOpen(false)
    setSelectedItem(null)
    setActionMode(null)
    setGeneralError(null)
    setModel(initialModel);
    checkIfPinned();
    bindDataRemote();

    return () => formik.resetForm();
  }, [params.id]);

  useEffect(() => {
    console.log("section updated", model)
    prepareFormikObject();
  }, [model.section, model.section.Fields]);
  // END EVENTS

  return (
    <React.Fragment>
      <div className="row mx-0" id="contentContainer">
        <div className="col-12">
          <div className="dx-card my-4 px-3 py-2 titleContainer">
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              {loading ? (
                <React.Fragment>
                  <div className="">
                    <div className="w-25 mx-auto my-1" style={{ paddingTop: "" }}>
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {/* <div className="col-auto"></div> */}
                  <div className="d-flex align-items-center my-1">
                    <i className={"fa-solid fa-file-lines" + " me-3 fa-2x"}></i>
                    <div className="d-flex flex-column">
                      <h5 className={"my-1"}>Rates</h5>
                      <small>Rates Management / Rates Manager / Rates</small>
                    </div>
                  </div>
                </React.Fragment>
              )}
              <div className="">
                <button
                  className={`btn btn-blue-overline${isPinned ? "-selected" : ""} me-2`}
                  title="Pin to Favorites"
                  onClick={() => addFavorite()}
                  data-html2canvas-ignore="true"
                >
                  <i className="fa fa-thumbtack"></i>
                </button>
                {actionMode === null ? (
                  <>
                    <button className="btn btn-primary my-1 me-2" title="Create" disabled={loading} onClick={() => toggleOpen("I")}>
                      <i className="fa fa-plus"></i>
                    </button>
                    {!isSearching && (
                      <button className={`btn btn-primary my-1 me-2`} title="Search" onClick={() => setIsSearching(true)}>
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </button>
                    )}
                  </>
                ) : null}
                {selectedItem ? (
                  <>
                    <button className="btn btn-primary my-1 me-2" title="View" disabled={loading} onClick={() => toggleOpen("C")}>
                      <i className="fa fa-eye"></i>
                    </button>
                    <button className="btn btn-primary my-1 me-2" title="Update" disabled={loading} onClick={() => toggleOpen("U")}>
                      <i className="fa fa-pencil"></i>
                    </button>
                    <button className="btn btn-primary my-1 me-2" title="Duplicate" disabled={loading} onClick={() => toggleOpen("D")}>
                      <i className="fa-solid fa-copy"></i>
                    </button>
                    <button
                      className="btn btn-secondary my-1 me-2"
                      type="button"
                      disabled={loading}
                      title="Delete"
                      onClick={() => deleteConfirm()}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </>
                ) : null}
                {actionMode === "C" && (
                  <button
                    className="btn btn-blue-overline my-1 me-2"
                    title="Export to PDF"
                    disabled={loading}
                    onClick={exportToPDF}
                    data-html2canvas-ignore="true"
                  >
                    <i className="fa-solid fa-file-pdf"></i>
                  </button>
                )}
                {actionMode === null && (
                  <button
                    className="btn btn-blue-overline my-1 me-2"
                    type="button"
                    disabled={loading}
                    title="Export to Excel"
                    onClick={exportToExcel}
                  >
                    <i className="fa-solid fa-file-excel"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
          {open ? loadFormUI() : loadGRIDUI()}
        </div>
      </div>
    </React.Fragment>
  );
}

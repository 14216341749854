import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ContextMenu, { Position } from "devextreme-react/context-menu";
import List from "devextreme-react/list";
import { useAuth } from "../../contexts/auth";
import "./UserPanel.scss";
import ClearUser from "../../utils/ClearUser";
import ROUTES from "../../utils/routes";

export default function UserPanel({ menuMode }) {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();

  function navigateToProfile() {
    navigate("/profile");
  }
  const menuItems = useMemo(
    () => [
      {
        text: "Profile",
        icon: "user",
        onClick: navigateToProfile,
      },
      {
        text: "Logout",
        icon: "runner",
        onClick: () => {
          ClearUser();
          window.location = ROUTES.Default.Clean;
        },
      },
      // {
      //   text: "Audit Info",
      //   icon: "runner",
      //   onClick: () => {
      //     ClearUser();
      //     window.location = ROUTES.Default.Clean;
      //   },
      // },
    ],
    [signOut]
  );
  return (
    <div className={"user-panel text-right"}>
      <div className={"user-info"}>
        <div className={"image-container"}>
          <div
            style={{
              background: `url('https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/06.png') no-repeat #fff`,
              backgroundSize: "cover",
            }}
            className={"user-image"}
          />
        </div>
        {/* <div className={"user-name"}>{user.email}</div> */}
      </div>

      {menuMode === "context" && (
        <ContextMenu
          items={menuItems}
          target={".user-button"}
          showEvent={"dxclick"}
          // width={210}
          cssClass={"user-menu"}
        >
          <Position my={"top center"} at={"bottom center"} />
        </ContextMenu>
      )}
      {menuMode === "list" && (
        <List className={"dx-toolbar-menu-action"} items={menuItems} />
      )}
    </div>
  );
}

const RatePeriodFields = [
    {
        "IdAppForm": 64,
        "Id": 6400001,
        "FieldName": "IdContract",
        "FieldType": "bigint",
        "FieldTitle": "Contract",
        "Position": 1,
        "cssClasss": "col-12 col-md-4",
        "HtmlType": "select",
        "PlaceHolder": "",
        "DefaultValue": "",
        "OptionValues": null,
        "DataSource": null,
        "Required": false,
        "Visible": false,
        "ReadOnly": false
    },
    // {
    //     "IdAppForm": 64,
    //     "Id": 6400002,
    //     "FieldName": "IdBusinessUnit",
    //     "FieldType": "bigint",
    //     "FieldTitle": "Business Unit",
    //     "Position": 2,
    //     "cssClasss": "col-12 col-md-4",
    //     "HtmlType": "select",
    //     "PlaceHolder": "",
    //     "DefaultValue": "",
    //     "OptionValues": null,
    //     "DataSource": null,
    //     "Required": false,
    //     "Visible": false,
    //     "ReadOnly": false
    // },
    {
        "IdAppForm": 64,
        "Id": 6400003,
        "FieldName": "Id",
        "FieldType": "bigint",
        "FieldTitle": "Id",
        "Position": 3,
        "cssClasss": "col-12 col-md-4",
        "HtmlType": "text",
        "PlaceHolder": "",
        "DefaultValue": "",
        "OptionValues": null,
        "DataSource": null,
        "Required": false,
        "Visible": false,
        "ReadOnly": false
    },
    {
        "IdAppForm": 64,
        "Id": 6400004,
        "FieldName": "Code",
        "FieldType": "varchar",
        "FieldTitle": "Rate Code",
        "Position": 4,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "text",
        "PlaceHolder": "",
        "DefaultValue": "",
        "OptionValues": null,
        "DataSource": null,
        "Required": true,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "IdAppForm": 64,
        "Id": 6400005,
        "FieldName": "Description",
        "FieldType": "varchar",
        "FieldTitle": "Rate Description",
        "Position": 5,
        "cssClasss": "col-12 col-md-6 col-lg-2",
        "HtmlType": "text",
        "PlaceHolder": "",
        "DefaultValue": "",
        "OptionValues": null,
        "DataSource": null,
        "Required": true,
        "Visible": true,
        "ReadOnly": false
    },
    // {
    //     "IdAppForm": 64,
    //     "Id": 6400006,
    //     "FieldName": "RateType",
    //     "FieldType": "char",
    //     "FieldTitle": "Rate Type",
    //     "Position": 6,
    //     "cssClasss": "col-12 col-md-4",
    //     "HtmlType": "radio",
    //     "PlaceHolder": "",
    //     "DefaultValue": "",
    //     "OptionValues": null,
    //     "DataSource": null,
    //     "Required": false,
    //     "Visible": false,
    //     "ReadOnly": false
    // },
    // {
    //     "IdAppForm": 64,
    //     "Id": 6400007,
    //     "FieldName": "ChargeDiscount",
    //     "FieldType": "bit",
    //     "FieldTitle": "Charge Discount",
    //     "Position": 7,
    //     "cssClasss": "col-12 col-md-4",
    //     "HtmlType": "radio",
    //     "PlaceHolder": "",
    //     "DefaultValue": "",
    //     "OptionValues": "[{\"id\": 0,\"text\": \"No\"},{\"id\": 1,\"text\": \"Si\"}]",
    //     "DataSource": null,
    //     "Required": false,
    //     "Visible": false,
    //     "ReadOnly": false
    // },
    {
        "IdAppForm": 64,
        "Id": 6400008,
        "FieldName": "DateStart",
        "FieldType": "datetime",
        "FieldTitle": "Start date",
        "Position": 8,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "date",
        "PlaceHolder": "",
        "DefaultValue": "",
        "OptionValues": null,
        "DataSource": null,
        "Required": true,
        "Visible": true,
        "ReadOnly": false
    },
    {
        "IdAppForm": 64,
        "Id": 6400009,
        "FieldName": "DateEnd",
        "FieldType": "datetime",
        "FieldTitle": "End date",
        "Position": 9,
        "cssClasss": "col-6 col-md-2",
        "HtmlType": "date",
        "PlaceHolder": "",
        "DefaultValue": "",
        "OptionValues": null,
        "DataSource": null,
        "Required": true,
        "Visible": true,
        "ReadOnly": false
    },
    // {
    //     "IdAppForm": 64,
    //     "Id": 6400010,
    //     "FieldName": "allotments",
    //     "FieldType": "varchar",
    //     "FieldTitle": "Allotments",
    //     "Position": 10,
    //     "cssClasss": "col-12 col-md-4",
    //     "HtmlType": "text",
    //     "PlaceHolder": "",
    //     "DefaultValue": "",
    //     "OptionValues": null,
    //     "DataSource": null,
    //     "Required": false,
    //     "Visible": false,
    //     "ReadOnly": false
    // },
    // {
    //     "IdAppForm": 64,
    //     "Id": 6400011,
    //     "FieldName": "release",
    //     "FieldType": "varchar",
    //     "FieldTitle": "release",
    //     "Position": 11,
    //     "cssClasss": "col-12 col-md-4",
    //     "HtmlType": "text",
    //     "PlaceHolder": "",
    //     "DefaultValue": "",
    //     "OptionValues": null,
    //     "DataSource": null,
    //     "Required": false,
    //     "Visible": false,
    //     "ReadOnly": false
    // },
    {
        "IdAppForm": 64,
        "Id": 6400012,
        "FieldName": "Comments",
        "FieldType": "varchar",
        "FieldTitle": "Comments",
        "Position": 12,
        "cssClasss": "col-12 col-md-4",
        "HtmlType": "textarea",
        "PlaceHolder": "",
        "DefaultValue": "",
        "OptionValues": null,
        "DataSource": null,
        "Required": false,
        "Visible": false,
        "ReadOnly": false
    },
    {
        "IdAppForm": 64,
        "Id": 6400013,
        "FieldName": "Active",
        "FieldType": "bit",
        "FieldTitle": "Active",
        "Position": 13,
        "cssClasss": "col-12 col-md-4",
        "HtmlType": "radio",
        "PlaceHolder": "",
        "DefaultValue": "",
        "OptionValues": "[{\"id\": 0,\"text\": \"No\"},{\"id\": 1,\"text\": \"Si\"}]",
        "DataSource": null,
        "Required": false,
        "Visible": false,
        "ReadOnly": false
    },
    {
        "IdAppForm": 64,
        "Id": 6400021,
        "FieldName": "GUID",
        "FieldType": "uniqueidentifier",
        "FieldTitle": "GUID",
        "Position": 21,
        "cssClasss": "col-12 col-md-4",
        "HtmlType": "text",
        "PlaceHolder": "",
        "DefaultValue": "",
        "OptionValues": null,
        "DataSource": null,
        "Required": false,
        "Visible": false,
        "ReadOnly": false
    }
]

export default RatePeriodFields;

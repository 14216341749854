import { DataGrid, TreeView } from 'devextreme-react';
import { Column, Editing } from 'devextreme-react/data-grid';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import FieldSelector from '../../customComponents/FieldSelector';
import { useFormik } from 'formik';
import { parsedUser } from '../../../utils/GetCurrentUser';
import API from '../../../api/api';

const RatePeriodDetails = ({ ParentGUID, setRatePeriod, ratePeriod, businessUnitSelected, ChildGUID }) => {


    const [tableSource, setTableSource] = useState([])

    const handleRateChange = async (e) => {
        if (!e) return
        const newTab = [...roomTypeRecordsDetails]
        const IdAgeGroup = e[0]?.key.IdAgeGroup
        
        const index = newTab.findIndex((item) => item.IdAgeGroup === IdAgeGroup)
        console.log("index found ", newTab, e, index);
        if (index > -1) {
            if(e[0].data.Rate){
            newTab[index].Rate = e[0].data.Rate;
            }
            if(e[0].data.Apply){
            newTab[index].Apply = e[0].data.Apply
            }
            console.log("cell update", newTab[index]);
        //    setRoomTypeRecordsDetails(newTab);
            //We need to send to the server
            //1. Go over all the visible fields and assembly the query to submit
            let objSPVersion = "";
            let roomTypeOccupation = newTab[index];
            Object.keys(roomTypeOccupation)
            .filter(item=>item !== 'RowStatus' && item !=="IdUserAdd" &&
            item !== 'IdUserMod' && item !=='IdUserDel' &&
            item !== 'DateAdd' && item !=='DateMod' && item !== 'DateDel' && 
            item !== 'AgeGroup' && item !=="ot")
            .forEach((item) => {
                console.log(item, roomTypeOccupation[item], typeof roomTypeOccupation[item])
                let val = null;
                
    
                if (typeof roomTypeOccupation[item] === 'string') {
                    val = `'${roomTypeOccupation[item]}'`;
                }
    
                if (typeof roomTypeOccupation[item] === 'number') {
                    val = `${roomTypeOccupation[item]}`;
                }
                if (typeof roomTypeOccupation[item] === 'object') {
                    val = null;
                }
                if (objSPVersion.length > 0) {
                    objSPVersion += ",";
                }
                objSPVersion += "@" + item + "=" + val
            });
            objSPVersion += ",@ParentGUID='" + ParentGUID + "',@ChildGUID='" + ChildGUID + "', @IdRoomType=" + roomTypeSelected.id;
         
            let queryString = `Token=${user.Token}&ActionMode=X`;
            let queryData = {
                Data: objSPVersion,
            };
debugger;
            let request = await API.postAction(
                "api/ContractsManager/RatePeriodRoomTypeOccupationAddEdit?" + queryString,
                queryData
            );
            console.log("response editing", request)
                ;
        }

    }


    const formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
    });
    const updateField = (fieldName, fieldValue) => {
        formik.setFieldValue(fieldName, fieldValue);
        setRatePeriod({ ...ratePeriod, [fieldName]: fieldValue });
    };

    // Room Type
    const user = parsedUser();
    const [roomTypes, setRoomTypes] = useState([]);
    const requestRecordsRoomTypes = async () => {
        try {

            let id = "78e296a1adde0c27623089555b750a9d5e0ec5645b9766e7ba63ebf0112a2248";
            let query = `token=${user.Token}&id=${id}&column1=IdBusinessUnit&value1=${businessUnitSelected.id}`;
            var request = await API.getAction("api/selector", query);
            let results = request.data[0];
            let parsedResults = JSON.parse(results.JSONData)[0];


            if (parsedResults.Error) {
                console.error(parsedResults.Msg);
                return;
            }
            let recordResult = JSON.parse(parsedResults.JSONData);
            if (recordResult) {

                let parsedItems = recordResult.map((item) => ({
                    id: item.Id,
                    text: item.Description,
                }));

                setRoomTypes(parsedItems);

            }
        } catch (error) {
            console.error(error);
        }
    };

    const [roomTypeDetails, setRoomTypeDetails] = useState({});

    const [roomTypeSelected, setRoomTypeSelected] = useState(null);
    const roomSelection = e => {

        setRoomTypeSelected(e.itemData);
    }

    const [roomTypeRecordsDetails, setRoomTypeRecordsDetails] = useState([])
    const bindDataRoomTypeDetailsRemote = async () => {
        //First load records
        let query = `token=${user.Token}&RecordId=${roomTypeSelected.id}&ParentGUID=${ParentGUID}&ChildGUID=${ChildGUID}`;
        let request = await API.getAction("api/ContractsManager/RatePeriodRoomTypeDetails", query);
        let results = request.data[0];

        let parsedResults = JSON.parse(results.JSONData)[0];
        let parsedResultsLevel1 = parsedResults.JSONData[0];

        setRoomTypeDetails(parsedResultsLevel1);

    }
    const bindDataRoomTypeOccupationDetailsRemote = async () => {
        //First load records
        let query = `token=${user.Token}&RecordId=${roomTypeSelected.id}&ParentGUID=${ParentGUID}&ChildGUID=${ChildGUID}`;
        let request = await API.getAction("api/ContractsManager/RatePeriodRoomTypeOccupationDetails", query);
        let results = request.data[0];

        let parsedResults = JSON.parse(results.JSONData)[0];

        if (parsedResults.Error) {
            alert(parsedResults.Msg);
            return;
        }
        let finalResult = parsedResults.JSONData;
debugger;


        console.log("final result", finalResult, tableSource);
        setRoomTypeRecordsDetails(finalResult);
    }

    // End Room Type

    useEffect(() => {
        requestRecordsRoomTypes();
    }, [businessUnitSelected])
    const [allotments, setAllotments] = useState(null);
    const [release, setRelease] = useState(null);
    useEffect(() => {
        console.log("room type details update", roomTypeDetails);
        if (roomTypeSelected) {
            setRoomTypeDetails({
                ...roomTypeDetails,
                allotments: allotments,
                release: release
            })

        }
    }, [allotments, release]);
    const saveRoomTypeDetails = async () => {
        let objSPVersion = "";
        Object.keys(roomTypeDetails)
        .filter(item=>item !== 'RowStatus' && item !=="IdUserAdd" &&
        item !== 'IdUserMod' && item !=='IdUserDel' &&
        item !== 'DateAdd' && item !=='DateMod' && item !== 'DateDel')
        .forEach((item) => {
            console.log(item, roomTypeDetails[item], typeof roomTypeDetails[item])
            let val = null;
            

            if (typeof roomTypeDetails[item] === 'string') {
                val = `'${roomTypeDetails[item]}'`;
            }

            if (typeof roomTypeDetails[item] === 'number') {
                val = `${roomTypeDetails[item]}`;
            }
            if (typeof roomTypeDetails[item] === 'object') {
                val = null;
            }
            if (objSPVersion.length > 0) {
                objSPVersion += ",";
            }
            objSPVersion += "@" + item + "=" + val
        });
        console.log(objSPVersion, roomTypeDetails)
        let queryString = `Token=${user.Token}&ActionMode=U`;
        let queryData = {
            Data: objSPVersion,
        };

        let request = await API.postAction(
            "api/ContractsManager/RatePeriodRoomTypeAddEdit?" + queryString,
            queryData
        );

    }
    useEffect(() => {
        console.log("room type details update final ", roomTypeDetails);
        if (roomTypeSelected) {
            saveRoomTypeDetails();
        }
    }, [roomTypeDetails]);
    useEffect(() => {
        console.log(roomTypeSelected);
        if (roomTypeSelected) {
            //we need to request the room info
            bindDataRoomTypeOccupationDetailsRemote();
            bindDataRoomTypeDetailsRemote()
        }
    }, [roomTypeSelected])


    return (
        <Container>
            <Row className="d-flex row  bg-body-tertiary border-1 shadow-sm mx-0">

                <Col xs={12} md={3} className='text-dark insideMenu bg-light border-right'>
                    <b className='mt-4 d-inline-block'>Room Type </b>
                    <hr />
                    <TreeView
                        //   ref={treeViewRef}
                        items={roomTypes}
                        keyExpr={"Id"}
                        selectionMode={"single"}
                        selectedItem={roomTypeSelected}
                        selectByClick={true}
                        focusStateEnabled={true}
                        expandEvent={"click"}
                        onItemClick={roomSelection}
                        //   onContentReady={onMenuReady}
                        width={"100%"}
                    />
                </Col>
                <Col xs={12} md={9} className="d-flex flex-column py-2  ">

                    <DataGrid
                        dataSource={roomTypeRecordsDetails}
                        // dataSource={tableSource}
                        onSaved={e => {
                            console.log("row update", e);

                        }}
                        showColumnLines={true}
                        showRowLines={true}
                        rowAlternationEnabled={true}
                        showBorders={true} >

                        <Editing mode="cell" onChangesChange={handleRateChange} allowUpdating={true} />
                        <Column
                            //  groupIndex={0}
                            alignment="center"
                            editorOptions={{ disabled: true }}
                            caption="Guest Type"
                            dataField="AgeGroup" />
                        <Column
                            alignment="center"
                            editorOptions={{ disabled: true }}
                            caption="Occupation Type"
                            dataField="OccupationType" />
                        <Column
                            alignment="center"
                            caption="Rate"
                            dataField="Rate"
                            className="columnInput"
                            dataType="number" />
                        <Column
                            alignment="center"
                            editorOptions={{ disabled: true }}
                            caption="Apply"
                            dataField="Apply"
                            cellRender={(e) => {
                                return (
                                    <div className="d-flex justify-content-center">
                                        {e.value ?
                                            <i className="fas fa-check-square"></i> :
                                            <i className="far fa-square"></i>}
                                    </div>
                                )
                            }}
                        />
                    </DataGrid>
                    {roomTypeDetails ?
                        <Row>
                            <Col xs={12} md={6} className=" my-3">
                                <label className="mb-1">Allotments</label>

                                <FieldSelector className="form-input" updateField={(name, value) => {
                                    console.log(name, value);
                                    setAllotments(value);
                                    //setRoomTypeDetails(u)
                                }}
                                    value={roomTypeDetails?.allotments ?? ""} model={{
                                        "cssClasss": "col-12 col-md-3",
                                        "HtmlType": "number",
                                        "FieldName": "allotments",
                                        "PlaceHolder": "",
                                        "DefaultValue": "",
                                        "Required": true,
                                        "Visible": true
                                    }} />
                            </Col>
                            <Col xs={12} md={6} className=" my-3">
                                <label className="mb-1">Release</label>

                                <FieldSelector className="form-input" updateField={(name, value) => {
                                    console.log(name, value);
                                    setRelease(value);

                                }}
                                    value={roomTypeDetails?.release ?? ""} model={{
                                        "cssClasss": "col-12 col-md-3",
                                        "HtmlType": "number",
                                        "FieldName": "release",
                                        "PlaceHolder": "",
                                        "DefaultValue": "",
                                        "Required": true,
                                        "Visible": true
                                    }} />
                            </Col>

                        </Row> : null}
                </Col>




            </Row>
        </Container>
    )
}
export default RatePeriodDetails
import React, { useCallback, useEffect, useRef, useState } from "react";

import { Column, DataGrid, FilterRow, Pager, Paging, Selection } from "devextreme-react/data-grid";
import "devextreme/data/odata/store";

import { Card, Col, Container, Form, Modal, Row } from "react-bootstrap";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { useFormik } from "formik";
import * as yup from "yup";

import { useParams } from "react-router-dom";
import API from "../../api/api";
import FieldSelector from "../../components/customComponents/FieldSelector";
import { useToast } from "../../contexts/toast";
import { parsedUser } from "../../utils/GetCurrentUser";
import yupTypeValidator from "../../utils/YupTypeValidator";
import Sha256 from "../../utils/sha256";

const initialProps = {
  ParentGUID: "",
  page: {},
  active: false,
  actionMode: "",
  getEndpoint: "",
  addEditEndpoint: "",

  deleteEndpoint: "",
  modelEndpoint: '',
  detailsEndpoint: '',
  id: 0
};

/**
 * @param {initialProps} props
 */
const CRUDPageGRIDCustom = (props) => {

  // PROPERTIES
  const user = parsedUser();

  const [headers, setHeaders] = useState([]);//This is used for GRIDs
  const [fields, setFields] = useState(null);
  const [records, setRecords] = useState({
    Columns: [],
    Rows: [],
  })
  const params = useParams();
  const toast = useToast();
  const [showAddEdit, setShowAddEdit] = useState(false);
  const [generalError, setGeneralError] = useState(null);
  const [actionMode, setActionMode] = useState(props.actionMode);//I=Insert, U= Update = C= Consultar, D= Delete
  const [loading, setLoading] = useState(false);
  

  const [model, setModel] = useState(props.page);
  const [search, setSearch] = useState("");
  const allowedPageSizes = [10, 20, 50, 100, "all"];
  const [selectedItem, setSelectedItem] = useState(null);
  const selectedItemRef = useRef(null);

  // END PROPERTIES

  //METHODS


  ///BRING THE DATA FROM THE SERVER FOR MODEL AND RECORDS
  const bindDataRecordRemote = async (cleanFilter) => {

    if (props.ParentGUID == null) {
      return;
    }
    // First start loading the model
    setLoading(true);

    //First load records
    try {
      let query = `token=${user.Token}&PageIndex=${props.page.PageIndex}&ParentGUID=${props.ParentGUID}`;
      if (search.length > 0 && cleanFilter == null) {
        query += "&Search=" + search;
      }

      let request = await API.getAction(props.getEndpoint, query);
      let results = request.data[0];
      if (!results.JSONData) {
        return;
      }
      let parsedResults = JSON.parse(results.JSONData)[0];

      let recordResult = parsedResults.JSONData;
      if (recordResult === null) {
        setRecords({ Rows: [] })
        return;
      }
      let dataFromAPI = recordResult[0].JSONData;
      console.log("Results from record", recordResult, dataFromAPI);

      //TODO: | Use JSONConfig to show specific fields


      //! If grid data stays null, we use the data from the API
      setRecords({

        Rows: recordResult
      })


    } catch (error) {
      console.error(error);
      setRecords({
        Rows: [],

      });
    } finally {
      setLoading(false);
    }
  };

  // THIS METHOD IS USED TO BRING THE DETAILS OF THE SECTION USED 
  const bindDataDetailsRemote = async (recordId) => {
    //First load records
    try {
      debugger;
      let query = `token=${user.Token}&RecordId=${recordId}&PageIndex=${props.page.PageIndex}&ParentGUID=${props.ParentGUID}`;
      let request = await API.getAction(props.detailsEndpoint, query);
      let results = request.data[0];

      let parsedResults = JSON.parse(results.JSONData)[0];
      if (parsedResults.Error) {
        alert(parsedResults.Msg);
        return;
      }
      let finalResult = parsedResults.JSONData[0];
      fillFormikObject(finalResult);
      setSelectedItem(null)
      //Fill object with new values
    } catch (error) {
      toast({ type: "error", message: "Error loading data" });
      return;
    }
  };

  const bindDataModelRemote = async (recordId) => {
    //First load records
    try {

      if(props.fields){
        setFields(props.fields);
        return;
      }
      if (props.ParentGUID == null) {
        return;
      }
      let query = `token=${user.Token}&RecordId=${recordId}&PageIndex=${props.page.PageIndex}&ParentGUID=${props.ParentGUID}`;

      let request = await API.getAction(props.modelEndpoint, query);

      //Check for header
      if (request.data.header) {

        let headersResponse = request.data.header;
        let firstParseHeader = JSON.parse(headersResponse);
        let parsedHeaders = JSON.parse(firstParseHeader[0].JSONData);

        let finalParseHeader = parsedHeaders[0].JSONData[0].Fields;
        setHeaders(finalParseHeader);

        console.log("the header ", headersResponse, parsedHeaders, finalParseHeader)
      }
      let recordsResult = null;
      //Check for model 
      if (request.data.model) {
        //means came with header
        let firstResult = JSON.parse(request.data.model);
        let secondResult = typeof firstResult[0] === 'string' ? JSON.parse(firstResult[0]) : firstResult[0];
        recordsResult = secondResult;


      } else {

        //Regular display, 
        let resultsSections = request.data[0];
        if (resultsSections === undefined) {
          return;
        }
        let parsedResultsSections = JSON.parse(resultsSections.JSONData)[0];
        recordsResult = parsedResultsSections;
      }


      let parsedResults = recordsResult;
      if (parsedResults.Error) {
        alert(parsedResults.Msg);
        return;
      }

      let finalResult = typeof parsedResults.JSONData === 'string' ? JSON.parse(parsedResults.JSONData)[0].JSONData[0] : parsedResults.JSONData[0];

      console.log("model items", finalResult);

      setFields(finalResult.Fields);

    } catch (error) {
      console.error(error);
      toast({ type: "error", message: "Error loading data" });
      return;
    }
  };


  //Delete the record
  const deleteConfirm = async () => {
    confirmAlert({
      closeOnClickOutside: false,
      message: "Are you sure you want to delete this record?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              let query = `token=${user.Token}&RecordId=${selectedItem.GUID}&PageIndex=${props.page.PageIndex}&ParentGUID=${props.ParentGUID}`;
              await API.getAction(props.deleteEndpoint, query);
              await bindDataRecordRemote();
              setSelectedItem(null);
            } catch (error) {
              console.error(error)
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };


  //Open edit/add mode
  const toggleOpen = async (action, reload) => {
    setActionMode(action);

    if (action === null) {
      setSelectedItem(null);
      formik.resetForm();
    }

    if (action === "I") {
      setSelectedItem(null)
    }
    if (action === "U" || action === "C" || action === "D") {

      debugger;
      await bindDataDetailsRemote(selectedItem.GUID);
    }

    if (selectedItemRef.current) {
      selectedItemRef.current.clearSelection();
    }

    setGeneralError(null);
    setShowAddEdit(!showAddEdit)
    if (reload) {
      await bindDataRecordRemote();
    }
  };


  //Update the field value on formik according to what has been updated
  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  const [validationsSchema, setValidationSchema] = useState(null);

  const fillFormikObject = (record) => {
    console.log("fillFormikObject", "record", record, "model", model);
    if (
      fields &&

      fields.length > 0
    ) {
      fields.forEach((item) => {
        console.log(
          "field name",
          item.FieldName,
          "RecordValue ",
          record[item.FieldName]
        );
        formik.setFieldValue(item.FieldName, record[item.FieldName]);
      });
    }

    //Now
  };

  const prepareFormikObject = () => {
    console.log("Preparing formik object", model)
    let initialObject = {};
    let initialObjectValidation = {};


    if (fields) {
      fields.forEach((item) => {

        if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.FieldName] = item.value;

        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };

  //This method is to save the record we are working on
  const handleSubmit = async (obj, event) => {
    console.log("❗ ~ file: CRUDSimple.js:259 ~ handleSubmit ~ obj:", obj)
    let actions = {
      "I": "I",
      "U": "U",
      "D": "I"
    };

    if (actionMode === "D") {
      const { GUID, Id, ...rest } = obj;
      obj = rest;
    }

    confirmAlert({
      closeOnClickOutside: false,
      message: "Are you sure you want to save this record?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              let objSPVersion = "";
              fields.forEach((item) => {
                if (item.ReadOnly ||
                  item.HtmlType === "separator" ||
                  item.FieldName === 'IdCustomer' ||
                  item.FieldName === 'IdContract') return;

                if (item.HtmlType !== "separator") {
                  objSPVersion +=
                    (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
                }
                if (
                  item.FieldType === "int" ||
                  item.FieldType === "bool" ||
                  item.FieldType === "bit" ||
                  item.FieldType === "bigint"
                ) {
                  objSPVersion +=
                    obj[item.FieldName] === undefined || obj[item.FieldName] === null
                      ? item.DefaultValue && item.DefaultValue.length > 0 ? item.DefaultValue : "null"
                      : "'" + obj[item.FieldName] + "'";
                } else if (item.HtmlType === "password") {
                  let passwordEncrypted = null;
                  if (obj[item.FieldName] && obj[item.FieldName].length > 0) {
                    passwordEncrypted =
                      obj["UserName"].toUpperCase() + obj[item.FieldName];
                    passwordEncrypted = Sha256.hash(passwordEncrypted);
                    ;
                  }
                  objSPVersion +=
                    obj[item.FieldName] === undefined
                      ? "null"
                      : "'" + passwordEncrypted + "'"
                } else
                  if (item.HtmlType === 'radio') {

                    objSPVersion +=
                      obj[item.FieldName] === undefined || obj[item.FieldName] === null
                        ? item.DefaultValue && item.DefaultValue.length > 0 ? item.DefaultValue : "null"
                        : "'" + obj[item.FieldName] + "'";

                  } else {
                    objSPVersion +=
                      obj[item.FieldName] === undefined || obj[item.FieldName] === null
                        ? "null"
                        : "'" + obj[item.FieldName] + "'";
                  }
              });
              let queryString = `Id=${params.id}&Token=${user.Token}&ActionMode=${actions[actionMode]}&PageIndex=${props.page.PageIndex}`;
              objSPVersion += `, @ParentGUID='${props.ParentGUID}'`;
              if (props.ChildGUID) {
                objSPVersion += `, @ChildGUID='${props.ChildGUID}'`;
              }
              let queryData = {
                Data: objSPVersion,
              };

              let request = await API.postAction(
                props.addEditEndpoint + "?" + queryString,
                queryData
              );

              if (request.status === 200) {
                let response = JSON.parse(request.data[0].JSONData)[0];
                if (response.Error) {
                  setGeneralError(response.Msg);
                  return;
                }
              }
              toast({
                type: "success",
                "message": "Record saved successfully",
              })

              toggleOpen(null, true);
            } catch (error) {
              console.error(error);
            }
          },
        },
        {
          label: "No",
        },
      ],
    });


  };

  const formik = useFormik({
    initialValues: {},
    // enableReinitialize: true,
    validateOnChange: false,
    // validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  // END METHODS

  // UI METHODS
  const loadGRIDUI = () => {

    return (
      <Container id="tab-grid-body" fluid className=" ">

        <Card>
          <Card.Body className="p-0">
            <Row className="mx-0">
              <Col className="py-2 " >
                <DataGrid
                  dataSource={records.Rows}
                  columns={headers.length > 0 ? headers.map(item => item.FieldName) : null}
                  // columns={records.Columns.length > 0 ? records.Columns : null}
                  columnAutoWidth={true}
                  showColumnHeaders={true}
                  showBorders={true}
                  onSelectionChanged={selectItem}
                  loadPanel={{
                    enabled: true,
                    showIndicator: true,
                    text: "Loading...",
                  }}
                >

                  {props.allowToSearchInColumns ?
                    <FilterRow visible={true} />
                    : null}
                  <Selection mode="single" />
                  <Paging
                    defaultPageSize={10}
                  />
                  <Pager
                    showInfo={true}
                    infoText={"Page:"}
                    visible={true}
                    showPageSizeSelector={true}
                    allowedPageSizes={allowedPageSizes}
                  />
                </DataGrid>
              </Col>
              <Col className="col-auto ml-auto text-center bg-light border ">
                <div className="d-flex flex-column gy-3 align-items-center justify-content-center">
                  <button
                    type="button"
                    className="btn btn-primary my-2 btn-sm"
                    onClick={() => toggleOpen("I")}
                    title="Add"
                    disabled={actionMode === "C"}
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                  {(selectedItem && actionMode !== "C") && (
                    <>
                      <button
                        id="grid-edit-button"
                        type="button"
                        className="btn btn-primary mb-2 btn-sm"
                        title="Update"
                        onClick={() => toggleOpen("U")}>
                        <i className="fa fa-pencil"></i>
                      </button>
                      <button
                        id="grid-delete-button"
                        type="button"
                        className="btn btn-secondary mb-2 btn-sm"
                        title="Delete"
                        onClick={() => deleteConfirm()}
                      >
                        <i className="fa fa-trash"></i>
                      </button>

                    </>
                  )
                  }
                </div>
              </Col>
            </Row>

          </Card.Body>
        </Card>

      </Container>
    );
  };

  //EVENTS
  const selectItem = useCallback((e) => {
    e.component.byKey(e.currentSelectedRowKeys[0]).done((item) => {
      selectedItemRef.current = e.component;
      setSelectedItem(item);
    });
  }, []);

  useEffect(() => {
    // if (!props.active) return;
    console.log("section updated", props.page)

    bindDataModelRemote();
    bindDataRecordRemote();
  }, [props.page]);

  useEffect(() => {
    prepareFormikObject();
  }, [fields])


  // END EVENTS

  if (props.active === false) return <></>;

  return (
    <>
      {/* DISPLAY THE TYPE OF FORM */}
      <Modal
        show={showAddEdit}
        onHide={() => toggleOpen(null)}
        backdrop="static"
        keyboard={false}
        size="xl"
        className="modalRight"
      >
        <Modal.Header closeButton>
          <Modal.Title>{model.PageTitle}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <Container fluid className="overflow-auto">
              <Row className="gy-5 mb-4">
                {fields &&
                  fields
                    .filter(x => x.FieldTitle !== "Audit Information"
                      && x.FieldName !== "Id"
                      && x.FieldName !== "IdCustomer"
                      && x.FieldName !== 'DateAdd'
                      && x.FieldName !== 'File'
                      && x.FieldName !== 'DateMod')
                    .map((item) => {
                      return (
                        <Col
                          className={
                            item.cssClasss + (!item.Visible ? " d-none" : "")
                          }
                          key={`col-${item.FieldName}`}
                        >
                          <label className="me-2 mt-2">
                            {item.FieldTitle}
                            {item.Required ? <i className="required-asterisk ms-1 text-danger">*</i> : null}
                          </label>
                          <br />

                          <FieldSelector
                            actionMode={actionMode}
                            model={item}
                            key={`field-${item.FieldName}`}
                            updateField={updateField}
                            value={formik.values[item.FieldName]}
                          ></FieldSelector>
                          {formik.errors[item.FieldName] ? (
                            <div className="invalid text-sm my-1">
                              {formik.errors[item.FieldName]}
                            </div>
                          ) : null}
                        </Col>
                      );
                    })}
              </Row>

              {generalError ? (
                <Row>
                  <Col xs={12}>
                    <div className="alert alert-danger" role="alert">
                      {generalError}
                    </div>
                  </Col>
                </Row>
              ) : null}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex gx-3 align-items-center justify-content-end">
              {Object.values(formik.errors).length > 0 && (
                <span className="invalid me-2">
                  Please check the forms for errors
                </span>
              )}
              <button className="btn btn-secondary me-2" type="button" onClick={() => toggleOpen(null)}>Close</button>
              <button className="btn btn-primary me-2" type="button" onClick={() => [formik.submitForm()]}>Save</button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
      {
        loading ? (
          <React.Fragment>
            <div className="">
              <div className="w-25 mx-auto my-1 p-5 text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <>
            {loadGRIDUI()}
          </>
        )
      }
    </>)

}

export default CRUDPageGRIDCustom;